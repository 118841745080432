import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getJobs = async (query) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/jobs?${qs.stringify(query)}`
  );

  return data;
};

export const getJob = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(`${API_ROOT_URL}/porrige/jobs/${id}`);

  return data;
};

export const editJob = async (d, id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(`${API_ROOT_URL}/porrige/jobs/${id}`, {
    ...d,
  });

  return data;
};

export const deleteJob = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.delete(`${API_ROOT_URL}/porrige/jobs/${id}`);

  return data;
};

export const saveJobStatus = async (id, status) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/jobs/save-status`,
    {
      id,
      status,
    }
  );

  return data;
};

export const createJob = async (user) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(`${API_ROOT_URL}/porrige/jobs`, user);
  return data;
};

export const updateJob = async (id, user) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/jobs/${id}`,
    user
  );
  return data;
};

export const saveReferralExternalLink = async (id, link) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/jobs/referral-external-link/${id}`,
    { referral_external_link: link }
  );
  return data;
};
