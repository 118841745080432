import React from "react";

export const TableForm = ({ name, value }) => {
  return (
    <tr className="">
      <th scope="row" className="w-25 pt-2 text-muted">
        <p className="m-0 p-0 fs-6 fw-normal">{name}</p>
      </th>
      <td className="fs-6 px-0 pt-2 text-dark">
        <p className="m-0 p-0 fs-6 fw-bold">{value}</p>
      </td>
    </tr>
  );
};
