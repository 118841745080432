import React, { useState } from "react";
import { Col, Form, Row, Button, Alert } from "@themesberg/react-bootstrap";
import { saveContacts } from "../../../api/users/candidatesApi";
import { v4 as uuidv4 } from "uuid";

const ContactInformation = ({
  contacts,
  handleContactsChange,
  setContacts,
  isDisabledForm,
}) => {
  const [error, setError] = useState("");

  const toggleHandler = (type, v) => {
    setContacts((prev) => ({ ...prev, [type]: v }));
  };

  const addAnotherSocials = () => {
    setContacts((prev) => ({
      ...prev,
      socials: [
        ...(prev.socials || []),
        {
          name: "",
          link: "",
          id: uuidv4(),
        },
      ],
    }));
  };

  const onSave = async (event) => {
    event.preventDefault();
    try {
      const id = window.location.href.split("/").pop();
      let data = {
        // ...contacts,
        socials: contacts.socials.map((social) => {
          return { name: social.name, link: social.link };
        }),
      };
      const socialMediaPlatforms = [
        "facebook",
        "linkedin",
        "twitter",
        "instagram",
        "youtube",
        "github",
      ];

      socialMediaPlatforms.forEach((platform) => {
        if (contacts[platform]) data[platform] = contacts[platform];
      });
      await saveContacts(id, data);
    } catch (err) {
      setError(err.response.data.message.map((m) => m).join(", "));
    }
  };
  const contactsHandler = (event, id) => {
    const { name, value } = event.target;
    setContacts((prev) => {
      return {
        ...prev,
        socials: prev.socials.map((social) => {
          if (social.id === id) {
            return { ...social, [name]: value };
          }
          return social;
        }),
      };
    });
  };
  const onDelete = (id) => {
    setContacts((prev) => {
      return {
        ...prev,
        socials: prev.socials.filter((social) => social.id !== id),
      };
    });
  };

  const isSocialsEmpty = contacts.socials.some(
    (social) => !social.name && !social.link
  );

  return (
    <div>
      <h5 className="my-4">Contact information</h5>

      <Form onSubmit={onSave}>
        <Row>
          <Col md={6} className="mb-2">
            <Form.Group id="Facebook">
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="facebook"
                type="text"
                placeholder="Enter Facebook address"
                value={contacts.facebook}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-2">
            <Form.Group id="linkedin">
              <Form.Label>Linkedin</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="linkedin"
                type="text"
                placeholder="Enter Linkedin address"
                value={contacts.linkedin}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-2">
            <Form.Group id="twitter">
              <Form.Label>X</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="twitter"
                type="text"
                placeholder="Enter twitter address"
                value={contacts.twitter}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-2">
            <Form.Group id="instagram">
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="instagram"
                type="text"
                placeholder="Enter Instagram address"
                value={contacts.instagram}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-2">
            <Form.Group id="youtube">
              <Form.Label>Youtube</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="youtube"
                type="text"
                placeholder="Enter Youtube address"
                value={contacts.youtube}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-2">
            <Form.Group id="github">
              <Form.Label>Github</Form.Label>
              <Form.Control
                disabled={isDisabledForm}
                // required
                name="github"
                type="text"
                placeholder="Enter Github address"
                value={contacts.github}
                onChange={handleContactsChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {contacts.socials.map((social) => (
          <Row key={social.id} className="d-flex align-items-end">
            <Col md={5} className="mb-2">
              <Form.Group id="name">
                <Form.Label>Other Social Media Name</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  // required
                  name="name"
                  type="text"
                  placeholder="Add a name of the link"
                  value={social.name}
                  onChange={(e) => contactsHandler(e, social.id)}
                />
              </Form.Group>
            </Col>
            <Col md={5} className="mb-2">
              <Form.Group id="link">
                <Form.Label>Social Media Link</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  // required
                  name="link"
                  type="text"
                  placeholder="Add a link"
                  value={social.link}
                  onChange={(e) => contactsHandler(e, social.id)}
                />
              </Form.Group>
            </Col>
            {!isDisabledForm && (
              <Col md={2} className="mb-2">
                <Button variant="danger" onClick={() => onDelete(social.id)}>
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        ))}
        {!isDisabledForm && (
          <div className="mt-3 mb-3">
            <Button
              variant="secondary"
              disabled={isSocialsEmpty}
              type="button"
              onClick={addAnotherSocials}
            >
              Add Another Social Media
            </Button>
          </div>
        )}
        <Form.Check
          disabled={isDisabledForm}
          type="switch"
          id="is_telegram-switch"
          label="Telegram"
          name="is_telegram"
          checked={contacts.is_telegram}
          onChange={() => toggleHandler("is_telegram", !contacts.is_telegram)}
        />
        <Form.Check
          disabled={isDisabledForm}
          type="switch"
          id="is_whatsapp-switch"
          label="Whatsapp"
          name="is_whatsapp"
          checked={contacts.is_whatsapp}
          onChange={() => toggleHandler("is_whatsapp", !contacts.is_whatsapp)}
        />
        {error && <Alert variant="danger">{error}</Alert>}
        {!isDisabledForm && (
          <div className="mt-3">
            <Button variant="primary" type="submit">
              Save Contact Information
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ContactInformation;
