import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes";
import { getUsers } from "../../../api/users/usersApi";
import { USER_ROLES } from "../../../utils/constants";
import { formatDate, shortId } from "../../../utils/helpers";
import InputSearch from "../../components/InputSearch";
import Select from "react-select";
import { getCompaniesList } from "../../../api/users/companies/CompaniesApi";

const TableRow = (props) => {
  const { id, first_name, last_name, email, created_at, status } = props;

  return (
    <tr>
      <td>
        <Card.Link
          as={Link}
          to={Routes.RecruiterDetail.path.replace(":id", id)}
          className="fw-normal"
        >
          {shortId(id)}
        </Card.Link>
      </td>
      <td>
        <span className="fw-normal">{first_name}</span>
      </td>
      <td>
        <span className="fw-normal">{last_name}</span>
      </td>
      <td>
        <span className="fw-normal">{email}</span>
      </td>
      <td>
        <span className="fw-normal">{formatDate(created_at)}</span>
      </td>
      <td>
        <span className={`fw-normal text-${status}`}>{status}</span>
      </td>
    </tr>
  );
};

export const RecruiterTable = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [query, setQuery] = useState({
    page: 1,
    per_page: 20,
    search: "",
    role: [USER_ROLES.RECRUITER],
    company_id: "",
  });

  const userRole = localStorage.getItem("role");

  const [users, setUsers] = useState({ count: 0, data: [] });
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState("");

  const [companiesList, setCompaniesList] = useState([]);

  const handleCompaniesList = (search) => {
    try {
      if (search.length) {
        getCompaniesList({ search: search }).then((arr) =>
          setCompaniesList(arr)
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getUsers(query);
        setUsers(res);
        setTableData(
          res.data.map((t) => <TableRow key={`recruiter-${t.id}`} {...t} />)
        );
        setPaginationData(
          generatePagination(query.page, res.count, query.per_page)
        );
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [query]);

  const totalUsers = users.count;
  const currentUsers = users.data.length + (query.page - 1) * query.per_page;

  let is_paginate = false;

  const handlePaginationPage = async (value, maxPage) => {
    if (is_paginate) {
      return false;
    }
    let new_page;
    if (value === "prev") {
      if (query.page === 1) {
        return false;
      }
      new_page = query.page - 1;
    } else if (value === "next") {
      if (query.page === maxPage) {
        return false;
      }
      new_page = query.page + 1;
    } else {
      new_page = Number(value);
    }
    query.page = new_page;
    is_paginate = true;
    const res = await getUsers(query);
    setUsers(res);
    setTableData(
      res.data.map((t) => <TableRow key={`recruiter-${t.id}`} {...t} />)
    );
    setPaginationData(
      generatePagination(query.page, res.count, query.per_page)
    );
    is_paginate = false;
  };

  const generatePagination = (page, count, per_page) => {
    if (count > per_page) {
      const paginationItems = [];
      for (let i = 1; i <= Math.ceil(count / per_page); i++) {
        paginationItems.push(
          <Pagination.Item
            value={i}
            active={i === page}
            onClick={() => handlePaginationPage(i, Math.ceil(count / per_page))}
          >
            {i}
          </Pagination.Item>
        );
      }
      return (
        <Nav>
          <Pagination className="mb-2 mb-lg-0">
            <Pagination.Prev
              value="prev"
              onClick={() =>
                handlePaginationPage("prev", Math.ceil(count / per_page))
              }
            >
              Previous
            </Pagination.Prev>
            {paginationItems}
            <Pagination.Next
              value="next"
              onClick={() =>
                handlePaginationPage("next", Math.ceil(count / per_page))
              }
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Nav>
      );
    } else {
      return "";
    }
  };

  const handleSelectChange = (value, name) => {
    setSelectedCompany(value);
    setQuery((prev) => ({
      ...prev,
      [name]: value.value,
    }));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calyptus</Breadcrumb.Item>
            <Breadcrumb.Item active>Recruiters</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Recruiters</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
          {userRole !== USER_ROLES.SUPPORT && (
            <Link to={Routes.CreateRecruiter.path}>
              <Dropdown.Toggle
                as={Button}
                variant="secondary"
                className="text-dark me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>Create New</span>
              </Dropdown.Toggle>
            </Link>
          )}
        </div>
      </div>

      <Row className="d-flex justify-content-start align-items-center mb-4">
        <div
          style={{
            marginRight: "10px",
            width: "300px",
          }}
        >
          <InputSearch setQuery={setQuery} />
        </div>
        <Col md={4}>
          <Form.Group id="company_id" className="w-100">
            <Select
              placeholder="Start typing to search for a company..."
              required
              value={selectedCompany}
              options={companiesList.map((i) => ({
                value: i.id,
                label: i.name,
              }))}
              name="company_id"
              classNamePrefix="select"
              onChange={(v) => handleSelectChange(v, "company_id")}
              onInputChange={handleCompaniesList}
            />
          </Form.Group>
        </Col>
      </Row>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">First Name</th>
                <th className="border-bottom">Last Name</th>
                <th className="border-bottom">Email</th>
                <th className="border-bottom">Created Date</th>
                <th className="border-bottom">Status</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {paginationData}
            <small className="fw-bold">
              Showing <b>{currentUsers}</b> out of <b>{totalUsers}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default RecruiterTable;
