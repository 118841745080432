import {getAxiosInstance} from '../axios';
import {API_ROOT_URL} from '../../utils/constants';
import qs from 'qs';

export const getPortfolios = async (query) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/portfolios?${qs.stringify(query)}`);

    return data;
};

export const createPortfolio = async (portfolio) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/portfolios`, portfolio);

    return data;
};

export const updatePortfolio = async (portfolio) => {
    const axios = getAxiosInstance();
    const { data } = await axios.put(`${API_ROOT_URL}/porrige/portfolios`, portfolio);

    return data;
};

export const deletePortfolio = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.delete(`${API_ROOT_URL}/porrige/portfolios/${id}`);

    return data;
};
