import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import { CertificatesProvidersTypes } from "../../../../utils/constants";
import {
  createCertificate,
  deleteCertificate,
  getCertificates,
  updateCertificate,
} from "../../../../api/users/certificatesAPI";
import moment from "moment-timezone";
import { ChooseFileWidget } from "../../../../components/Widgets";

const Certificate = ({ certificates, setCertificates, isDisabledForm }) => {
  const pathname = window.location.href.split("/");
  const [errorMsgCertificate, setErrorMsgCertificate] = useState({});

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setCertificates(
      certificates.map((i, n) => {
        if (n === index) i[`${name}`] = value;
        return i;
      })
    );
  };

  const handleSaveCertificate = async (index) => {
    try {
      const certificate = certificates[index];
      if (!certificate.provider && !certificate.institution) {
        setErrorMsgCertificate({
          ...errorMsgCertificate,
          [index]: "Enter provider or institution",
        });
        return false;
      }
      if (certificate.pass_date > new Date()) {
        setErrorMsgCertificate({
          ...errorMsgCertificate,
          [index]: "Invalid date",
        });
        return false;
      }
      if (certificate.id) {
        await updateCertificate({
          id: certificate.id,
          user_id: certificate.user_id,
          title: certificate.title,
          url: certificate.url,
          pass_date: certificate.pass_date,
          provider: certificate.provider,
          institution: certificate.institution,
        });
      } else {
        const newCertificate = await createCertificate({
          user_id: pathname[pathname.length - 1],
          title: certificate.title,
          url: certificate.url,
          pass_date: certificate.pass_date,
          provider: certificate.provider,
          institution: certificate.institution,
        });
        setCertificates(
          certificates.map((i, n) => {
            if (n === index) i.id = newCertificate.id;
            return i;
          })
        );
      }
      setErrorMsgCertificate({ ...errorMsgCertificate, [index]: "" });
    } catch (error) {
      setErrorMsgCertificate({
        ...errorMsgCertificate,
        [index]: error.response.data.message,
      });
    }
  };

  const handleDeleteCertificate = async (index) => {
    const updatedCertificates = [...certificates]; // Assuming forms is your array of forms in the state
    await deleteCertificate(
      certificates.find((i, n) => {
        if (n === index) return i;
      }).id
    );
    updatedCertificates.splice(index, 1);
    setCertificates(updatedCertificates); // Update the state with the modified array
  };

  const handleAdd = () => {
    if (!certificates.length || certificates[certificates.length - 1].id) {
      setCertificates([...certificates, {}]);
    }
  };

  return (
    <>
      <h5 className="my-4">Certificates</h5>
      {certificates.map((certificate, index) => (
        <Form
          key={index}
          className="my-4"
          onSubmit={(event) => {
            event.preventDefault();
            handleSaveCertificate(index);
          }}
        >
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Certificate Name</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="title"
                  type="text"
                  placeholder="Enter name"
                  value={certificate.title}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="url">
                <Form.Label>Url</Form.Label>
                <Form.Control
                  // required
                  disabled={isDisabledForm}
                  name="url"
                  type="text"
                  placeholder="Enter url"
                  value={certificate.url}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="pass_date">
                <Form.Label>Pass date</Form.Label>
                <Form.Control
                  required
                  disabled={isDisabledForm}
                  name="pass_date"
                  type="date"
                  value={moment(certificate.pass_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="provider">
                <Form.Label>Provider</Form.Label>
                <Form.Select
                  disabled={isDisabledForm}
                  name="provider"
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                  defaultValue={certificate.provider}
                  value={certificate.provider}
                >
                  <option key="" value="">
                    None
                  </option>
                  {Object.entries(CertificatesProvidersTypes).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="institution">
                <Form.Label>Institution</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="institution"
                  type="text"
                  placeholder="Enter institution"
                  value={certificate.institution}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ChooseFileWidget
                title="Attach certificate file"
                file={certificate?.attached_file?.url}
              />
            </Col>
          </Row>
          {errorMsgCertificate[`${index}`] && (
            <Alert variant="danger">{errorMsgCertificate[`${index}`]}</Alert>
          )}
          {!isDisabledForm && (
            <div className="mt-3 d-flex align-items-center">
              <Button className="me-2 btn" variant="primary" type="submit">
                Save
              </Button>
              {certificate.id && (
                <Button
                  className="btn btn-secondary"
                  variant="danger"
                  onClick={() => handleDeleteCertificate(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </Form>
      ))}
      {!isDisabledForm && (
        <div className="mt-3">
          <Button variant="primary" onClick={handleAdd}>
            Add Certificate
          </Button>
        </div>
      )}
    </>
  );
};

export default Certificate;
