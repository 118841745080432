import React, { useEffect, useState } from "react";
import {Alert, Button, Card, Col, Row,} from "@themesberg/react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
import {getApplication, deleteApplication} from "../../api/applications/applicationsApi";
import ReactQuill from "react-quill";
import {TableForm} from "../jobs/JobDetails/TableForm";
import {Routes} from "../../routes";

const ApplicationDetail = () => {
    const history = useHistory();
    const pathname = window.location.href.split("/");

    const [errorMsgBtn, setErrorMsgBtn] = useState("");
    const [createdByLink, setCreatedByLink] = useState("");
    const [isDisabledForm, setIsDisabledForm] = useState(false);

    const userRole = localStorage.getItem("role");

    const [chatData, setChatData] = useState({
        job: {title: "", id: "", company: {name: "", id: ""}},
        users: [{first_name: "", last_name: "", id: ""}],
        user: {first_name: "", last_name: "", id: "", company_id: "", role: ""},
        matching_score: "",
        created_at: "",
        application: { note: "", status: "", id: "" },
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getApplication(pathname[pathname.length - 1]);
                setChatData(res);
                if (res.user?.role === USER_ROLES.RECRUITER) {
                    setCreatedByLink(Routes.RecruiterDetail.path.replace(":id", res.user?.id));
                }
                if (res.user?.role === USER_ROLES.CANDIDATE) {
                    setCreatedByLink(Routes.CandidateDetail.path.replace(":id", res.user?.id));
                }
                if (res.user?.role === USER_ROLES.COMPANY) {
                    setCreatedByLink(Routes.CompanyDetail.path.replace(":id", res.user?.company_id));
                }
                if (
                    userRole === USER_ROLES.SUPPORT ||
                    res.role === USER_ROLES.SUPER_ADMIN ||
                    res.role === userRole
                ) {
                    setIsDisabledForm(true);
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, []);

    const handleDelete = async () => {
        try {
            await deleteApplication(pathname[pathname.length - 1]);
            history.push("/applications");
        } catch (error) {
            setErrorMsgBtn(error.response.data.message);
            console.error("Error:", error.message);
        }
    };

    return (
        <>
            <Row>
                <Col xs={12} xl={8}>
                    <div className="d-flex">
                        <div
                            className="d-grid align-items-start p-3 justify-content-between "
                            style={{
                                gridTemplateColumns: "2fr 1fr",
                                gap: "1rem",
                            }}
                        >
                            <Row>

                                <div className="d-flex flex-column align-items-start p-[16px]">
                                    <table className="">
                                        <tbody>
                                        <TableForm name="Job Title" value={
                                            <Card.Link as={Link} to={Routes.JobDetail.path.replace(":id", chatData.job.id)} className="fw-normal">
                                                {chatData.job.title}
                                            </Card.Link>
                                        } />

                                        <TableForm name="Company" value={
                                            <Card.Link as={Link} to={Routes.CompanyDetail.path.replace(":id", chatData.job.company.id)} className="fw-normal">
                                                {chatData.job.company.name}
                                            </Card.Link>
                                        } />

                                        <TableForm name="Candidate" value={
                                            <Card.Link as={Link} to={Routes.CandidateDetail.path.replace(":id", chatData.users[0]?.id)} className="fw-normal">
                                                {chatData.users[0]?.first_name + ' ' + chatData.users[0]?.last_name}
                                            </Card.Link>
                                        } />

                                        {chatData.matching_score?.matching_score && (
                                            <TableForm name="Matching Score" value={chatData.matching_score.matching_score.toFixed(2) + '% match'} />
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                {chatData.application.note && (
                                    <div>
                                        <p className="m-0 mb-0 text-uppercase fw-bold text-muted">Note</p>

                                        <ReactQuill
                                            value={chatData.application.note}
                                            readOnly={true}
                                            theme="snow"
                                            modules={{ toolbar: false }}
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                )}
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <div>
                                <Card.Link as={Link} to={createdByLink} className="fw-normal">
                                    <TableForm name="Created By: " value={chatData.user?.role + ' ' + chatData.user?.first_name + ' ' + chatData.user?.last_name} />
                                </Card.Link>
                                <p>
                                    Creation Date:{" "}
                                    {new Date(chatData.created_at).toLocaleDateString()}
                                </p>
                                <p>Application Status: {chatData.application.status}</p>
                                <p>
                                    Application Status Date:{" "}
                                    {new Date(chatData.application.status_date).toLocaleDateString()}
                                </p>
                            </div>
                        </Col>
                        {!isDisabledForm && (
                            <Col xs={12}>
                                <div className="d-flex align-items-center">
                                    <Button variant="danger" onClick={() => handleDelete()} className="me-2">
                                        Delete
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
                </Col>
            </Row>
        </>
    );
};

export default ApplicationDetail;
