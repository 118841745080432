import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Table} from '@themesberg/react-bootstrap';
import {getInvoices, payInvoice} from "../../../../api/users/companies/SubscriptionsAPI";
import {USER_ROLES} from "../../../../utils/constants";

export default () => {
    const userRole = localStorage.getItem('role');
    const pathname = window.location.href.split('/');
    const [invoicesList, setInvoicesList] = useState([]);
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect( () => {
        async function fetchData() {
            try {
                const invoicesList = await getInvoices({company_id: pathname[pathname.length-1], limit: 20});
                setInvoicesList(invoicesList);
                if (userRole === USER_ROLES.SUPPORT) {
                    setIsDisabledForm(true);
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, []);

    const handleInvoicePay = async (id) => {
        try {
            await payInvoice(id);
            setErrorMsg('');
            setInvoicesList(invoicesList.map(i => {
                if (id === i.id) {
                    i.status = 'paid';
                }
                return i;
            }));
        } catch (error) {
            setErrorMsg('Cannot pay this invoice');
            console.error('Error:', error.message);
        }
    };

    const InvoicesRow = (props) => {
        const { status, created_at, total, currency, id } = props;

        return (
            <tr>
                <td className="border-0">
                    {total/100} {currency}
                </td>
                <td className="border-0">
                    {status}
                </td>
                <td className="border-0">
                    {moment(created_at).format("DD MMM YYYY")}
                </td>
                <td className="col-2">
                    {status === 'open' && !isDisabledForm &&
                        <span className="icon icon-sm">
                            <Button className="btn btn-dark btn-sm" onClick={() => handleInvoicePay(id)}>pay</Button>
                        </span>
                    }
                </td>
            </tr>
        );
    };

    return (
        <>
            <h4 className="my-4">Invoices</h4>
            {invoicesList?.length &&
                <Card border="light" className="shadow-sm">
                    <Card.Body className="pb-0">
                        <Table responsive className="table-centered table-nowrap rounded mb-0">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">Price</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Created At</th>
                                <th className="border-0">Pay</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoicesList.map(r => <InvoicesRow key={`invoice-${r.id}`} {...r} />)}
                            </tbody>
                        </Table>
                        {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                    </Card.Body>
                </Card>
            }
        </>
    );
}
