import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import { EducationTypes } from "../../../../utils/constants";
import {
  createEducation,
  deleteEducation,
  updateEducation,
} from "../../../../api/users/educationsAPI";
import moment from "moment-timezone";
import { ChooseFileWidget } from "../../../../components/Widgets";

const Education = ({ educations, setEducations, isDisabledForm }) => {
  const pathname = window.location.href.split("/");
  const [errorMsgEducation, setErrorMsgEducation] = useState({});

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setEducations(
      educations.map((i, n) => {
        if (n === index) i[`${name}`] = value;
        return i;
      })
    );
  };
  const handleSaveEducation = async (index) => {
    try {
      const education = educations[index];
      if (education.end_date && education.end_date < education.start_date) {
        setErrorMsgEducation({
          ...errorMsgEducation,
          [index]: "End date must be more than start",
        });
        return false;
      }
      if (
        education.end_date > new Date() ||
        education.start_date > new Date()
      ) {
        setErrorMsgEducation({ ...errorMsgEducation, [index]: "Invalid date" });
        return false;
      }
      if (education.id) {
        await updateEducation({
          id: education.id,
          user_id: education.user_id,
          title: education.title,
          position: education.position,
          website: education.website,
          start_date: education.start_date,
          end_date: education.end_date,
          education_type: education.education_type,
          certificate_url: education.certificate_url
            ? education.certificate_url
            : null,
          description: education.description,
        });
      } else {
        const newEducation = await createEducation({
          title: education.title,
          user_id: pathname[pathname.length - 1],
          position: education.position,
          website: education.website,
          start_date: education.start_date,
          end_date: education.end_date,
          education_type: education.education_type,
          certificate_url: education.certificate_url
            ? education.certificate_url
            : null,
          description: education.description,
        });
        setEducations(
          educations.map((i, n) => {
            if (n === index) i.id = newEducation.id;
            return i;
          })
        );
      }
      setErrorMsgEducation({ ...errorMsgEducation, [index]: "" });
    } catch (error) {
      setErrorMsgEducation({
        ...errorMsgEducation,
        [index]: error.response.data.message,
      });
    }
  };

  const handleDeleteEducation = async (index) => {
    const updatedEducation = [...educations]; // Assuming forms is your array of forms in the state
    await deleteEducation(
      educations.find((i, n) => {
        if (n === index) return i;
      }).id
    );
    updatedEducation.splice(index, 1);
    setEducations(updatedEducation); // Update the state with the modified array
  };

  const handleAdd = () => {
    if (!educations.length || educations[educations.length - 1].id) {
      setEducations([...educations, {}]);
    }
  };

  return (
    <>
      <h5 className="my-4">Educations</h5>
      {educations.map((education, index) => (
        <Form
          key={index}
          className="my-4"
          onSubmit={(event) => {
            event.preventDefault();
            handleSaveEducation(index);
          }}
        >
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="title">
                <Form.Label>University Name</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="title"
                  type="text"
                  placeholder="Enter name"
                  value={education.title}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="position">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="position"
                  type="text"
                  placeholder="Enter position"
                  value={education.position}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="education_type">
                <Form.Label>Education type</Form.Label>
                <Form.Select
                  disabled={isDisabledForm}
                  name="education_type"
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                  defaultValue={EducationTypes.BACHELOR}
                  value={education.education_type}
                >
                  {Object.entries(EducationTypes).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="certificate_url">
                <Form.Label>Certificate url</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  // required
                  name="certificate_url"
                  type="text"
                  placeholder="Enter url"
                  value={education.certificate_url}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="start_date">
                <Form.Label>Start date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="start_date"
                  type="date"
                  value={moment(education.start_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="end_date">
                <Form.Label>End date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="end_date"
                  type="date"
                  value={moment(education.end_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  rows="3"
                  maxLength="1000"
                  disabled={isDisabledForm}
                  name="description"
                  as="textarea"
                  type="text"
                  placeholder="Enter description"
                  value={education.description}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ChooseFileWidget
                title="Attach education file"
                file={education?.attached_file?.url}
              />
            </Col>
          </Row>
          {errorMsgEducation[`${index}`] && (
            <Alert variant="danger">{errorMsgEducation[`${index}`]}</Alert>
          )}
          {!isDisabledForm && (
            <div className="mt-3 d-flex align-items-center">
              <Button className="me-2 btn" variant="primary" type="submit">
                Save
              </Button>
              {education.id && (
                <Button
                  className="btn btn-secondary"
                  variant="danger"
                  onClick={() => handleDeleteEducation(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </Form>
      ))}
      {!isDisabledForm && (
        <div className="mt-3">
          <Button variant="primary" onClick={handleAdd}>
            Add Education
          </Button>
        </div>
      )}
    </>
  );
};

export default Education;
