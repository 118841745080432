import React, { useState } from "react";
import "../../../../scss/main/popup.css";
import { createApprove } from "../../../../api/users/approvesAPI";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { ApprovingRequestTypes } from "../../../../utils/constants";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const Approves = ({
  candidate,
  professionalInfo,
  approves,
  isDisabledForm,
}) => {
  const pathname = window.location.href.split("/");

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [approvingOptions, setApprovingOptions] = useState([]);
  const [approve, setApprove] = useState({
    target_id: "",
    request_type: "",
  });
  const [approveMsg, setApproveMsg] = useState("");

  const ApprovesRow = (props) => {
    const { admin, approver, created_at, request_type, target_id } = props;

    let name, role, email, approver_type, is_verified, target_title, target_obj;
    if (admin) {
      name = `${admin.first_name} ${admin.last_name}`;
      email = admin.email;
      approver_type = "Admin";
      role = admin.role;
      is_verified = true;
    } else {
      name = `${approver.first_name} ${approver.last_name}`;
      email = approver.email;
      approver_type = approver.approver_type;
      role = approver.approver_role;
      is_verified = !approver.email_not_verified;
    }
    if (is_verified) {
      is_verified = (
        <span
          style={{ display: "inline-block" }}
          className="shape-sm rounded-circle bg-success me-2"
        ></span>
      );
    } else {
      is_verified = (
        <span
          style={{ display: "inline-block" }}
          className="shape-sm rounded-circle bg-danger me-2"
        ></span>
      );
    }
    if (request_type === ApprovingRequestTypes.SKILL) {
      target_obj = candidate.skills.find((i) => i.id === target_id);
      if (target_obj) {
        target_title = target_obj.name;
      }
    } else if (request_type === ApprovingRequestTypes.INDUSTRY) {
      target_obj = candidate.industries.find((i) => i.id === target_id);
      if (target_obj) {
        target_title = target_obj.name;
      }
    } else if (request_type === ApprovingRequestTypes.EXPERIENCE) {
      target_obj = candidate.experience.find((i) => i.id === target_id);
      if (target_obj) {
        target_title = target_obj.title;
      }
    }
    if (!target_obj) {
      return "";
    }

    return (
      <tr>
        <td className="border-0">{target_title}</td>
        <td className="fw-bold border-0">{request_type}</td>
        <td className="border-0">{name}</td>
        <td className="fw-bold border-0">{email}</td>
        <td className="border-0">{role}</td>
        <td className="fw-bold border-0">{approver_type}</td>
        <td className="border-0">{is_verified}</td>
        <td className="border-0">{moment(created_at).format("DD MMM YYYY")}</td>
      </tr>
    );
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleApprovingTargetChange = (e) => {
    const { value } = e;
    setApprove({ ...approve, target_id: value });
  };

  const handleApprovingTypeChange = (e) => {
    const { value } = e.target;
    if (value === ApprovingRequestTypes.EXPERIENCE) {
      setApprovingOptions(
        candidate.experience.map((i) => ({ label: i.title, value: i.id }))
      );
    } else if (value === ApprovingRequestTypes.SKILL) {
      setApprovingOptions([
        ...professionalInfo.core_skills,
        ...professionalInfo.secondary_skills,
      ]);
    } else if (value === ApprovingRequestTypes.INDUSTRY) {
      setApprovingOptions(professionalInfo.industries);
    } else {
      setApprovingOptions([]);
    }
    setApprove({ ...approve, request_type: value });
  };

  const handleCreateApprove = async () => {
    try {
      await createApprove({
        user_id: pathname[pathname.length - 1],
        ...approve,
      });
    } catch (error) {
      setApproveMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <h4 className="my-4">Approving</h4>
      {!isDisabledForm && (
        <Dropdown.Toggle
          as={Button}
          variant="secondary"
          className="text-dark me-2"
          style={{
            width: "150px",
          }}
          onClick={openPopup}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <span>Create New</span>
        </Dropdown.Toggle>
      )}
      {approves?.length > 0 && (
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Title</th>
                  <th className="border-0">Type</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Role</th>
                  <th className="border-0">Approver Type</th>
                  <th className="border-0">Verified</th>
                  <th className="border-0">Created At</th>
                </tr>
              </thead>
              <tbody>
                {approves.map((r) => (
                  <ApprovesRow key={`ranking-${r.id}`} {...r} />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <div className="popup-content">
              <h2>Create Approving</h2>
              <Form onSubmit={handleCreateApprove}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="approving_type">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        onChange={handleApprovingTypeChange}
                        required
                        name="request_type"
                      >
                        <option>Select</option>
                        {Object.entries(ApprovingRequestTypes).map(([k, v]) => (
                          <option key={k} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="target_id">
                      <Form.Label>Target</Form.Label>
                      <Select
                        required
                        name="target_id"
                        options={approvingOptions}
                        classNamePrefix="select"
                        onChange={(v) => handleApprovingTargetChange(v)}
                      ></Select>
                    </Form.Group>
                  </Col>
                </Row>
                {approveMsg && <Alert variant="danger">{approveMsg}</Alert>}
                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Save Approve
                  </Button>
                </div>
              </Form>
              <button className="close-popup" onClick={closePopup}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Approves;
