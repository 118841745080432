import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import {
  createExperience,
  deleteExperience,
  updateExperience,
} from "../../../../api/users/experiencesAPI";
import moment from "moment-timezone";
import useContinents from "../../../../utils/Hooks/useContinents";
import { City } from "country-state-city";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

const Experience = ({ experiences, setExperiences, isDisabledForm }) => {
  const pathname = window.location.href.split("/");
  const { continents } = useContinents();

  const [errorMsgExperience, setErrorMsgExperience] = useState({});

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setExperiences(
      experiences.map((i, n) => {
        if (n === index) i[`${name}`] = value;

        return i;
      })
    );
  };
  const handleSaveExperience = async (index) => {
    try {
      const experience = experiences[index];
      if (experience.end_date && experience.end_date < experience.start_date) {
        setErrorMsgExperience({
          ...errorMsgExperience,
          [index]: "End date must be more than start",
        });
        return false;
      }
      if (
        experience.end_date > new Date() ||
        experience.start_date > new Date()
      ) {
        setErrorMsgExperience({
          ...errorMsgExperience,
          [index]: "Invalid date",
        });
        return false;
      }
      const location = experience.location;

      // const newLocation = {
      //   continent: location.continent,
      //   country: location.country,
      //   // city: location.city.value,
      //   isoCode: location.isoCode,
      // };
      let data = {
        title: experience.title,
        position: experience.position,
        // website: experience.website,
        start_date: experience.start_date,
        description: experience.description,
      };
      if (experience?.still_working || !experience?.end_date) {
        data.end_date = null;
      } else {
        data.end_date = experience?.end_date;
      }
      if (experience.location.continent) {
        data = {
          ...data,
          // location: newLocation,
        };
      }

      if (experience.id) {
        await updateExperience({
          ...data,
          id: experience.id,
          user_id: experience.user_id,
        });
      } else {
        const newExperience = await createExperience({
          ...data,
          user_id: pathname[pathname.length - 1],
        });
        setExperiences(
          experiences.map((i, n) => {
            if (n === index) i.id = newExperience.id;
            return i;
          })
        );
      }
      setErrorMsgExperience({ ...errorMsgExperience, [index]: "" });
    } catch (error) {
      setErrorMsgExperience({
        ...errorMsgExperience,
        [index]: error.response.data.message,
      });
    }
  };

  const handleDeleteExperience = async (index) => {
    const updatedExperiences = [...experiences]; // Assuming forms is your array of forms in the state
    await deleteExperience(
      experiences.find((i, n) => {
        if (n === index) return i;
      }).id
    );
    updatedExperiences.splice(index, 1);
    setExperiences(updatedExperiences); // Update the state with the modified array
  };

  const handleAdd = () => {
    // Add a new experience to the experiences array
    if (!experiences.length || experiences[experiences.length - 1].id) {
      setExperiences([
        ...experiences,
        {
          id: "",
          title: "",
          position: "",
          website: "",
          start_date: "",
          end_date: "",
          description: "",
          still_working: false,
          location: {
            country: "",
            city: "",
            isoCode: "",
            id: uuidv4(),
            continent: "",
          },
        },
      ]);
    }
  };

  const generateCountryList = (location) => {
    const allCountries = Object.values(continents).filter(
      ({ continent }) => continent === location.continent
    );

    if (!allCountries.length) {
      return [];
    }
    return allCountries[0];
  };
  const handleDeleteLocation = (id) => {
    setExperiences(
      experiences.map((experience) => {
        experience.location = experience.location.filter(
          (location) => location.id !== id
        );
        return experience;
      })
    );
  };

  const stillWorkingHandler = (e, index) => {
    const { name, checked } = e.target;
    setExperiences(
      experiences.map((i, n) => {
        if (n === index) {
          i[`${name}`] = checked;
          if (checked) {
            i.end_date = null;
          }
        }
        return i;
      })
    );
  };

  return (
    <>
      <h5 className="my-4">Experience</h5>
      {experiences.map((experience, index) => (
        <Form
          key={index}
          className="my-4"
          onSubmit={(event) => {
            event.preventDefault();
            handleSaveExperience(index);
          }}
        >
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="title"
                  type="text"
                  placeholder="Enter name"
                  value={experience.title}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="position">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="position"
                  type="text"
                  placeholder="Enter position"
                  value={experience.position}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <React.Fragment>
            <Row className="my-2 d-flex justify-content-end align-items-end">
              <Col xs={6}>
                <Form.Group id="location">
                  <Form.Label>Continents</Form.Label>
                  <Select
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    value={
                      experience.location.continent
                        ? {
                            label: experience.location.continent,
                            value: experience.location.continent,
                          }
                        : null
                    }
                    options={Object.values(continents).map((item) => ({
                      value: item.continent,
                      label: item.continent,
                      name: item.continent,
                      id: item.continent,
                    }))}
                    placeholder="Select continent"
                    name="location"
                    className={`basic-multi-select`}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setExperiences((prev) =>
                        prev.map((item) =>
                          item.id === experience.id
                            ? {
                                ...item,
                                location:
                                  item.location.id === experience.location.id
                                    ? {
                                        ...experience.location,
                                        continent: v.value,
                                        country: "",
                                        city: "",
                                      }
                                    : experience.location,
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group id="location">
                  <Form.Label>Country</Form.Label>
                  <Select
                    isDisabled={experience.location.continent === ""}
                    hideSelectedOptions={false}
                    value={
                      experience.location.country
                        ? {
                            label: experience.location.country,
                            value: experience.location.country,
                            isoCode: experience.location.isoCode,
                          }
                        : null
                    }
                    options={generateCountryList(experience.location).country}
                    placeholder="Select country"
                    name="location"
                    className={`basic-multi-select `}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setExperiences((prev) =>
                        prev.map((item) =>
                          item.id === experience.id
                            ? {
                                ...item,
                                location:
                                  item.location.id === experience.location.id
                                    ? {
                                        ...experience.location,
                                        country: v.value,
                                        isoCode: v.isoCode,
                                      }
                                    : experience.location,
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} className="mt-2">
                <Form.Group id="location">
                  <Form.Label>City</Form.Label>
                  <Select
                    hideSelectedOptions={false}
                    value={experience.location.city}
                    isDisabled={experience.location.isoCode === ""}
                    options={
                      City.getCitiesOfCountry(experience.location.isoCode)?.map(
                        ({ name }) => ({
                          label: name,
                          value: name,
                        })
                      ) || []
                    }
                    closeMenuOnSelect={true}
                    placeholder="Select city"
                    name="location"
                    className={`basic-multi-select`}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setExperiences((prev) =>
                        prev.map((item) =>
                          item.id === experience.id
                            ? {
                                ...item,
                                location:
                                  item.location.id === experience.location.id
                                    ? {
                                        ...experience.location,
                                        city: v,
                                      }
                                    : experience.location,
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment> */}

          <Row>
            {/* <Col md={4} className="mb-3">
              <Form.Group id="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="website"
                  type="text"
                  placeholder="Enter website"
                  value={experience.website}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col> */}
            <Col md={4} className="mb-3">
              <Form.Group id="start_date">
                <Form.Label>Start date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="start_date"
                  type="date"
                  value={moment(experience.start_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="end_date">
                <Form.Label>End date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="end_date"
                  type="date"
                  value={moment(experience.end_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                    setExperiences(
                      experiences.map((i, n) => {
                        if (n === index) {
                          i.end_date = v.target.value;
                          i.still_working = false;
                        }
                        return i;
                      })
                    );
                  }}
                />
              </Form.Group>
            </Col>
            <Form.Group>
              <Form.Check
                type="switch"
                name="still_working"
                label="Still working here"
                checked={experience.still_working}
                onChange={(v) => stillWorkingHandler(v, index)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  rows="3"
                  maxLength="1000"
                  disabled={isDisabledForm}
                  name="description"
                  as="textarea"
                  type="text"
                  placeholder="Enter description"
                  value={experience.description}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {errorMsgExperience[`${index}`] && (
            <Alert variant="danger">{errorMsgExperience[`${index}`]}</Alert>
          )}
          {!isDisabledForm && (
            <div className="mt-3 d-flex align-items-center">
              <Button className="me-2 btn" variant="primary" type="submit">
                Save
              </Button>
              {experience.id && (
                <Button
                  className="btn btn-secondary"
                  variant="danger"
                  onClick={() => handleDeleteExperience(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </Form>
      ))}
      {!isDisabledForm && (
        <div className="mt-3">
          <Button variant="primary" onClick={handleAdd}>
            Add Experience
          </Button>
        </div>
      )}
    </>
  );
};

export default Experience;
