import React, {useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import {createUser} from '../../../api/users/usersApi';
import {USER_ROLES} from "../../../utils/constants";
import {useHistory} from "react-router-dom";

export default () => {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    let disableAdminSelect = false;
    const userRole = localStorage.getItem('role');
    if (userRole !== USER_ROLES.SUPER_ADMIN) {
        disableAdminSelect = true;
    }
    const [isDisabledAdminSelect, setIsDisabledAdminSelect] = useState(disableAdminSelect);

    const [userData, setUserData] = useState({
        role: USER_ROLES.SUPPORT,
        email: '',
        last_name: '',
        first_name: '',
        phone_number: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleSaveGeneral = async (event) => {
        event.preventDefault();

        try {
            await createUser({role: userData.role, email: userData.email, last_name: userData.last_name, first_name: userData.first_name, phone_number: userData.phone_number});
            history.push('/users/oatmeal');
        } catch (error) {
            setErrorMsg(error.response.data.message);
            console.error('Error:', error.message);
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} xl={12}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <h5 className="mb-4">General information</h5>
                            <Form onSubmit={handleSaveGeneral}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required name="first_name" type="text" placeholder="Enter first name" value={userData.first_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="lastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required name="last_name" type="text" placeholder="Also last name" value={userData.last_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required name="email" type="email" placeholder="name@mail.com" value={userData.email} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control required name="phone_number" type="phone" placeholder="+12-345 678 910" value={userData.phone_number} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="role">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select name="role" onChange={handleInputChange} defaultValue={USER_ROLES.SUPPORT} value={userData.role}>
                                                <option disabled={isDisabledAdminSelect} value={USER_ROLES.ADMIN}>Admin</option>
                                                <option value={USER_ROLES.SUPPORT}>Support</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                                <div className="mt-3">
                                    <Button variant="primary" type="submit">Save</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
