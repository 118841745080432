import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useHistory } from "react-router-dom";
import { signIn } from "../../api/auth/authAPI";

const Signin = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const history = useHistory();
  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      const response = await signIn(userData.email, userData.password);
      if (response) {
        if (response.qr_code) {
          localStorage.setItem("mfa_url", response.qr_code);
        }
        localStorage.setItem("user_id", response.user_id);
        // Render the MFA page
        window.location.href = "/index.html#/auth/mfa";
        // history.push('/auth/mfa');
        // history.push('/dashboard/overview');
        // window.location.reload();
      } else {
        // Handle other cases, show error messages, etc.
        setErrorMsg("User not exist");
      }

      // You might want to redirect the user or update the UI based on the response.
    } catch (error) {
      // Handle errors
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSignIn}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        name="email"
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        value={userData.email}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          name="password"
                          required
                          type="password"
                          placeholder="Password"
                          value={userData.password}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/*<Form.Check type="checkbox">*/}
                      {/*  <FormCheck.Input id="defaultCheck5" className="me-2" />*/}
                      {/*  <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>*/}
                      {/*</Form.Check>*/}
                      <Card.Link className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Signin;
