import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import {
  EmploymentTypes,
  UserSeniority,
  WorkPreferences,
  Currencies,
} from "../../../../utils/constants";
import { City } from "country-state-city";
import { getLanguages } from "../../../../api/utills/utillsAPI";
import moment from "moment-timezone";

import { getProfessions } from "../../../../api/professions/professionsApi";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { formatTimezonesWithMoment } from "../../../../utils/helpers";
import useContinents from "../../../../utils/Hooks/useContinents";
const MainInformationEdit = ({
  selectedProfessions = [],
  seniorities = [],
  minimum_experience,
  work_preferences = [],
  employment_types = [],
  locations = [],
  visa_sponsorship,
  timezones = [],
  selectedLanguages = [],
  currency,
  minimum_salary,
  maximum_salary,
  title = "",
  setJobForm,
  errors,
}) => {
  const { continents } = useContinents();

  const [professions, setProfessions] = useState([]);
  const [languages, setLanguages] = useState([]);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const loadProfessions = async (inputValue, type) => {
    try {
      const res = await getProfessions(inputValue);

      const newArr = res?.map((skill) => ({
        value: skill.id,
        label: skill.name,
      }));

      setProfessions(newArr);
    } catch (error) {
      console.error(error);
    }
  };
  const handleProfessionsSearch = useCallback((value) => {
    const capitalizedStr = capitalize(value);
    loadProfessions(capitalizedStr, "professions");
  }, []);

  const fetchLanguages = async () => {
    try {
      const res = await getLanguages();
      setLanguages(res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (value, type) => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        [type]: value.map((v) => ({ name: v.label, id: v.value })),
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        [name]: value,
      },
    }));
  };

  const handleMultiSelectChange = (values, name) => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        [name]: values.map((v) => v.value),
      },
    }));
  };

  const handleAddCountries = () => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        locations: [
          ...prev.job.locations,
          {
            id: uuidv4(),
            country: "",
            cities: [],
            isoCode: "",
          },
        ],
      },
    }));
  };

  const deleteLocation = (id) => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        locations: prev.job.locations.filter((loc) => loc.id !== id),
      },
    }));
  };

  const generateCountryList = (location) => {
    const allCountries = Object.values(continents).filter(
      ({ continent }) => continent === location.continent
    );

    if (!allCountries.length) {
      return [];
    }
    return allCountries[0];
  };
  const handleToggle = () => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        visa_sponsorship: !prev.job.visa_sponsorship,
      },
    }));
  };
  const timeZones = formatTimezonesWithMoment(moment).map((item) => ({
    value: item,
    label: item,
    name: item,
  }));

  const hasWorkPreference = (preferences, preference) =>
    preferences.includes(preference);
  const isDisabled =
    hasWorkPreference(work_preferences, "Hybrid") ||
    hasWorkPreference(work_preferences, "On-site");

  const isTimezoneDisabled = hasWorkPreference(work_preferences, "Remote");

  return (
    <Col xs={12}>
      <div className="d-flex align-items-center">
        <Form style={{ width: "100%", marginTop: 0 }}>
          <Row className="mb-2">
            <Form.Group id="title">
              <Form.Label className="form-label">Job Position Name</Form.Label>
              <Form.Control
                required
                name="title"
                type="text"
                className="me-2"
                placeholder="Enter who you are looking for"
                value={title}
                onChange={handleInputChange}
                isInvalid={errors?.title}
              />
            </Form.Group>
          </Row>
          <hr />
          <p className="m-0 mb-0 text-uppercase fw-bold text-muted">
            Experience
          </p>
          <Row className="mb-2">
            <Form.Group id="title">
              <Form.Label className="form-label">Job Roles</Form.Label>
              <Select
                required
                value={
                  selectedProfessions.map((d) => ({
                    value: d.id,
                    label: d.name,
                  })) || []
                }
                options={professions}
                onFocus={() => handleProfessionsSearch("")}
                isClearable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                className={`basic-multi-select ${
                  errors?.professions_ids ? "error" : ""
                }`}
                isMulti
                placeholder="Type to select from dropdown"
                name="professions"
                classNamePrefix="select"
                onChange={(v) => handleSelectChange(v, "professions")}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Col xs={6}>
              <Form.Group id="title">
                <Form.Label className="form-label">
                  Years of Experience: From
                </Form.Label>
                <Form.Control
                  required
                  name="minimum_experience"
                  type="number"
                  className="me-2"
                  placeholder="Enter number of years of experience"
                  value={minimum_experience}
                  onChange={handleInputChange}
                  isInvalid={errors?.minimum_experience}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group id="title">
                <Form.Label className="form-label">Experience Level</Form.Label>
                <Select
                  required
                  value={
                    seniorities.map((d) => ({
                      value: d,
                      label: d,
                    })) || []
                  }
                  options={Object.entries(UserSeniority).map(([k, v]) => ({
                    value: v,
                    label: v,
                  }))}
                  isClearable={false}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder="Type to select from dropdown"
                  name="seniorities"
                  className={`basic-multi-select ${
                    errors?.seniorities ? "error" : ""
                  }`}
                  classNamePrefix="select"
                  onChange={(v) => handleMultiSelectChange(v, "seniorities")}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <p className="m-0 mb-0 text-uppercase fw-bold text-muted">
            Preferences
          </p>
          <Row className="my-2">
            <Form.Group id="employment_types">
              <Form.Label>Employment</Form.Label>
              <Select
                required
                hideSelectedOptions={false}
                value={
                  employment_types.map((d) => ({
                    value: d,
                    label: d,
                  })) || []
                }
                options={Object.entries(EmploymentTypes).map(([k, v]) => ({
                  value: v,
                  label: v,
                }))}
                isMulti
                placeholder="Select employment type"
                name="employment_types"
                className={`basic-multi-select ${
                  errors?.employment_types ? "error" : ""
                }
                    `}
                classNamePrefix="select"
                onChange={(v) => handleMultiSelectChange(v, "employment_types")}
              />
            </Form.Group>
          </Row>

          <Row className="my-2">
            <Col xs={2}>
              <Form.Group id="title">
                <Form.Label className="form-label">Currency</Form.Label>
                <Form.Select
                  name="currency"
                  onChange={handleInputChange}
                  defaultValue={currency}
                  value={currency || "USD"}
                  isInvalid={errors?.currency}
                >
                  {Object.entries(Currencies).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={5}>
              <Form.Group id="title">
                <Form.Label className="form-label">Minimum Salary</Form.Label>
                <Form.Control
                  required
                  name="minimum_salary"
                  type="number"
                  className="me-2"
                  placeholder="Enter minimum salary"
                  value={minimum_salary}
                  onChange={handleInputChange}
                  isInvalid={errors?.minimum_salary}
                />
              </Form.Group>
            </Col>
            <Col xs={5}>
              <Form.Group id="title">
                <Form.Label className="form-label">Maximum Salary</Form.Label>
                <Form.Control
                  required
                  name="maximum_salary"
                  type="number"
                  className="me-2"
                  placeholder="Enter maximum salary"
                  value={maximum_salary}
                  onChange={handleInputChange}
                  isInvalid={errors?.maximum_salary}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="my-2">
            <Form.Group id="work_preferences">
              <Form.Label>Work Preference</Form.Label>
              <Select
                required
                hideSelectedOptions={false}
                value={
                  work_preferences.map((d) => ({
                    value: d,
                    label: d,
                  })) || []
                }
                options={Object.entries(WorkPreferences).map(([k, v]) => ({
                  value: v,
                  label: v,
                }))}
                isMulti
                closeMenuOnSelect={false}
                placeholder="Select work preferences type"
                name="work_preferences"
                className={`basic-multi-select ${
                  errors?.work_preferences ? "error" : ""
                }`}
                classNamePrefix="select"
                onChange={(v) => handleMultiSelectChange(v, "work_preferences")}
              />
            </Form.Group>
          </Row>

          {locations.map((location) => (
            <Row
              className="my-2 d-flex justify-content-end align-items-end"
              key={location.id}
            >
              <Col xs={6}>
                <Form.Group id="locations">
                  <Form.Label>Continents</Form.Label>
                  <Select
                    required
                    isDisabled={!isDisabled}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    value={
                      location.continent
                        ? {
                            label: location.continent,
                            value: location.continent,
                          }
                        : null
                    }
                    options={Object.values(continents).map((item) => ({
                      value: item.continent,
                      label: item.continent,
                      name: item.continent,
                      id: item.continent,
                    }))}
                    placeholder="Select continent"
                    name="locations"
                    className={`basic-multi-select ${
                      errors?.continent?.[location.id] ? "error" : ""
                    }
                    `}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setJobForm((prev) => ({
                        ...prev,
                        job: {
                          ...prev.job,
                          locations: prev.job.locations.map((loc) => {
                            if (loc.id === location.id) {
                              return {
                                ...loc,
                                cities: [],
                                country: "",
                                isoCode: "",
                                continent: v.label,
                              };
                            }
                            return loc;
                          }),
                        },
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group id="locations">
                  <Form.Label>Country</Form.Label>
                  <Select
                    required
                    isDisabled={!isDisabled || location.continent === ""}
                    hideSelectedOptions={false}
                    value={
                      location.country
                        ? {
                            label: location.country,
                            value: location.country,
                            isoCode: location.isoCode,
                          }
                        : null
                    }
                    options={generateCountryList(location).country}
                    placeholder="Select country"
                    name="locations"
                    className={`basic-multi-select ${
                      errors?.country?.[location.id] ? "error" : ""
                    }
                    `}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setJobForm((prev) => ({
                        ...prev,
                        job: {
                          ...prev.job,
                          locations: prev.job.locations.map((loc) => {
                            if (loc.id === location.id) {
                              return {
                                ...loc,
                                country: v.label,
                                cities: [],
                                isoCode: v.isoCode,
                              };
                            }
                            return loc;
                          }),
                        },
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-2">
                <Form.Group id="locations">
                  <Form.Label>Cities</Form.Label>
                  <Select
                    required
                    hideSelectedOptions={false}
                    value={
                      location.cities.map((d) => ({
                        value: d,
                        label: d,
                      })) || null
                    }
                    isOptionDisabled={() => location.cities.length >= 3}
                    isDisabled={location.isoCode === ""}
                    isMulti
                    options={
                      City.getCitiesOfCountry(location.isoCode)?.map(
                        ({ name }) => ({
                          label: name,
                          value: name,
                        })
                      ) || []
                    }
                    closeMenuOnSelect={false}
                    placeholder="Select cities"
                    name="locations"
                    className={`basic-multi-select ${
                      errors?.cities?.[location.id] ? "error" : ""
                    }
                    `}
                    classNamePrefix="select"
                    onChange={(v) => {
                      setJobForm((prev) => ({
                        ...prev,
                        job: {
                          ...prev.job,
                          locations: prev.job.locations.map((loc) => {
                            if (loc.id === location.id) {
                              return {
                                ...loc,
                                cities: v.map((city) => city.value),
                              };
                            }
                            return loc;
                          }),
                        },
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
              {locations.length > 1 && (
                <Col xs={2} className="mt-2 ">
                  <Button
                    variant="danger"
                    onClick={() => deleteLocation(location.id)}
                  >
                    Delete
                  </Button>
                </Col>
              )}
            </Row>
          ))}
          <div className="mt-3">
            <Button variant="primary" onClick={handleAddCountries}>
              Add Another Work Location
            </Button>
          </div>

          <Col className="my-3">
            <Form.Group>
              <Form.Check
                type="switch"
                name="visa_sponsorship"
                label="We provide visa sponsorship"
                checked={visa_sponsorship}
                onChange={handleToggle}
              />
            </Form.Group>
          </Col>
          <Row className="my-2">
            <Form.Group id="languages">
              <Form.Label>Languages</Form.Label>
              <Select
                required
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={
                  selectedLanguages.map((d) => ({ value: d, label: d })) || []
                }
                options={
                  languages.map((lang) => ({
                    value: lang.name,
                    label: lang.name,
                  })) || []
                }
                isMulti
                onFocus={() => fetchLanguages("")}
                placeholder="Select languages"
                name="languages"
                className={`basic-multi-select ${
                  errors?.languages ? "error" : ""
                }`}
                classNamePrefix="select"
                onChange={(v) => handleMultiSelectChange(v, "languages")}
              />
            </Form.Group>
          </Row>
          <Row className="my-2">
            <Col xs={6}>
              <Form.Group id="timezones">
                <Form.Label>Timezone Flexibility Range: Start</Form.Label>
                <Select
                  required
                  isDisabled={!isTimezoneDisabled}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  value={{ value: timezones[0], label: timezones[0] }}
                  options={timeZones}
                  placeholder="Select timezone"
                  name="timezones"
                  className={`basic-multi-select ${
                    errors?.timezones ? "error" : ""
                  } 
                  `}
                  classNamePrefix="select"
                  onChange={(v) => {
                    setJobForm((prev) => ({
                      ...prev,
                      job: {
                        ...prev.job,
                        timezones: [v.value, prev.job.timezones[1]],
                      },
                    }));
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group id="timezones">
                <Form.Label>Timezone Flexibility Range: End</Form.Label>
                <Select
                  required
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  isDisabled={!isTimezoneDisabled}
                  value={{ value: timezones[1], label: timezones[1] }}
                  options={timeZones}
                  placeholder="Select timezone"
                  name="timezones"
                  className={`basic-multi-select ${
                    errors?.timezones ? "error" : ""
                  }`}
                  classNamePrefix="select"
                  onChange={(v) => {
                    setJobForm((prev) => ({
                      ...prev,
                      job: {
                        ...prev.job,
                        timezones: [prev.job.timezones[0], v.value],
                      },
                    }));
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
        </Form>
      </div>
    </Col>
  );
};

export default MainInformationEdit;
