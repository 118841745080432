import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getCandidates = async (query) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates?${qs.stringify(query)}`
  );

  return data;
};

export const getCandidate = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(`${API_ROOT_URL}/porrige/candidates/${id}`);

  return data;
};

export const getWallet = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates/wallet/${id}`
  );

  return data;
};

export const getJobPreferences = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates/job-preferences/${id}`
  );

  return data;
};

export const createCandidate = async (candidate) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(
    `${API_ROOT_URL}/porrige/candidates`,
    candidate
  );

  return data;
};

export const saveCandidate = async (user_id, candidate) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(
    `${API_ROOT_URL}/porrige/candidates/${user_id}`,
    candidate
  );

  return data;
};

export const saveJobPreferences = async (user_id, job_preferences) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(
    `${API_ROOT_URL}/porrige/candidates/job-preferences/${user_id}`,
    job_preferences
  );

  return data;
};

export const saveProfessionalInfo = async (user_id, professional_info) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(
    `${API_ROOT_URL}/porrige/candidates/professional-info/${user_id}`,
    professional_info
  );

  return data;
};

export const deleteCandidate = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.delete(
    `${API_ROOT_URL}/porrige/candidates/${id}`
  );

  return data;
};

export const getJobProposals = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates/proposals/${id}`
  );

  return data;
};

export const getOnboardingLifecycle = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates/get-onboarding-lifecycle/${id}`
  );

  return data;
};

export const saveIsActive = async (user_id, status) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/candidates/is-active`,
    { user_id, status }
  );

  return data;
};

export const saveVerifyByCalyptus = async (user_id, status) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/candidates/verify-by-calyptus`,
    { user_id, status }
  );

  return data;
};

export const saveContacts = async (id, contacts) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(
    `${API_ROOT_URL}/porrige/candidates/save-contacts/${id}`,
    contacts
  );

  return data;
};

export const checkProfile = async (user_id, status) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/candidates/save-profile-checking`,
    { user_id, status }
  );

  return data;
};

export const saveProfileSummary = async (user_id, description) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/candidates/save-description/${user_id}`,
    { description }
  );

  return data;
};

export const identifyRegistration = async (user_id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/candidates/identify-registration/${user_id}`
  );

  return data;
};

export const getUserPoints = async (userId) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(`${API_ROOT_URL}/porrige/points/${userId}`);

  return data;
};

export const addTransaction = async (userId, transaction) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(
    `${API_ROOT_URL}/porrige/points/${userId}/add-transaction`,
    transaction
  );

  return data;
};

export const addToWhitelist = async (userId) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(
    `${API_ROOT_URL}/porrige/candidates/${userId}/add-to-whitelist`
  );

  return data;
};

export const removeFromWhitelist = async (userId) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(
    `${API_ROOT_URL}/porrige/candidates/${userId}/remove-from-whitelist`
  );

  return data;
};
