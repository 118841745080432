import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import {
  createPortfolio,
  deletePortfolio,
  updatePortfolio,
} from "../../../../api/users/portfoliosAPI";
import moment from "moment-timezone";
import { ChooseFileWidget } from "../../../../components/Widgets";

const Portfolio = ({ portfolios, setPortfolios, isDisabledForm }) => {
  const pathname = window.location.href.split("/");
  const [errorMsgPortfolio, setErrorMsgPortfolio] = useState({});

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setPortfolios(
      portfolios.map((i, n) => {
        if (n === index) i[`${name}`] = value;
        return i;
      })
    );
  };

  const handleSavePortfolio = async (index) => {
    try {
      const portfolio = portfolios[index];
      if (portfolio.end_date && portfolio.end_date < portfolio.start_date) {
        setErrorMsgPortfolio({
          ...errorMsgPortfolio,
          [index]: "End date must be more than start",
        });
        return false;
      }
      if (
        portfolio.end_date > new Date() ||
        portfolio.start_date > new Date()
      ) {
        setErrorMsgPortfolio({ ...errorMsgPortfolio, [index]: "Invalid date" });
        return false;
      }
      if (portfolio.id) {
        await updatePortfolio({
          id: portfolio.id,
          user_id: portfolio.user_id,
          title: portfolio.title,
          role: portfolio.role,
          portfolio_link: portfolio.portfolio_link,
          url: portfolio.url,
          start_date: portfolio.start_date,
          end_date: portfolio.end_date,
          description: portfolio.description,
        });
      } else {
        const newPortfolio = await createPortfolio({
          title: portfolio.title,
          user_id: pathname[pathname.length - 1],
          role: portfolio.role,
          portfolio_link: portfolio.portfolio_link,
          url: portfolio.url,
          start_date: portfolio.start_date,
          end_date: portfolio.end_date,
          description: portfolio.description,
        });
        setPortfolios(
          portfolios.map((i, n) => {
            if (n === index) i.id = newPortfolio.id;
            return i;
          })
        );
      }
      setErrorMsgPortfolio({ ...errorMsgPortfolio, [index]: "" });
    } catch (error) {
      setErrorMsgPortfolio({
        ...errorMsgPortfolio,
        [index]: error.response.data.message,
      });
    }
  };

  const handleDeletePortfolio = async (index) => {
    const updatedPortfolios = [...portfolios]; // Assuming forms is your array of forms in the state
    await deletePortfolio(
      portfolios.find((i, n) => {
        if (n === index) return i;
      }).id
    );
    updatedPortfolios.splice(index, 1);
    setPortfolios(updatedPortfolios); // Update the state with the modified array
  };

  const handleAdd = () => {
    if (!portfolios.length || portfolios[portfolios.length - 1].id) {
      setPortfolios([...portfolios, {}]);
    }
  };

  return (
    <>
      <h5 className="my-4">Portfolio</h5>
      {portfolios.map((portfolio, index) => (
        <Form
          key={index}
          className="my-4"
          onSubmit={(event) => {
            event.preventDefault();
            handleSavePortfolio(index);
          }}
        >
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="title"
                  type="text"
                  placeholder="Enter name"
                  value={portfolio.title}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="role">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="role"
                  type="text"
                  placeholder="Enter role"
                  value={portfolio.role}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="portfolio_link">
                <Form.Label>Portfolio</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="role"
                  type="text"
                  placeholder="Enter url"
                  value={portfolio.portfolio_link}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="url">
                <Form.Label>Url</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="url"
                  type="text"
                  placeholder="Enter url"
                  value={portfolio.url}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="start_date">
                <Form.Label>Start date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  required
                  name="start_date"
                  type="date"
                  value={moment(portfolio.start_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="end_date">
                <Form.Label>End date</Form.Label>
                <Form.Control
                  disabled={isDisabledForm}
                  name="end_date"
                  type="date"
                  value={moment(portfolio.end_date).format("YYYY-MM-DD")}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  rows="3"
                  maxLength="500"
                  disabled={isDisabledForm}
                  name="description"
                  as="textarea"
                  type="text"
                  placeholder="Enter description"
                  value={portfolio.description}
                  onChange={(v) => {
                    handleChange(v, index);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ChooseFileWidget
                title="Attach portfolio file"
                file={portfolio?.attached_file?.url}
              />
            </Col>
          </Row>
          {errorMsgPortfolio[`${index}`] && (
            <Alert variant="danger">{errorMsgPortfolio[`${index}`]}</Alert>
          )}
          {!isDisabledForm && (
            <div className="mt-3 d-flex align-items-center">
              <Button className="me-2 btn" variant="primary" type="submit">
                Save
              </Button>
              {portfolio.id && (
                <Button
                  className="btn btn-secondary"
                  variant="danger"
                  onClick={() => handleDeletePortfolio(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </Form>
      ))}
      {!isDisabledForm && (
        <div className="mt-3">
          <Button variant="primary" onClick={handleAdd}>
            Add Portfolio
          </Button>
        </div>
      )}
    </>
  );
};

export default Portfolio;
