import moment from "moment-timezone";
import React from "react";
import { Card, Table } from "@themesberg/react-bootstrap";

const Proposals = ({ jobs }) => {
  const JobsRow = (props) => {
    const { status, created_at, job } = props;

    return (
      <tr>
        <td className="border-0">{job.title}</td>
        <td className="border-0">{job.company.name}</td>
        <td className="border-0">{status}</td>
        <td className="border-0">
          {`${job.minimum_salary}-${job.maximum_salary} ${job.currency}`}
        </td>
        <td className="border-0">{moment(created_at).format("DD MMM YYYY")}</td>
      </tr>
    );
  };

  return (
    <>
      <h4 className="my-4">Jobs</h4>
      {jobs?.length && (
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Title</th>
                  <th className="border-0">Company</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Salary</th>
                  <th className="border-0">Created At</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((r) => (
                  <JobsRow key={`job-${r.id}`} {...r} />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Proposals;
