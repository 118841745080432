import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getExperiences = async (query) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/users-experience?${qs.stringify(query)}`
  );

  return data;
};

export const createExperience = async (experience) => {
  const axios = getAxiosInstance();
  const { data } = await axios.post(
    `${API_ROOT_URL}/porrige/users-experience`,
    experience
  );

  return data;
};

export const updateExperience = async (experience) => {
  const axios = getAxiosInstance();
  const { data } = await axios.put(
    `${API_ROOT_URL}/porrige/users-experience`,
    experience
  );

  return data;
};

export const deleteExperience = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.delete(
    `${API_ROOT_URL}/porrige/users-experience/${id}`
  );

  return data;
};

export const saveLanguages = async (id, languages) => {
  const axios = getAxiosInstance();
  const { data } = await axios.patch(
    `${API_ROOT_URL}/porrige/candidates/save-languages/${id}`,
    { languages: languages }
  );

  return data;
};
