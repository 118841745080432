import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getManagers = async (id) => {
  const axios = getAxiosInstance();
  const { data } = await axios.get(
    `${API_ROOT_URL}/porrige/company/managers/${id}`
  );

  return data;
};
