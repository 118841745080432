import React, { useEffect, useState } from "react";
import { Country, City } from "country-state-city";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Table,
  Tabs,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  saveUserStatus,
  saveUserPassword,
  resetForgotPasswordLimit,
} from "../../../api/users/usersApi";
import {
  Currencies,
  EmploymentTypes,
  IndustriesTypes,
  USER_ROLES,
  USER_STATUSES,
  UserSeniority,
  WorkPreferences,
} from "../../../utils/constants";
import {
  addToWhitelist,
  checkProfile,
  deleteCandidate,
  getCandidate,
  getJobPreferences,
  getJobProposals,
  getOnboardingLifecycle,
  getWallet,
  identifyRegistration,
  profileChecked,
  removeFromWhitelist,
  saveCandidate,
  saveIsActive,
  saveJobPreferences,
  saveProfessionalInfo,
  saveProfileSummary,
  saveVerifyByCalyptus,
} from "../../../api/users/candidatesApi";
import Select from "react-select";
import { getSkills } from "../../../api/skills/skillsApi";
import { getProfessions } from "../../../api/professions/professionsApi";
import { getIndustries } from "../../../api/industries/industriesApi";
import { getCultures } from "../../../api/cultures/culturesApi";
import Experience from "./components/Experience";
import Education from "./components/Education";
import Portfolio from "./components/Portfolio";
import Approves from "./components/Approves";
import Certificate from "./components/Certificate";
import Assessments from "./components/Assessments";
import Proposals from "./components/Proposals";
import { ChoosePhotoWidget } from "../../../components/Widgets";
import useContinents from "../../../utils/Hooks/useContinents";
import { v4 as uuidv4 } from "uuid";
import Locations from "../../../components/Locations";
import linkedIn from "../../../assets/img/icons/linkedIn.svg";
import {
  formatTimezonesWithMoment,
  shortSocialUrl,
} from "../../../utils/helpers";
import ContactInformation from "./ContactInformation";
import moment from "moment-timezone";
import Languages from "./components/Languages";
import { getExperiences } from "../../../api/users/experiencesAPI";
import { getEducations } from "../../../api/users/educationsAPI";
import { getPortfolios } from "../../../api/users/portfoliosAPI";
import { getCertificates } from "../../../api/users/certificatesAPI";
import { getApproves } from "../../../api/users/approvesAPI";
import { getAssessments } from "../../../api/users/assessmentsAPI";
import Points from "./components/Points";

const CandidateDetails = () => {
  const history = useHistory();
  const pathname = window.location.href.split("/");
  const userRole = localStorage.getItem("role");
  const { continents } = useContinents();

  const [errorMsg, setErrorMsg] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsgPass, setErrorMsgPass] = useState("");
  const [errorMsgBtn, setErrorMsgBtn] = useState("");
  const [errorPreferencesMsg, setErrorPreferencesMsg] = useState("");
  const [errorProfessionalMsg, setErrorProfessionalMsg] = useState("");
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const [userStatus, setUserStatus] = useState({
    verified: false,
    blocked: false,
  });
  const [skillsList, setSkillsList] = useState([]);
  const [professionsList, setProfessionList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [culturesList, setCulturesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [registrationType, setRegistrationType] = useState("");
  const [walletData, setWalletData] = useState({
    address: "",
    transaction: "",
    contract: "",
  });

  const [experiences, setExperiences] = useState([]);
  const [educations, setEducations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [approves, setApproves] = useState([]);

  const [userData, setUserData] = useState({
    email: "",
    status: "",
    last_name: "",
    first_name: "",
    created_at: "",
    phone_number: "",
    seniority: "",
    experience_years: "",
    industries: [],
    skills: [],
    experience: [],
    locations: [],
    countries: [],
    profile_picture: { url: "" },
    profile_picture_id: "",
    is_whitelisted: null,
  });
  const [contacts, setContacts] = useState({
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    youtube: "",
    github: "",
    is_telegram: false,
    is_whatsapp: false,
    socials: [],
  });

  const [userLinkedIn, setUserLinkedIn] = useState("");
  const [jobPreferences, setJobPreferences] = useState({
    currency: "",
    salary: "",
    work_preference: "",
    employment_type: "",
    timezones: [],
    availability: "",
    is_active: false,
  });

  const [professionalInfo, setProfessionalInfo] = useState({
    professions: "",
    core_skills: "",
    secondary_skills: "",
    cultures: "",
    industries: "",
    preference_industries: "",
  });

  const [profileSummary, setProfileSummary] = useState("");

  const [lifecycle, setLifecycle] = useState({
    percent: 0,
    lifecycle: {},
  });

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [forgotPasswordLimit, setForgotPasswordLimit] = useState(false);

  const createInputChangeHandler = (setter) => (e) => {
    const { name, value } = e.target;
    setter((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = createInputChangeHandler(setUserData);
  const handlePreferencesChange = createInputChangeHandler(setJobPreferences);
  const handleContactsChange = createInputChangeHandler(setContacts);

  const handleMultiSelectChange = (values, name, type) => {
    if (type === "job_preferences") {
      setJobPreferences({ ...jobPreferences, [name]: values });
    } else if (type === "professional_info") {
      setProfessionalInfo({ ...professionalInfo, [name]: values });
    }
  };

  const handleAddLocation = () => {
    if (
      !userData.locations.length ||
      userData.locations[userData.locations.length - 1].cities?.length
    ) {
      setUserData({
        ...userData,
        locations: [
          ...userData.locations,
          {
            cities: [],
            country: "",
            isoCode: "",
            continent: "",
            id: uuidv4(),
            UsersLocationsModel: { is_eligible: false },
          },
        ],
      });
    }
  };

  const handleDeleteLocation = (id) => {
    const updatedLocations = userData.locations.filter((i) => i.id !== id);
    setUserData({ ...userData, locations: updatedLocations });
  };

  const handleAddCountries = () => {
    if (
      !userData.countries.length ||
      userData.countries[userData.countries.length - 1].country
    ) {
      setUserData({
        ...userData,
        countries: [
          ...userData.countries,
          {
            cities: [],
            country: "",
            isoCode: "",
            continent: "",
            is_eligible: false,
            id: uuidv4(),
          },
        ],
      });
    }
  };

  const handleDeleteCountries = (index) => {
    const updatedUserData = { ...userData };
    updatedUserData.countries.splice(index, 1);
    setUserData(updatedUserData);
  };

  const handleEligibilityChange = (e, index) => {
    const { name, checked } = e.target;
    setUserData({
      ...userData,
      countries: userData.countries.map((i, n) => {
        if (n === index) {
          i.is_eligible = checked;
        }
        return i;
      }),
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCandidate(pathname[pathname.length - 1]);
        const experiences = await getExperiences({
          user_id: pathname[pathname.length - 1],
        });
        const educations = await getEducations({
          user_id: pathname[pathname.length - 1],
        });
        const portfolios = await getPortfolios({
          user_id: pathname[pathname.length - 1],
        });
        const certificates = await getCertificates({
          user_id: pathname[pathname.length - 1],
        });
        const jobs = await getJobProposals(pathname[pathname.length - 1]);
        const approves = await getApproves(pathname[pathname.length - 1]);
        const assessments = await getAssessments(pathname[pathname.length - 1]);
        setAssessments(assessments);
        setApproves(approves);
        setJobs(jobs);

        setCertificates(certificates);
        setPortfolios(portfolios);
        setEducations(educations);
        setExperiences(
          experiences.map((experience) => {
            experience.location = experience.location
              ? {
                  ...experience.location,
                  id: uuidv4(),
                  city: {
                    label: experience.location.city,
                    value: experience.location.city,
                  },
                }
              : {
                  country: "",
                  city: "",
                  isoCode: "",
                  id: uuidv4(),
                  continent: "",
                };

            return {
              ...experience,
              still_working: experience.end_date ? false : true,
            };
          })
        );
        setUserLinkedIn(res.contacts.linkedin || "");
        setContacts({
          facebook: res.contacts.facebook || "",
          linkedin: res.contacts.linkedin || "",
          twitter: res.contacts.twitter || "",
          instagram: res.contacts.instagram || "",
          youtube: res.contacts.youtube || "",
          github: res.contacts.github || "",
          is_telegram: res.contacts.is_telegram || false,
          is_whatsapp: res.contacts.is_whatsapp || false,
          socials: res.contacts.socials
            ? res.contacts.socials.map((i) => {
                return { name: i.name, link: i.link, id: uuidv4() };
              })
            : [],
        });
        setUserData({
          ...res,
          experience_years: res.cv_details.experience_years,
          locations: res.locations.map((i) => {
            i.cities = i.cities.map((city) => ({ value: city, label: city }));
            return i;
          }),
        });
        setSelectedLanguages(
          res.cv_details.languages?.length > 0
            ? res.cv_details.languages.map((item) => ({
                value: item.lang,
                label: item.lang,
                level: item.level,
                id: uuidv4(),
              }))
            : [
                {
                  value: "",
                  label: "",
                  level: "",
                  id: uuidv4(),
                },
              ]
        );
        setProfileSummary(res.cv_details.description);
        if (userRole === USER_ROLES.SUPPORT) {
          setIsDisabledForm(true);
        }
        if (res.status === USER_STATUSES.VERIFIED) {
          setUserStatus({ verified: true });
        } else if (res.status === USER_STATUSES.BLOCKED) {
          setUserStatus({ blocked: true });
        }
        const jobPreferencesRes = await getJobPreferences(
          pathname[pathname.length - 1]
        );
        setJobPreferences({
          is_active: jobPreferencesRes.is_active || false,
          currency: jobPreferencesRes.currency || "USD",
          timezones: jobPreferencesRes.timezones || [],
          availability: jobPreferencesRes.availability || "",
          employment_type:
            jobPreferencesRes.employment_type?.length > 0
              ? jobPreferencesRes.employment_type?.map((i) => ({
                  value: i,
                  label: i,
                }))
              : [],
          salary: jobPreferencesRes.salary || "",
          work_preference:
            jobPreferencesRes.work_preference?.length > 0
              ? jobPreferencesRes.work_preference?.map((i) => ({
                  value: i,
                  label: i,
                }))
              : [],
        });
        setProfessionalInfo({
          core_skills: res.skills
            .map((i) => {
              if (i.UsersSkillsModel.is_core)
                return { value: i.id, label: i.name };
            })
            .filter(Boolean),
          professions: res.professions.map((i) => ({
            value: i.id,
            label: i.name,
          })),
          secondary_skills: res.skills
            .map((i) => {
              if (!i.UsersSkillsModel.is_core)
                return { value: i.id, label: i.name };
            })
            .filter(Boolean),
          cultures: jobPreferencesRes.preference_cultures.map((i) => ({
            value: i.id,
            label: i.name,
          })),
          industries: res.industries.map((i) => ({
            value: i.id,
            label: i.name,
          })),
          preference_industries: jobPreferencesRes.industries.map((i) => ({
            value: i.id,
            label: i.name,
          })),
        });

        const registration = await identifyRegistration(
          pathname[pathname.length - 1]
        );
        setRegistrationType(registration.data);
        const lifecycle = await getOnboardingLifecycle(
          pathname[pathname.length - 1]
        );
        setLifecycle(lifecycle);

        const countries = Country.getAllCountries();
        setCountriesList(countries);

        const walletRes = await getWallet(pathname[pathname.length - 1]);
        setWalletData(walletRes);

        if (res.forgot_password_count > 4) {
          setForgotPasswordLimit(true);
        } else {
          setForgotPasswordLimit(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);
  const handleSaveGeneral = async (event) => {
    event.preventDefault();
    try {
      const data = {
        email: userData.email,
        last_name: userData.last_name,
        first_name: userData.first_name,
        phone_number: userData.phone_number,
        seniority: userData.seniority,
        experience_years: +userData.experience_years,
        countries: userData.countries.map((i) => ({
          country: i.country,
          is_eligible: i.is_eligible,
          continent: i.continent,
        })),
        locations: userData.locations.map((i) => ({
          isoCode: i.isoCode,
          country: i.country,
          cities: i.cities.map((city) => city.value),
          continent: i.continent,
        })),
        timezones: jobPreferences.timezones,
      };

      await saveCandidate(pathname[pathname.length - 1], data);
      history.push("/users/candidates");
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };
  const handleSavePreferences = async (event) => {
    event.preventDefault();

    try {
      await saveJobPreferences(pathname[pathname.length - 1], {
        currency: jobPreferences.currency,
        employment_type: jobPreferences.employment_type.map((i) => i.value),
        salary: +jobPreferences.salary,
        work_preference: jobPreferences.work_preference.map((i) => i.value),

        availability: +jobPreferences.availability,
      });
      history.push("/users/candidates");
    } catch (error) {
      setErrorPreferencesMsg(error.response.data.message);
    }
  };

  const handleSaveProfessionalInfo = async (event) => {
    event.preventDefault();
    try {
      const info = { ...professionalInfo };
      Object.keys(professionalInfo).forEach((i) => {
        info[i] = info[i].map((l) => l.value);
      });
      await saveProfessionalInfo(pathname[pathname.length - 1], info);
      history.push("/users/candidates");
    } catch (error) {
      setErrorProfessionalMsg(error.response.data.message);
    }
  };

  const onSaveProfileSummary = async (e) => {
    e.preventDefault();
    await saveProfileSummary(pathname[pathname.length - 1], profileSummary);
  };

  const handleSavePassword = async (event) => {
    event.preventDefault();

    try {
      await saveUserPassword(pathname[pathname.length - 1], password);
      history.push("/users/candidates");
    } catch (error) {
      setErrorMsgPass(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCandidate(pathname[pathname.length - 1]);
      history.push("/users/candidates");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
    }
  };

  const handleStatus = async (status) => {
    try {
      await saveUserStatus(pathname[pathname.length - 1], status);
      history.push("/users/candidates");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
    }
  };

  const handleIsActive = async (e) => {
    const { name, checked } = e.target;
    setJobPreferences({ ...jobPreferences, [name]: checked });
    await saveIsActive(pathname[pathname.length - 1], checked);
  };

  const handleVerifiedByCalyptus = async (e) => {
    const { name, checked } = e.target;
    setUserData({ ...userData, [name]: checked });
    try {
      await saveVerifyByCalyptus(pathname[pathname.length - 1], checked);
    } catch (error) {
      // setErrorMsg(error.response.data.message);
      setVerifyError(error.response.data.message);
    }
  };

  const checkProfileHandler = async (e) => {
    const { name, checked } = e.target;
    await checkProfile(pathname[pathname.length - 1], checked);
    setUserData({ ...userData, [name]: checked });
  };

  const handleForgotPasswordLimit = async () => {
    setForgotPasswordLimit(false);
    await resetForgotPasswordLimit(pathname[pathname.length - 1]);
  };

  const handleSkillsList = (search) => {
    try {
      if (search.length) {
        getSkills({ search: search }).then((arr) =>
          setSkillsList(
            arr.map((skill) => ({ value: skill.id, label: skill.name }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const handleProfessionsList = (search) => {
    try {
      if (search?.length) {
        getProfessions({ search: search }).then((arr) =>
          setProfessionList(
            arr.map((skill) => ({ value: skill.id, label: skill.name }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const handleIndustriesList = (search, type) => {
    try {
      if (search.length) {
        getIndustries({ search: search, type: type }).then((arr) =>
          setIndustriesList(
            arr.map((industry) => ({
              value: industry.id,
              label: industry.name,
            }))
          )
        );
      }
    } catch (error) {
      setErrorProfessionalMsg(error.response.data.message);
    }
  };

  const handleCulturesList = (search) => {
    try {
      if (search.length) {
        getCultures({ search: search }).then((arr) =>
          setCulturesList(
            arr.map((culture) => ({ value: culture.id, label: culture.name }))
          )
        );
      }
    } catch (error) {
      setErrorProfessionalMsg(error.response.data.message);
    }
  };

  const generateCountryList = (location) => {
    const allCountries = Object.values(continents).filter(
      ({ continent }) => continent === location.continent
    );

    if (!allCountries.length) {
      return [];
    }
    return allCountries[0];
  };

  const navigateTo = (url) => {
    window.open(url, "_blank");
  };
  const timeZones = formatTimezonesWithMoment(moment).map((item) => ({
    value: item,
    label: item,
    name: item,
  }));

  const refreshWhitelistStatus = async () => {
    try {
      const res = await getCandidate(pathname[pathname.length - 1]);
      setUserData({ ...userData, is_whitelisted: res.is_whitelisted });
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const _addToWhitelist = async () => {
    try {
      await addToWhitelist(pathname[pathname.length - 1]);
      refreshWhitelistStatus();
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const _removeFromWhitelist = async () => {
    try {
      await removeFromWhitelist(pathname[pathname.length - 1]);
      refreshWhitelistStatus();
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };
  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Tabs
                defaultActiveKey="general"
                id="controlled-tab-example"
                className="mb-4"
              >
                <Tab eventKey="general" title="General Information">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="my-4">General information</h5>
                    {!userData.is_whitelisted ? (
                      <Button
                        size="md"
                        variant="success"
                        style={{
                          height: "40px",
                        }}
                        className=""
                        onClick={_addToWhitelist}
                      >
                        Add to Whitelist
                      </Button>
                    ) : (
                      <Button
                        size="md"
                        variant="danger"
                        className=""
                        onClick={_removeFromWhitelist}
                      >
                        Remove from Whitelist
                      </Button>
                    )}
                  </div>

                  <Row>
                    {registrationType && (
                      <Col md={3}>
                        <p className="d-flex align-items-center m-0 p-0">
                          Registration: {registrationType}
                        </p>
                      </Col>
                    )}
                    {userLinkedIn && (
                      <Col md={6}>
                        <div
                          onClick={() => navigateTo(userLinkedIn)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={linkedIn}
                            alt="linkedin"
                            style={{
                              height: "24px",
                              width: "24px",
                              marginRight: "8px",
                            }}
                          />
                          <p className="d-flex align-items-center m-0 p-0">
                            {userLinkedIn
                              ? shortSocialUrl(userLinkedIn)
                              : "No link"}
                          </p>
                        </div>
                      </Col>
                    )}

                    {userData.cv_file?.url && (
                      <Col md={3}>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => navigateTo(userData.cv_file?.url)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Candidate CV
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <Form onSubmit={handleSaveGeneral}>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="first_name"
                            type="text"
                            placeholder="Enter first name"
                            value={userData.first_name}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="last_name"
                            type="text"
                            placeholder="Also last name"
                            value={userData.last_name}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="email"
                            type="email"
                            placeholder="name@company.com"
                            value={userData.email}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="phone">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            // required
                            name="phone_number"
                            type="phone"
                            placeholder="+12-345 678 910"
                            value={userData.phone_number}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-2">
                        <Form.Group id="seniority">
                          <Form.Label>Seniority</Form.Label>
                          <Form.Select
                            name="seniority"
                            onChange={handleInputChange}
                            defaultValue={userData.seniority}
                            value={userData.seniority}
                          >
                            {Object.entries(UserSeniority).map(([k, v]) => (
                              <option key={k} value={v}>
                                {v}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-2">
                        <Form.Group id="experience">
                          <Form.Label>Experience Years</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="experience_years"
                            type="number"
                            placeholder=""
                            value={userData.experience_years}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Group id="timezones">
                          <Form.Label>
                            Timezone Flexibility Range: Start
                          </Form.Label>
                          <Select
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            value={
                              jobPreferences.timezones[0]
                                ? {
                                    value: jobPreferences.timezones[0],
                                    label: jobPreferences.timezones[0],
                                  }
                                : null
                            }
                            options={timeZones}
                            placeholder="Select timezone"
                            name="timezones"
                            classNamePrefix="select"
                            onChange={(v) => {
                              setJobPreferences({
                                ...jobPreferences,
                                timezones: [
                                  v.value,
                                  jobPreferences.timezones[1],
                                ],
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group id="timezones">
                          <Form.Label>
                            Timezone Flexibility Range: End
                          </Form.Label>
                          <Select
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            value={
                              jobPreferences.timezones[1]
                                ? {
                                    value: jobPreferences.timezones[1],
                                    label: jobPreferences.timezones[1],
                                  }
                                : null
                            }
                            options={timeZones}
                            placeholder="Select timezone"
                            name="timezones"
                            classNamePrefix="select"
                            onChange={(v) => {
                              setJobPreferences({
                                ...jobPreferences,
                                timezones: [
                                  jobPreferences.timezones[0],
                                  v.value,
                                ],
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {userData.countries.map((country, index) => (
                      <Row className="d-flex mt-2 align-items-center">
                        <Col>
                          <Form.Group id="locations">
                            <Form.Label>Continents</Form.Label>
                            <Select
                              // required
                              hideSelectedOptions={false}
                              closeMenuOnSelect={true}
                              value={
                                country.continent
                                  ? {
                                      label: country.continent,
                                      value: country.continent,
                                    }
                                  : null
                              }
                              options={Object.values(continents).map(
                                (item) => ({
                                  value: item.continent,
                                  label: item.continent,
                                  name: item.continent,
                                  id: item.continent,
                                })
                              )}
                              placeholder="Select continent"
                              name="locations"
                              className={`basic-multi-select`}
                              classNamePrefix="select"
                              onChange={(v) => {
                                setUserData(
                                  (prev) => {
                                    const updatedLocations = [
                                      ...prev.countries,
                                    ];
                                    updatedLocations[index].continent = v.value;
                                    return {
                                      ...prev,
                                      countries: updatedLocations,
                                    };
                                  },
                                  [index]
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group id="locations">
                            <Form.Label>Country</Form.Label>
                            <Select
                              // required
                              isDisabled={country.continent === ""}
                              hideSelectedOptions={false}
                              value={
                                country.country
                                  ? {
                                      label: country.country,
                                      value: country.country,
                                      isoCode: country.isoCode,
                                    }
                                  : null
                              }
                              options={generateCountryList(country).country}
                              placeholder="Select country"
                              name="locations"
                              className={`basic-multi-select`}
                              classNamePrefix="select"
                              onChange={(v) => {
                                setUserData((prev) => {
                                  const updatedLocations = [...prev.countries];
                                  updatedLocations[index].country = v.value;
                                  updatedLocations[index].isoCode = v.isoCode;
                                  return {
                                    ...prev,
                                    countries: updatedLocations,
                                  };
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>

                        <Col
                          md={3}
                          className="mb-0"
                          style={{ marginTop: "36px" }}
                        >
                          <Form.Group>
                            <Form.Check
                              disabled={isDisabledForm}
                              type="switch"
                              name="is_eligible"
                              label="Is eligible"
                              checked={country.is_eligible}
                              onChange={(v) =>
                                handleEligibilityChange(v, index)
                              }
                            />
                          </Form.Group>
                        </Col>
                        {userData.countries.length > 1 && (
                          <button
                            onClick={() => handleDeleteCountries(index)}
                            style={{
                              width: "30px",
                              marginTop: "36px",
                              padding: "0",
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        )}
                      </Row>
                    ))}
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" onClick={handleAddCountries}>
                          Add Country
                        </Button>
                      </div>
                    )}

                    <Form.Label>Preference locations</Form.Label>

                    {userData.locations.map((location, index) => (
                      <React.Fragment key={location.id}>
                        <Locations
                          location={location}
                          continents={continents}
                          setState={setUserData}
                          generateCountryList={generateCountryList}
                          City={City}
                          handleDeleteLocation={handleDeleteLocation}
                          data={userData}
                          index={index}
                          deleteLocation={true}
                        />
                      </React.Fragment>
                    ))}
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" onClick={handleAddLocation}>
                          Add Location
                        </Button>
                      </div>
                    )}
                    {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" type="submit">
                          Save General
                        </Button>
                      </div>
                    )}
                  </Form>
                  <Form onSubmit={onSaveProfileSummary}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="description">
                        <Form.Label>Profile Summary</Form.Label>
                        <Form.Control
                          rows="5"
                          maxLength="2000"
                          disabled={isDisabledForm}
                          name="description"
                          as="textarea"
                          type="text"
                          placeholder="Enter description"
                          value={profileSummary}
                          onChange={(v) => setProfileSummary(v.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" type="submit">
                          Save Summary
                        </Button>
                      </div>
                    )}
                  </Form>
                  <ContactInformation
                    contacts={contacts}
                    handleContactsChange={handleContactsChange}
                    setContacts={setContacts}
                    isDisabledForm={isDisabledForm}
                  />
                  <Form onSubmit={handleSavePreferences}>
                    <h5 className="my-4">Set Preferences</h5>
                    <Row>
                      <Col md={6}>
                        <Form.Group id="currency">
                          <Form.Label>Currency</Form.Label>
                          <Form.Select
                            disabled={isDisabledForm}
                            name="currency"
                            onChange={handlePreferencesChange}
                            defaultValue={jobPreferences.currency}
                            value={jobPreferences.currency}
                          >
                            {Object.entries(Currencies).map(([k, v]) => (
                              <option key={k} value={v}>
                                {v}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group id="salary">
                          <Form.Label>Salary</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="salary"
                            type="number"
                            placeholder="50000"
                            value={jobPreferences.salary}
                            onChange={handlePreferencesChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group id="availability">
                        <Form.Label>Notice Period</Form.Label>
                        <Form.Control
                          name="availability"
                          type="number"
                          placeholder=""
                          value={jobPreferences.availability}
                          onChange={handlePreferencesChange}
                        />
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="work_preference">
                          <Form.Label>Work Preference</Form.Label>
                          <Select
                            disabled={isDisabledForm}
                            required
                            value={jobPreferences.work_preference}
                            options={Object.entries(WorkPreferences).map(
                              ([k, v]) => ({ value: v, label: v })
                            )}
                            isMulti
                            name="work_preference"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "work_preference",
                                "job_preferences"
                              );
                            }}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="employment_type">
                          <Form.Label>Employment Type</Form.Label>
                          <Select
                            disabled={isDisabledForm}
                            required
                            value={jobPreferences.employment_type}
                            options={Object.entries(EmploymentTypes).map(
                              ([k, v]) => ({ value: v, label: v })
                            )}
                            isMulti
                            name="employment_type"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "employment_type",
                                "job_preferences"
                              );
                            }}
                          ></Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    {errorPreferencesMsg && (
                      <Alert variant="danger">{errorPreferencesMsg}</Alert>
                    )}
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" type="submit">
                          Save Preferences details
                        </Button>
                      </div>
                    )}
                  </Form>

                  <Form onSubmit={handleSaveProfessionalInfo}>
                    <h5 className="my-4">Set Professional Info</h5>

                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group id="professions">
                          <Form.Label>Profession</Form.Label>
                          <Select
                            required
                            value={professionalInfo.professions}
                            options={professionsList}
                            isMulti
                            name="professions"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "professions",
                                "professional_info"
                              );
                            }}
                            onInputChange={handleProfessionsList}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="core_skills">
                          <Form.Label>Core Skills</Form.Label>
                          <Select
                            required
                            value={professionalInfo.core_skills}
                            options={skillsList}
                            isMulti
                            name="core_skills"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "core_skills",
                                "professional_info"
                              );
                            }}
                            onInputChange={handleSkillsList}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="secondary_skills">
                          <Form.Label>Secondary Skills</Form.Label>
                          <Select
                            // required
                            value={professionalInfo.secondary_skills}
                            options={skillsList}
                            isMulti
                            name="secondary_skills"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "secondary_skills",
                                "professional_info"
                              );
                            }}
                            onInputChange={handleSkillsList}
                          ></Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group id="cultures">
                          <Form.Label>Cultures</Form.Label>
                          <Select
                            // required
                            value={professionalInfo.cultures}
                            options={culturesList}
                            isMulti
                            name="cultures"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "cultures",
                                "professional_info"
                              );
                            }}
                            onInputChange={handleCulturesList}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="industries">
                          <Form.Label>Industries</Form.Label>
                          <Select
                            // required
                            value={professionalInfo.industries}
                            options={industriesList}
                            isMulti
                            name="industries"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "industries",
                                "professional_info"
                              );
                            }}
                            onInputChange={(v) => {
                              handleIndustriesList(
                                v,
                                IndustriesTypes.INDUSTRY_DOMAIN
                              );
                            }}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="secondary_skills">
                          <Form.Label>Preference Industries</Form.Label>
                          <Select
                            required
                            value={professionalInfo.preference_industries}
                            options={industriesList}
                            isMulti
                            name="preference_industries"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(
                                v,
                                "preference_industries",
                                "professional_info"
                              );
                            }}
                            onInputChange={(v) => {
                              handleIndustriesList(v, IndustriesTypes.INDUSTRY);
                            }}
                          ></Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    {errorProfessionalMsg && (
                      <Alert variant="danger">{errorProfessionalMsg}</Alert>
                    )}
                    {!isDisabledForm && (
                      <div className="mt-3">
                        <Button variant="primary" type="submit">
                          Save Professional Info
                        </Button>
                      </div>
                    )}
                  </Form>
                </Tab>
                <Tab eventKey="experience" title="Experience">
                  <Experience
                    experiences={experiences}
                    setExperiences={setExperiences}
                    isDisabledForm={isDisabledForm}
                  />
                  <Education
                    educations={educations}
                    setEducations={setEducations}
                    isDisabledForm={isDisabledForm}
                  />
                  <Portfolio
                    portfolios={portfolios}
                    setPortfolios={setPortfolios}
                    isDisabledForm={isDisabledForm}
                  />
                  <Certificate
                    certificates={certificates}
                    setCertificates={setCertificates}
                    isDisabledForm={isDisabledForm}
                  />
                  <Languages
                    isDisabledForm={isDisabledForm}
                    languages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                  />
                </Tab>
                <Tab eventKey="proposals" title="Proposals">
                  <Proposals jobs={jobs} />
                </Tab>
                <Tab eventKey="points" title="Points">
                  <Points />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <div>
                <p>
                  Creation Date:{" "}
                  {new Date(userData.created_at).toLocaleDateString()}
                </p>

                <p>Status: {userData.status}</p>
                <p>
                  <Form.Check
                    disabled={isDisabledForm}
                    type="switch"
                    id="isProfileChecked"
                    label="Profile checked"
                    name="is_profile_checked"
                    checked={userData.is_profile_checked}
                    onChange={checkProfileHandler}
                  />
                </p>
                <p>
                  <Form.Check
                    disabled={isDisabledForm}
                    type="switch"
                    id="is_active-switch"
                    label="Open for opportunities"
                    name="is_active"
                    checked={jobPreferences.is_active}
                    onChange={handleIsActive}
                  />
                </p>

                <p>
                  <Form.Check
                    disabled={isDisabledForm}
                    type="switch"
                    id="verified_by_calyptus-switch"
                    name="verified_by_calyptus"
                    label="Recruiters Verified"
                    checked={userData.verified_by_calyptus}
                    onChange={handleVerifiedByCalyptus}
                  />
                </p>
                {verifyError && <Alert variant="danger">{verifyError}</Alert>}
                {forgotPasswordLimit && (
                  <p>
                    <Form.Check
                      disabled={isDisabledForm}
                      type="switch"
                      id="forgot_password_limit-switch"
                      name="forgot_password_limit"
                      label="Forgot password limit"
                      checked={forgotPasswordLimit}
                      onChange={handleForgotPasswordLimit}
                    />
                  </p>
                )}
                <p>Profile completion: {lifecycle.percent}%</p>
                {walletData.address && (
                  <p>Wallet address: {lifecycle.address}</p>
                )}
                {walletData.transaction && (
                  <p>
                    <a href={walletData.transaction} target="_blank">
                      Transaction
                    </a>
                  </p>
                )}
                {walletData.contract && (
                  <p>
                    <a href={walletData.contract} target="_blank">
                      digital CV
                    </a>
                  </p>
                )}
              </div>
            </Col>
            {!isDisabledForm && (
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <Button
                    variant="danger"
                    onClick={() => handleDelete()}
                    className="me-2"
                  >
                    Delete
                  </Button>
                  {userStatus.blocked && (
                    <Button
                      variant="secondary"
                      onClick={() => handleStatus(USER_STATUSES.VERIFIED)}
                    >
                      Unblock
                    </Button>
                  )}
                  {userStatus.verified && (
                    <Button
                      variant="secondary"
                      onClick={() => handleStatus(USER_STATUSES.BLOCKED)}
                    >
                      Block
                    </Button>
                  )}
                </div>
              </Col>
            )}
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={userData?.profile_picture?.url}
              />
            </Col>
          </Row>
          {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
        </Col>
        <Assessments assessments={assessments} />
        <Approves
          candidate={userData}
          professionalInfo={professionalInfo}
          approves={approves}
          isDisabledForm={isDisabledForm}
        />
      </Row>
    </>
  );
};

export default CandidateDetails;
