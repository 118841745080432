import React from 'react';
import {useHistory, Switch, Route} from 'react-router-dom';
import HomePage from './pages/HomePage';
import Signin from './pages/examples/Signin';
import MFA from './pages/examples/MFA';
import SetPassword from './pages/examples/SetPassword';
import NotFoundPage from './pages/examples/NotFound';

const LoginRoute = () => {
    const history = useHistory();
    const token = localStorage.getItem('token');
    // If the token is not present, redirect to the sign-in page
    if (!token) {
        if (!window.location.href.includes('/auth/set-password/')) {
            window.location.href = '/index.html#/auth/sign-in';
            // history.push('/index.html#/auth/sign-in');
        }
        return (
            <Switch>
                <Route exact path='/auth/sign-in'>
                    <Signin />
                </Route>
                <Route path='/auth/mfa'>
                    <MFA />
                </Route>
                <Route path='/auth/set-password'>
                    <SetPassword />
                </Route>
                <Route path='/404'>
                    <NotFoundPage />
                </Route>
            </Switch>
        );
    }

    // If the token is present, you can render your protected content here
    return <HomePage />;
};
export default LoginRoute;
