// export const API_ROOT_URL = "https://api.calyptus.co";
export const API_ROOT_URL = "https://dev.api.calyptus.co";

export const USER_ROLES = {
  ADMIN: "admin",
  CANDIDATE: "candidate",
  COMPANY: "company",
  RECRUITER: "recruiter",
  SUPER_ADMIN: "super admin",
  SUPPORT: "support",
};

export const USER_STATUSES = {
  APPLICANT: "applicant",
  NOT_VERIFIED: "not_verified",
  VERIFIED: "verified",
  BLOCKED: "blocked",
};

export const UserSeniority = {
  TRAINEE_JUNIOR: "Trainee/Junior",
  MIDDLE: "Middle",
  SENIOR: "Senior",
  LEADERSHIP: "Leadership",
  C_LEVEL: "C-level",
  VP: "VP",
  DIRECTOR: "Director",
};

export const WorkPreferences = {
  REMOTE: "Remote",
  HYBRID: "Hybrid",
  ON_SITE: "On-site",
};
export const EmploymentTypes = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
  CONTRACT: "Contract",
  // TEMPORARY: 'Temporary',
  // INTERNSHIP: 'Internship',
  // OTHER: 'Other'
};

export const Currencies = {
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
};

export const JobStatuses = {
  PUBLISHED: "published",
  DRAFT: "draft",
  ON_HOLD: "on hold",
  CLOSED: "closed",
};

export const ChatsApplicationStatuses = {
  WAITING: "Waiting for Review",
  SHORTLISTED: "Shortlisted",
  INTERVIEWING: "Interviewing",
  REJECTED: "Rejected",
  HIRED: "Hired",
};

export const EducationTypes = {
  BACHELOR: "Bachelor",
  MASTER: "Master",
  PHD: "PhD",
  ASSOCIATE: "Associate",
  POSTGRADUATE: "Postgraduate",
  HIGH_SCHOOL: "High School",
};

export const ApprovingRequestTypes = {
  SKILL: "skill",
  // CERTIFICATE: 'certificate',
  // PORTFOLIO: 'portfolio',
  EXPERIENCE: "experience",
  INDUSTRY: "industry",
};

export const CertificatesProvidersTypes = {
  CREDLY: "credly",
  MICROSOFT: "microsoft",
};

export const CompanySizes = {
  "1-10": "1-10",
  "11-50": "11-50",
  "51-100": "51-100",
  "101-500": "101-500",
  "501+": "501+",
};

export const S3_URL = "https://calyptus-fs-dev.s3.eu-west-2.amazonaws.com";

export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!+%*?&])[A-Za-z\d@$!+%*?&]{8,16}$/;

export const SUBSCRIPTION_PLANS = {
  "1-10": {
    6: 18000,
    12: 24000,
  },
  "11-50": {
    6: 24000,
    12: 36000,
  },
  "51-100": {
    6: 30000,
    12: 45000,
  },
  "101-500": {
    6: 48000,
    12: 72000,
  },
  "501+": {
    6: 72000,
    12: 108000,
  },
};
export const CURRENCY_CONVERTOR = {
  USD: 1.25,
  EUR: 1.15,
  GBP: 1,
};
export const BillingPaymentPlans = {
  MONTH: "per_month",
  HIRE: "per_hire",
};
export const IndustriesTypes = {
  INDUSTRY: "industry",
  INDUSTRY_DOMAIN: "industry and domain",
};

export const languageLevels = [
  {
    label: "Beginner",
    value: "Beginner",
  },

  {
    label: "Pre-intermediate",
    value: "Pre-intermediate",
  },
  {
    label: "Intermediate",
    value: "Intermediate",
  },
  {
    label: "Upper-intermediate",
    value: "Upper-intermediate",
  },
  {
    label: "Advanced",
    value: "Advanced",
  },
  {
    label: "Fluent/Native",
    value: "Fluent/Native",
  },
];
