import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from 'qs';

export const getUsers = async (query) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/users?${qs.stringify(query)}`);

    return data;
};

export const getUser = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/users/${id}`);

    return data;
};

export const deleteUser = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.delete(`${API_ROOT_URL}/porrige/users/${id}`);

    return data;
};

export const saveUserStatus = async (id, status) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/users/status`, {id: id, status: status});

    return data;
};

export const saveUserPassword = async (id, password) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/users/password`, {id: id, password: password});

    return data;
}

export const saveUser = async (id, user) => {
    const axios = getAxiosInstance();
    const { data } = await axios.put(`${API_ROOT_URL}/porrige/users/${id}`, user);
    return data;
}

export const createUser = async (user) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/users`, user);
    return data;
}

export const resetForgotPasswordLimit = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/users/reset-forgot-password-limit/${id}`);

    return data;
};
