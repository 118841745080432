import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import Select from "react-select";

const Locations = ({
  location,
  continents,
  setState,
  generateCountryList,
  City,
  handleDeleteLocation,
  data,
  index,
  deleteLocation = false,
}) => {
  return (
    <Row
      className="my-2 d-flex justify-content-end align-items-end"
      key={location.id}
    >
      <Col xs={6}>
        <Form.Group id="locations">
          <Form.Label>Continents</Form.Label>
          <Select
            // required
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            value={
              location.continent
                ? {
                    label: location.continent,
                    value: location.continent,
                  }
                : null
            }
            options={Object.values(continents).map((item) => ({
              value: item.continent,
              label: item.continent,
              name: item.continent,
              id: item.continent,
            }))}
            placeholder="Select continent"
            name="locations"
            className={`basic-multi-select`}
            classNamePrefix="select"
            onChange={(v) => {
              setState((prev) => {
                const updatedLocations = [...prev.locations];
                updatedLocations[index].continent = v.value;
                updatedLocations[index].country = "";
                updatedLocations[index].cities = [];
                return {
                  ...prev,
                  locations: updatedLocations,
                };
              });
            }}
          />
        </Form.Group>
      </Col>
      <Col xs={6}>
        <Form.Group id="locations">
          <Form.Label>Country</Form.Label>
          <Select
            // required
            isDisabled={location.continent === ""}
            hideSelectedOptions={false}
            value={
              location.country
                ? {
                    label: location.country,
                    value: location.country,
                    isoCode: location.isoCode,
                  }
                : null
            }
            options={generateCountryList(location).country}
            placeholder="Select country"
            name="locations"
            className={`basic-multi-select `}
            classNamePrefix="select"
            onChange={(v) => {
              setState((prev) => {
                const updatedLocations = [...prev.locations];
                updatedLocations[index].country = v.value;
                updatedLocations[index].cities = [];
                updatedLocations[index].isoCode = v.isoCode;

                return {
                  ...prev,
                  locations: updatedLocations,
                };
              });
            }}
          />
        </Form.Group>
      </Col>

      <Col xs={12} className="mt-2">
        <Form.Group id="locations">
          <Form.Label>Cities</Form.Label>
          <Select
            // required
            hideSelectedOptions={false}
            value={
              location.cities
                ? location.cities.map((city) => ({
                    label: city.label,
                    value: city.value,
                  }))
                : null
            }
            isDisabled={location.isoCode === ""}
            isMulti
            options={
              City.getCitiesOfCountry(location.isoCode)?.map(({ name }) => ({
                label: name,
                value: name,
              })) || []
            }
            isOptionDisabled={() => location.cities.length >= 3}
            closeMenuOnSelect={false}
            placeholder="Select cities"
            name="locations"
            className={`basic-multi-select`}
            classNamePrefix="select"
            onChange={(v) => {
              setState((prev) => {
                const updatedLocations = [...prev.locations];
                updatedLocations[index].cities = v;
                return {
                  ...prev,
                  locations: updatedLocations,
                };
              });
            }}
          />
        </Form.Group>
      </Col>

      {(deleteLocation || data.locations.length > 1) && (
        <Button
          className="mt-2"
          variant="danger"
          style={{ width: "100px" }}
          onClick={() => handleDeleteLocation(location.id)}
        >
          Delete
        </Button>
      )}
    </Row>
  );
};

export default Locations;
