import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";
import {
  BillingPaymentPlans,
  CompanySizes,
  CURRENCY_CONVERTOR,
  SUBSCRIPTION_PLANS,
  USER_ROLES,
  USER_STATUSES,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  deleteCompany,
  getCompany,
  sendToLever,
  updateCompany,
  updateCompanyUser,
} from "../../../api/users/companies/CompaniesApi";
import { getLocations } from "../../../api/locations/locationsApi";
import { getIndustries } from "../../../api/industries/industriesApi";
import { getCultures } from "../../../api/cultures/culturesApi";
import Select from "react-select";
import {
  resetForgotPasswordLimit,
  saveUserStatus,
} from "../../../api/users/usersApi";
import {
  activatePayPerHire,
  createSubscription,
  updateSubscription,
} from "../../../api/users/companies/SubscriptionsAPI";
import Invoices from "./components/Invoices";
import { ChoosePhotoWidget } from "../../../components/Widgets";
import { City } from "country-state-city";
import { v4 as uuidv4 } from "uuid";
import Locations from "../../../components/Locations";
import useContinents from "../../../utils/Hooks/useContinents";

const CompanyDetails = () => {
  const history = useHistory();

  const { continents } = useContinents();

  const userRole = localStorage.getItem("role");
  const pathname = window.location.href.split("/");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgSubscription, setErrorMsgSubscription] = useState("");
  const [errorMsgOwner, setErrorMsgOwner] = useState("");
  const [errorMsgBtn, setErrorMsgBtn] = useState("");
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const [forgotPasswordLimit, setForgotPasswordLimit] = useState(false);
  const [ownerStatus, setOwnerStatus] = useState({
    verified: false,
    blocked: false,
  });
  const [locationsList, setLocationsList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [culturesList, setCulturesList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [companyData, setCompanyData] = useState({
    id: "",
    name: "",
    website: "",
    description: "",
    location_id: "",
    year_founded: "",
    size: CompanySizes["1-10"],
    is_active: false,
    is_send_to_lever: false,
    cultures: [],
    industries: [],
    locations: [],
    contact: { socials: [{}], linkedin: "", twitter: "" },
    profile_picture_id: "",
    profile_picture: { url: "" },
  });
  const [ownerData, setOwnerData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    status: "",
    profile_picture: { url: "" },
    profile_picture_id: "",
  });
  const [subscriptionData, setSubscriptionData] = useState({
    company_id: "",
    stripe_id: "",
    stripe_subscription_id: "",
    subscription_id: "",
    total_price: "",
    currency: "",
    payment_plan: "",
  });

  const [isLever, setIsLever] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleOwnerInputChange = (e) => {
    const { name, value } = e.target;
    setOwnerData({
      ...ownerData,
      [name]: value,
    });
  };

  const handleSubscriptionInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData({
      ...subscriptionData,
      [name]: value,
    });
  };

  const handleSocialInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      contact: { ...companyData.contact, [name]: value },
    });
  };

  const handleMultiSelectChange = (values, name) => {
    setCompanyData({ ...companyData, [name]: values });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCompany(pathname[pathname.length - 1]);
        if (res.contact) {
          if (!res.contact.socials) {
            res.contact.socials = [{}];
          }
        }
        setCompanyData({
          ...res,
          locations: res.locations.map((i) => {
            i.cities = i.cities.map((city) => ({ value: city, label: city }));
            i.continent = i.continent || "";
            i.id = i.id || uuidv4();
            return i;
          }),
          contact: res.contact || { socials: [{}], linkedin: "", twitter: "" },
          cultures: res?.cultures.map((i) => ({ value: i.id, label: i.name })),
          industries: res?.industries.map((i) => ({
            value: i.id,
            label: i.name,
          })),
        });

        if (res?.lever?.lever_token) {
          setIsLever(true);
        } else {
          setIsLever(false);
        }

        if (userRole === USER_ROLES.SUPPORT) {
          setIsDisabledForm(true);
        }

        const owner = res.users.find((i) => i.role === USER_ROLES.COMPANY);
        if (owner.forgot_password_count > 4) {
          setForgotPasswordLimit(true);
        } else {
          setForgotPasswordLimit(false);
        }
        if (owner.status === USER_STATUSES.VERIFIED) {
          setOwnerStatus({ verified: true });
        } else if (owner.status === USER_STATUSES.BLOCKED) {
          setOwnerStatus({ blocked: true });
        }
        setOwnerData(owner);

        const locations = await getLocations(null);
        setLocationsList(locations);

        // const plans = await getSubscriptionPlans();
        let plans = [];
        if (res.size) {
          let id_num = 1;
          for (const [k, v] of Object.entries(SUBSCRIPTION_PLANS[res.size])) {
            for (const [c_k, c_v] of Object.entries(CURRENCY_CONVERTOR)) {
              if (
                res.subscription?.currency === c_k &&
                res.subscription?.subscription_id === Number(k)
              ) {
                res.subscription.subscription_id = id_num;
              }
              plans.push({
                id: id_num,
                count: k,
                title: `${k} Months`,
                price: ((v * c_v) / k).toFixed() + " per month",
                currency: c_k,
              });
              id_num += 1;
            }
          }
        }
        setSubscriptionData(res.subscription);
        setPlansList(plans);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const handleSaveGeneral = async (event) => {
    event.preventDefault();
    try {
      await updateCompany(pathname[pathname.length - 1], {
        locations: companyData.locations.map((i) => ({
          isoCode: i.isoCode,
          country: i.country,
          cities: i.cities.map((city) => city.value),
          continent: i.continent,
        })),
        contact: companyData.contact,
        name: companyData.name,
        website: companyData.website,
        description: companyData.description,
        year_founded: new Date(companyData.year_founded).toISOString(),
        size: companyData.size,
        location_id: companyData.location_id,
        cultures: companyData.cultures.map((i) => i.value),
        industries: companyData.industries.map((i) => i.value),
      });
      history.push("/users/companies");
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleSaveSubscription = async (event) => {
    event.preventDefault();
    const subscription = plansList.find(
      (i) => i.id === Number(subscriptionData.subscription_id)
    );
    try {
      if (subscriptionData.stripe_subscription_id) {
        await updateSubscription({
          company_id: pathname[pathname.length - 1],
          subscription_id: Number(subscription.count),
          currency: subscription.currency,
        });
      } else {
        await createSubscription({
          company_id: pathname[pathname.length - 1],
          subscription_id: Number(subscription.count),
          currency: subscription.currency,
        });
      }
      history.push("/users/companies");
    } catch (error) {
      setErrorMsgSubscription(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleSaveSubscriptionPerHire = async (event) => {
    event.preventDefault();
    try {
      await activatePayPerHire(pathname[pathname.length - 1]);
      history.push("/users/companies");
    } catch (error) {
      setErrorMsgSubscription(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleSaveOwner = async (event) => {
    event.preventDefault();
    try {
      await updateCompanyUser(ownerData.id, {
        first_name: ownerData.first_name,
        last_name: ownerData.last_name,
        email: ownerData.email,
        phone_number: ownerData.phone_number,
        position: ownerData.position,
      });
      history.push("/users/companies");
    } catch (error) {
      setErrorMsgOwner(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCompany(pathname[pathname.length - 1]);
      history.push("/users/companies");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
    }
  };

  const handleStatus = async (status, id) => {
    try {
      await saveUserStatus(id, status);
      history.push("/users/companies");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
    }
  };

  const handleIndustriesList = (search) => {
    try {
      if (search.length) {
        getIndustries({ search: search }).then((arr) =>
          setIndustriesList(
            arr.map((industry) => ({
              value: industry.id,
              label: industry.name,
            }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleCulturesList = (search) => {
    try {
      if (search.length) {
        getCultures({ search: search }).then((arr) =>
          setCulturesList(
            arr.map((culture) => ({ value: culture.id, label: culture.name }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleSocialsChange = (e, index) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      contact: {
        ...companyData.contact,
        socials: companyData.contact.socials.map((i, n) => {
          if (n === index) i[name] = value;
          return i;
        }),
      },
    });
  };

  const handleAddSocial = () => {
    if (
      !companyData.contact.socials.length ||
      companyData.contact.socials[companyData.contact.socials.length - 1]?.name
    ) {
      setCompanyData({
        ...companyData,
        contact: {
          ...companyData.contact,
          socials: [...companyData.contact.socials, {}],
        },
      });
    }
  };

  const handleDeleteSocial = (index) => {
    const updatedCompanyData = { ...companyData };
    updatedCompanyData.contact.socials.splice(index, 1);
    setCompanyData(updatedCompanyData);
  };

  const handleForgotPasswordLimit = async () => {
    setForgotPasswordLimit(false);
    await resetForgotPasswordLimit(pathname[pathname.length - 1]);
  };

  const handleSendToLever = async (e) => {
    const { name, checked } = e.target;
    setCompanyData({ ...companyData, [name]: checked });
    await sendToLever(pathname[pathname.length - 1], checked);
  };

  const handleLocationsChange = (value, index, name) => {
    if (name === "isoCode") {
      setCompanyData({
        ...companyData,
        locations: companyData.locations.map((i, n) => {
          if (n === index) {
            i[name] = value.target.value;
            i.cities = [];
          }
          return i;
        }),
      });
    } else {
      setCompanyData({
        ...companyData,
        locations: companyData.locations.map((i, n) => {
          if (n === index) {
            i[name] = value;
          }
          return i;
        }),
      });
    }
  };

  const handleAddLocation = () => {
    if (
      !companyData.locations.length ||
      companyData.locations[companyData.locations.length - 1].cities?.length
    ) {
      setCompanyData({
        ...companyData,
        locations: [
          ...companyData.locations,
          { cities: [], country: "", isoCode: "", continent: "", id: uuidv4() },
        ],
      });
      //   setCompanyData({
      //     ...companyData,
      //     locations: [...companyData.locations, {}],
      //   });
    }
  };
  const handleDeleteLocation = (id) => {
    const updatedCompanyData = { ...companyData };
    updatedCompanyData.locations = updatedCompanyData.locations.filter(
      (i) => i.id !== id
    );
    setCompanyData(updatedCompanyData);
  };
  const generateCountryList = (location) => {
    const allCountries = Object.values(continents).filter(
      ({ continent }) => continent === location.continent
    );

    if (!allCountries.length) {
      return [];
    }
    return allCountries[0];
  };

  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Tabs defaultActiveKey="general" id="controlled-tab-example">
                <Tab eventKey="general" title="General Information">
                  <h5 className="my-4">General information</h5>
                  <Form onSubmit={handleSaveGeneral}>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="name">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="name"
                            type="text"
                            placeholder="Enter company name"
                            value={companyData.name}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="year_founded">
                          <Form.Label>Founded</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="year_founded"
                            type="date"
                            min="1900"
                            max={new Date().toISOString().split("T")[0]}
                            value={companyData.year_founded}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="size">
                          <Form.Label>Size</Form.Label>
                          <Form.Select
                            disabled={isDisabledForm}
                            name="size"
                            onChange={handleInputChange}
                            defaultValue={companyData.size}
                            value={companyData.size}
                          >
                            {Object.entries(CompanySizes).map(([k, v]) => (
                              <option key={k} value={v}>
                                {v}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="location_id">
                          <Form.Label>Location</Form.Label>
                          <Form.Select
                            disabled={isDisabledForm}
                            name="location_id"
                            onChange={handleInputChange}
                            defaultValue={companyData.location_id}
                            value={companyData.location_id}
                          >
                            <option>Select</option>
                            {locationsList.map((i, index) => (
                              <option value={i.id} key={index}>
                                {i.city} ({i.country})
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group id="website">
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="website"
                            type="text"
                            placeholder="Enter website"
                            value={companyData.website}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="cultures">
                          <Form.Label>Cultures</Form.Label>
                          <Select
                            disabled={isDisabledForm}
                            required
                            value={companyData.cultures}
                            options={culturesList}
                            isMulti
                            name="cultures"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(v, "cultures");
                            }}
                            onInputChange={handleCulturesList}
                          ></Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="industries">
                          <Form.Label>Industries</Form.Label>
                          <Select
                            disabled={isDisabledForm}
                            required
                            value={companyData.industries}
                            options={industriesList}
                            isMulti
                            name="industries"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v) => {
                              handleMultiSelectChange(v, "industries");
                            }}
                            onInputChange={handleIndustriesList}
                          ></Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="linkedin">
                          <Form.Label>Linkedin</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            name="linkedin"
                            type="text"
                            placeholder="https://linkedin.com/"
                            value={companyData.contact.linkedin}
                            onChange={handleSocialInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="twitter">
                          <Form.Label>Twitter</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            name="twitter"
                            type="text"
                            placeholder="https://twitter.com/"
                            value={companyData.contact.twitter}
                            onChange={handleSocialInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Label>Locations</Form.Label>
                    {companyData.locations.map((location, index) => (
                      <React.Fragment key={location.id}>
                        <Locations
                          location={location}
                          continents={continents}
                          setState={setCompanyData}
                          generateCountryList={generateCountryList}
                          City={City}
                          handleDeleteLocation={handleDeleteLocation}
                          data={companyData}
                          index={index}
                        />
                      </React.Fragment>
                    ))}
                    <div className="mt-3">
                      <Button variant="primary" onClick={handleAddLocation}>
                        Add Location
                      </Button>
                    </div>
                    <Form.Label>Socials</Form.Label>
                    {companyData.contact.socials.map((social, index) => (
                      <React.Fragment key={index}>
                        <Row>
                          <Col md={4} className="mb-3">
                            <Form.Group id="name">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                disabled={isDisabledForm}
                                required
                                name="name"
                                type="text"
                                placeholder="Enter name"
                                value={social.name}
                                onChange={(v) => handleSocialsChange(v, index)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <Form.Group id="link">
                              <Form.Label>Link</Form.Label>
                              <Form.Control
                                disabled={isDisabledForm}
                                required
                                name="link"
                                type="text"
                                placeholder="Enter link"
                                value={social.link}
                                onChange={(v) => handleSocialsChange(v, index)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            {companyData.contact.socials.length && (
                              <button onClick={() => handleDeleteSocial(index)}>
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                    <div className="mb-3">
                      <Button variant="primary" onClick={handleAddSocial}>
                        Add Social
                      </Button>
                    </div>
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group id="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            rows="3"
                            maxLength="500"
                            name="description"
                            as="textarea"
                            type="text"
                            placeholder="Enter description"
                            value={companyData.description}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                    <div className="mt-3">
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </div>
                  </Form>
                  <Form onSubmit={handleSaveOwner}>
                    <h5 className="mb-4">Owner info</h5>
                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="position">
                          <Form.Label>Position</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="position"
                            type="text"
                            placeholder="Enter Position"
                            value={ownerData.position}
                            onChange={handleOwnerInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="first_name"
                            type="text"
                            placeholder="Enter first name"
                            value={ownerData.first_name}
                            onChange={handleOwnerInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="last_name"
                            type="text"
                            placeholder="Also last name"
                            value={ownerData.last_name}
                            onChange={handleOwnerInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            required
                            name="email"
                            type="email"
                            placeholder="name@mail.com"
                            value={ownerData.email}
                            onChange={handleOwnerInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="phone">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            disabled={isDisabledForm}
                            // required
                            name="phone_number"
                            type="phone"
                            placeholder="+12-345 678 910"
                            value={ownerData.phone_number}
                            onChange={handleOwnerInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <ChoosePhotoWidget
                          title="Select profile photo"
                          photo={ownerData?.profile_picture?.url}
                        />
                      </Col>
                    </Row>
                    {errorMsgOwner && (
                      <Alert variant="danger">{errorMsgOwner}</Alert>
                    )}
                    <div className="mt-3">
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="subscription" title="Subscription">
                  <h5 className="my-4">Subscription</h5>
                  {subscriptionData?.stripe_id && companyData.size && (
                    <Form onSubmit={handleSaveSubscription}>
                      <Row>
                        <Col md={6} className="mb-3">
                          <Form.Label>Plan</Form.Label>
                          <Form.Select
                            disabled={isDisabledForm}
                            onChange={handleSubscriptionInputChange}
                            required
                            name="subscription_id"
                            defaultValue={subscriptionData.subscription_id}
                            value={subscriptionData.subscription_id}
                          >
                            <option>Select</option>
                            {plansList.map((i, index) => (
                              <option value={i.id} key={index}>
                                {i.title}({i.price}
                                {i.currency})
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        {subscriptionData.total_price && (
                          <Col md={6} className="mb-3">
                            <Form.Group id="total_price">
                              <Form.Label>Price per month</Form.Label>
                              <Form.Control
                                disabled
                                name="total_price"
                                type="phone"
                                value={subscriptionData.total_price}
                              />
                            </Form.Group>
                          </Col>
                        )}
                        {errorMsgSubscription && (
                          <Alert variant="danger">{errorMsgSubscription}</Alert>
                        )}
                        <div className="mt-3">
                          {subscriptionData.stripe_subscription_id ? (
                            <Button
                              className="me-2"
                              variant="primary"
                              type="submit"
                            >
                              Update Subscription Plan
                            </Button>
                          ) : (
                            <Button
                              className="me-2"
                              variant="primary"
                              type="submit"
                            >
                              Create Subscription Plan
                            </Button>
                          )}
                          {subscriptionData.payment_plan !==
                          BillingPaymentPlans.HIRE ? (
                            <Button
                              variant="secondary"
                              onClick={handleSaveSubscriptionPerHire}
                            >
                              Activate pay per hire plan
                            </Button>
                          ) : (
                            <Button variant="secondary" disabled="true">
                              Pay per hire plan is activated
                            </Button>
                          )}
                        </div>
                      </Row>
                    </Form>
                  )}

                  <Invoices />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <div>
                <p>
                  Creation Date:{" "}
                  {new Date(companyData.created_at).toLocaleDateString()}
                </p>
                <p>Owner Status: {ownerData.status}</p>
                {isLever && (
                  <p>
                    <Form.Check
                      disabled={isDisabledForm}
                      type="switch"
                      id="is_send_to_lever-switch"
                      label="Send jobs automatically lever"
                      name="is_send_to_lever"
                      checked={companyData.is_send_to_lever}
                      onChange={handleSendToLever}
                    />
                  </p>
                )}
                {forgotPasswordLimit && (
                  <p>
                    <Form.Check
                      disabled={isDisabledForm}
                      type="switch"
                      id="forgot_password_limit-switch"
                      name="forgot_password_limit"
                      label="Forgot password owner limit"
                      checked={forgotPasswordLimit}
                      onChange={handleForgotPasswordLimit}
                    />
                  </p>
                )}
              </div>
            </Col>
            {!isDisabledForm && (
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <Button
                    variant="danger"
                    onClick={() => handleDelete()}
                    className="me-2"
                  >
                    Delete Company
                  </Button>
                  {ownerStatus.blocked && (
                    <Button
                      variant="secondary"
                      onClick={() =>
                        handleStatus(USER_STATUSES.VERIFIED, ownerData.id)
                      }
                    >
                      Unblock Owner
                    </Button>
                  )}
                  {ownerStatus.verified && (
                    <Button
                      variant="secondary"
                      onClick={() =>
                        handleStatus(USER_STATUSES.BLOCKED, ownerData.id)
                      }
                    >
                      Block Owner
                    </Button>
                  )}
                </div>
              </Col>
            )}
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select company photo"
                photo={companyData?.profile_picture?.url}
              />
            </Col>
          </Row>
          {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
        </Col>
      </Row>
    </>
  );
};

export default CompanyDetails;
