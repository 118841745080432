import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import { JobStatuses, USER_ROLES } from "../../utils/constants";
import { getJobs } from "../../api/jobs/jobsAPI";
import { formatDate, getStatusClass, shortId } from "../../utils/helpers";
import Actions from "./JobDetails/Actions";
import InputSearch from "../components/InputSearch";
import Select from "react-select";
import { getCompaniesList } from "../../api/users/companies/CompaniesApi";

const JobsTable = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [query, setQuery] = useState({
    page: 1,
    per_page: 20,
    search: "",
    status: "",
    company_id: "",
  });

  const userRole = localStorage.getItem("role");

  const [jobs, setJobs] = useState({ count: 0, data: [] });
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState("");

  const [companiesList, setCompaniesList] = useState([]);

  const handleCompaniesList = (search) => {
    try {
      if (search.length) {
        getCompaniesList({ search: search }).then((arr) =>
          setCompaniesList(arr)
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handlePaginationPage = useCallback(
    async (value, maxPage) => {
      if (is_paginate) {
        return false;
      }
      let new_page;
      if (value === "prev") {
        if (query.page === 1) {
          return false;
        }
        new_page = query.page - 1;
      } else if (value === "next") {
        if (query.page === maxPage) {
          return false;
        }
        new_page = query.page + 1;
      } else {
        new_page = Number(value);
      }
      query.page = new_page;
      is_paginate = true;
      const res = await getJobs(query);
      setJobs(res);
      setTableData(
        res.data.map((t) => <TableRow key={`job-${t.id}`} {...t} />)
      );
      setPaginationData(
        generatePagination(query.page, res.count, query.per_page)
      );
      is_paginate = false;
    },
    [query]
  );

  const generatePagination = useCallback(
    (page, count, per_page) => {
      const totalPages = Math.ceil(count / per_page);

      if (count > per_page) {
        const paginationItems = [];

        for (let i = 1; i <= totalPages; i++) {
          // Only show the first page, the last page, the current page, and two pages on either side of the current page
          if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
            paginationItems.push(
              <Pagination.Item
                key={i}
                value={i}
                active={i === page}
                onClick={() => handlePaginationPage(i, totalPages)}
              >
                {i}
              </Pagination.Item>
            );
          } else if (i === 2 || i === totalPages - 1) {
            // Add ellipsis for hidden pages
            paginationItems.push(<Pagination.Ellipsis key={i} />);
          }
        }

        return (
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev
                value="prev"
                onClick={() => handlePaginationPage("prev", totalPages)}
              >
                Previous
              </Pagination.Prev>
              {paginationItems}
              <Pagination.Next
                value="next"
                onClick={() => handlePaginationPage("next", totalPages)}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
        );
      }
    },
    [handlePaginationPage]
  );
  const fetchData = async () => {
    try {
      const res = await getJobs(query);
      setJobs(res);
      setTableData(
        res.data.map((t) => <TableRow key={`job-${t.id}`} {...t} />)
      );
      setPaginationData(
        generatePagination(query.page, res.count, query.per_page)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const TableRow = (props) => {
    const {
      id,
      title,
      company,
      currency,
      minimum_salary,
      maximum_salary,
      created_at,
      status,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={Routes.JobDetail.path.replace(":id", id)}
            className="fw-normal"
          >
            {shortId(id)}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{title}</span>
        </td>
        <td>
          <span className="fw-normal">{company.name}</span>
        </td>
        <td>
          <span className="fw-normal">
            {minimum_salary}-{maximum_salary} {currency}
          </span>
        </td>
        <td>
          <span className="fw-normal">{formatDate(created_at)}</span>
        </td>
        <td>
          <span
            className={`fw-normal text-${status}`}
            style={{
              backgroundColor: getStatusClass(status),
              padding: "2px 4px",
              borderRadius: "5px",
            }}
          >
            {status}
          </span>
        </td>
        <td>
          <Actions status={status} id={id} fetchData={fetchData} />
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
  }, [query]);

  const handleSelectChange = (value, name) => {
    setSelectedCompany(value);
    setQuery((prev) => ({
      ...prev,
      [name]: value.value,
    }));
  };

  const totalJobs = jobs.count;
  const currentJobs = jobs.data.length + (query.page - 1) * query.per_page;

  let is_paginate = false;

  const statusHandler = (status) => {
    setQuery((prevQuery) => {
      if (prevQuery.status === status) {
        return { ...prevQuery, status: "" };
      } else {
        return { ...prevQuery, status: status };
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calyptus</Breadcrumb.Item>
            <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Jobs</h4>
          <p className="mb-0">Jobs list.</p>

          {userRole !== USER_ROLES.SUPPORT && (
            <Link to={Routes.CreateJob.path}>
              <Dropdown.Toggle
                as={Button}
                variant="secondary"
                className="text-dark me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>Add New Job Position</span>
              </Dropdown.Toggle>
            </Link>
          )}
        </div>
      </div>

      <div className="mb-4">
        <Row className="d-flex justify-content-start align-items-center mb-4">
          <div
            style={{
              marginRight: "10px",
              width: "300px",
            }}
          >
            <InputSearch setQuery={setQuery} />
          </div>
          <Col md={4}>
            <Form.Group id="company_id" className="w-100">
              <Select
                placeholder="Start typing to search for a company..."
                required
                value={selectedCompany}
                options={companiesList.map((i) => ({
                  value: i.id,
                  label: i.name,
                }))}
                name="company_id"
                classNamePrefix="select"
                onChange={(v) => handleSelectChange(v, "company_id")}
                onInputChange={handleCompaniesList}
              />
            </Form.Group>
          </Col>
          <div
            className="d-flex"
            style={{
              width: "max-content",
            }}
          >
            {Object.values(JobStatuses).map((status, index) => {
              return (
                <span
                  className="badge rounded-pill  me-2 px-3 py-2"
                  key={index}
                  style={{
                    backgroundColor:
                      query.status === status ? "#61DAFB" : "#F2F2F3",
                    color: "black",

                    cursor: "pointer",
                  }}
                  onClick={() => statusHandler(status)}
                >
                  {status}
                </span>
              );
            })}
          </div>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">Title</th>
                <th className="border-bottom">Company</th>
                <th className="border-bottom">Salary</th>
                <th className="border-bottom">Created Date</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom"></th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {paginationData}
            <small className="fw-bold">
              Showing <b>{currentJobs}</b> out of <b>{totalJobs}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default JobsTable;
