import React, {useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import {useHistory} from 'react-router-dom';
import {createRecruiter, getCompaniesList} from '../../../api/users/companies/CompaniesApi';
import Select from "react-select";

export default () => {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [userData, setUserData] = useState({
        phone_number: '',
        first_name: '',
        company_id: '',
        last_name: '',
        email: '',
        position: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleSelectChange = (value, name) => {
        setUserData({
            ...userData,
            [name]: value,
        });
    }

    const handleSaveGeneral = async (event) => {
        event.preventDefault();
        try {
            await createRecruiter({ first_name: userData.first_name, last_name: userData.last_name, email: userData.email, phone_number: userData.phone_number, company_id: userData.company_id.value, position: userData.position});
            history.push('/users/recruiters');
        } catch (error) {
            setErrorMsg(error.response.data.message);
            console.error('Error:', error.message);
        }
    }

    const handleCompaniesList = (search) => {
        try {
            if (search.length) {
                getCompaniesList({search: search}).then(arr => setCompaniesList(arr));
            }
        } catch (error) {
            setErrorMsg(error.response.data.message);
            console.error('Error:', error.message);
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} xl={12}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <h5 className="mb-4">General information</h5>
                            <Form onSubmit={handleSaveGeneral}>
                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required name="first_name" type="text" placeholder="Enter first name" value={userData.first_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="lastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required name="last_name" type="text" placeholder="Enter last name" value={userData.last_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="position">
                                            <Form.Label>Position</Form.Label>
                                            <Form.Control required name="position" type="text" placeholder="Also position" value={userData.position} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required name="email" type="email" placeholder="name@mail.com" value={userData.email} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control required name="phone_number" type="phone" placeholder="+12-345 678 910" value={userData.phone_number} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="company_id">
                                            <Form.Label>Company</Form.Label>
                                            <Select required value={userData.company_id} options={companiesList.map(i => ({value: i.id, label: i.name}))} name="company_id" classNamePrefix="select" onChange={(v) => {handleSelectChange(v, 'company_id')}} onInputChange={handleCompaniesList}></Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                                <div className="mt-3">
                                    <Button variant="primary" type="submit">Save</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
