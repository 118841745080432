import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import JobForm from "./JobForm";
import { Button, Col, Row } from "@themesberg/react-bootstrap";
import EditDescription from "./EditComponents/EditDescription";
import SkillElements from "./SkillElements";
import { useHistory } from "react-router-dom";
import { createJob } from "../../../api/jobs/jobsAPI";
import { handleValidation } from "./validation";
import Spinner from "../../components/Spinner";

const initialState = {
  job: {
    seniorities: [],
    minimum_salary: 0,
    maximum_salary: 0,
    minimum_experience: 0,
    currency: "USD",
    core_skills_ids: [],
    secondary_skills_ids: [],
    industries: [],
    cultures: [],
    employment_types: [],
    work_preferences: [],
    professions: [],
    languages: ["English"],
    timezones: [],
    visa_sponsorship: false,
    description: "",
    title: "",
    company_id: "",
    company: [],
    locations: [
      {
        country: "",
        cities: [],
        isoCode: "",
        id: uuidv4(),
        continent: "",
      },
    ],
    members: [],
    selectedSkills: {
      core: [],
      secondary: [],
    },
  },
  members: [],
};

const CreateJob = () => {
  const history = useHistory();
  const [jobForm, setJobForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setJobForm(initialState);
    history.push("/jobs");
  };
  const onSave = async () => {
    try {
      const {
        cultures,
        created_at,
        industries,
        professions,
        referral_external_link,
        selectedSkills,
        locations,
        ...rest
      } = jobForm.job;
      const data = {
        ...rest,
        minimum_salary: parseInt(jobForm.job.minimum_salary),
        maximum_salary: parseInt(jobForm.job.maximum_salary),
        minimum_experience: parseInt(jobForm.job.minimum_experience),

        core_skills_ids: jobForm.job.selectedSkills.core.map(
          (skill) => skill.id
        ),
        secondary_skills_ids: jobForm.job.selectedSkills.secondary.map(
          (skill) => skill.id
        ),
        industries_ids: jobForm.job.industries.map((industry) => industry.id),
        cultures_ids: jobForm.job.cultures.map((culture) => culture.id),
        company_id: jobForm.job.company.value,
        professions_ids: jobForm.job.professions.map(
          (profession) => profession.id
        ),
        members: jobForm.members.map((member) => member.id),
      };
      if (locations.length > 0 && locations[0].cities.length > 0) {
        data.locations = locations.map((location) => ({
          country: location.country,
          cities: location.cities,
          continent: location.continent,
          isoCode: location.isoCode,
        }));
      }
      if (!handleValidation(data, setErrors, "requiements")) return;
      setLoading(true);
      await createJob(data);
      history.push("/jobs");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Spinner />}

      <Row className="border border-2 border-main p-2 m-1">
        <div
          className="d-grid align-items-start p-3 justify-content-between"
          style={{
            gridTemplateColumns: "2fr 1fr",
            gap: "1rem",
          }}
        >
          <Row>
            <JobForm
              setJobForm={setJobForm}
              title={jobForm.job.title}
              professions={jobForm.job.professions}
              seniorities={jobForm.job.seniorities}
              minimum_experience={jobForm.job.minimum_experience}
              work_preferences={jobForm.job.work_preferences}
              employment_types={jobForm.job.employment_types}
              minimum_salary={jobForm.job.minimum_salary}
              maximum_salary={jobForm.job.maximum_salary}
              locations={jobForm.job.locations}
              visa_sponsorship={jobForm.job.visa_sponsorship}
              timezones={jobForm.job.timezones}
              languages={jobForm.job.languages}
              currency={jobForm.job.currency}
              errors={errors}
            />
            <EditDescription
              setData={setJobForm}
              description={jobForm.job.description}
            />
          </Row>
          <div className="d-flex flex-column">
            <SkillElements
              industries={jobForm.job.industries}
              selectedSkills={jobForm.job.selectedSkills}
              cultures={jobForm.job.cultures}
              company={jobForm.job.company}
              isEdit={true}
              isCreate={true}
              setJobForm={setJobForm}
              companyId={jobForm.job.company_id}
              members={jobForm.members}
              errors={errors}
            />
          </div>
        </div>
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="secondary" className="ms-2" onClick={onSave}>
              Save
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default CreateJob;
