import React from "react";

const Chip = ({ name }) => {
  return (
    <p className="m-0 mb-2 me-2 d-flex align-items-center rounded bg-light px-2 py-1 text-main fw-normal fs-8">
      {name}
    </p>
  );
};

export default Chip;
