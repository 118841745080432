import {getAxiosInstance} from '../../axios';
import {API_ROOT_URL} from '../../../utils/constants';
import qs from 'qs';

export const getSubscriptionPlans = async () => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/payments/plan`);

    return data;
};

export const getInvoices = async (filter) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/payments/invoice?${qs.stringify(filter)}`);

    return data;
}

export const payInvoice = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/payments/paid-invoice/${id}`);

    return data;
}

export const updateSubscription = async (subscription) => {
    const axios = getAxiosInstance();
    const { data } = await axios.put(`${API_ROOT_URL}/porrige/payments/subscription`, subscription);

    return data;
}

export const createSubscription = async (subscription) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/payments/subscription`, subscription);

    return data;
}

export const activatePayPerHire = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/payments/activate-pay-per-hire/${id}`);

    return data;
}
