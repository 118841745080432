import axios from "axios";

export const getAxiosInstance = () => {
  const instance = axios.create();
  let token = localStorage.getItem("token");

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      console.log(response.headers);
      if (typeof response.headers["authorization"] === "string") {
        token = response.headers["authorization"];
        localStorage.setItem("token", token);
        instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
      }
      return response;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        // If the response status is 401 (Unauthorized), remove the tokens and redirect to the sign-in page
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("data");
        localStorage.removeItem("role");
        window.location.href = "/index.html#/auth/sign-in";
      }
      return Promise.reject(error);
    }
  );

  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return instance;
};
