
export const Routes = {
    // pages
    Presentation: { path: '/' },
    DashboardOverview: { path: '/dashboard/overview' },
    Transactions: { path: '/transactions' },
    Settings: { path: '/settings' },
    Upgrade: { path: '/upgrade' },
    BootstrapTables: { path: '/tables/bootstrap-tables' },
    OatmealTable: { path: '/users/oatmeal' },
    OatmealDetail: { path: '/users/oatmeal/detail/:id' },
    CreateOatmeal: { path: '/users/oatmeal/create' },

    CandidatesTable: { path: '/users/candidates' },
    CandidateDetail: { path: '/users/candidates/detail/:id' },
    CreateCandidate: { path: '/users/candidates/create' },

    CompaniesTable: { path: '/users/companies' },
    CompanyDetail: { path: '/users/companies/detail/:id' },
    CreateCompany: { path: '/users/companies/create' },

    RecruitersTable: { path: '/users/recruiters' },
    RecruiterDetail: { path: '/users/recruiters/detail/:id' },
    CreateRecruiter: { path: '/users/recruiters/create' },

    JobsTable: { path: '/jobs' },
    JobDetail: { path: '/jobs/detail/:id' },
    CreateJob: { path: '/jobs/create' },

    ApplicationsTable: { path: '/applications' },
    ApplicationDetail: { path: '/applications/detail/:id' },

    Billing: { path: '/examples/billing' },
    Invoice: { path: '/examples/invoice' },
    Signin: { path: '/auth/sign-in' },
    MFA: { path: '/auth/mfa' },
    SetPassword: { path: '/auth/set-password' },
    Signup: { path: '/examples/sign-up' },
    ForgotPassword: { path: '/examples/forgot-password' },
    ResetPassword: { path: '/examples/reset-password' },
    Lock: { path: '/examples/lock' },
    NotFound: { path: '/examples/404' },
    ServerError: { path: '/examples/500' },

    // docs
    DocsOverview: { path: '/documentation/overview' },
    DocsDownload: { path: '/documentation/download' },
    DocsQuickStart: { path: '/documentation/quick-start' },
    DocsLicense: { path: '/documentation/license' },
    DocsFolderStructure: { path: '/documentation/folder-structure' },
    DocsBuild: { path: '/documentation/build-tools' },
    DocsChangelog: { path: '/documentation/changelog' },

    // components
    Accordions: { path: '/components/accordions' },
    Alerts: { path: '/components/alerts' },
    Badges: { path: '/components/badges' },
    Widgets: { path: '/widgets' },
    Breadcrumbs: { path: '/components/breadcrumbs' },
    Buttons: { path: '/components/buttons' },
    Forms: { path: '/components/forms' },
    Modals: { path: '/components/modals' },
    Navs: { path: '/components/navs' },
    Navbars: { path: '/components/navbars' },
    Pagination: { path: '/components/pagination' },
    Popovers: { path: '/components/popovers' },
    Progress: { path: '/components/progress' },
    Tables: { path: '/components/tables' },
    Tabs: { path: '/components/tabs' },
    Tooltips: { path: '/components/tooltips' },
    Toasts: { path: '/components/toasts' },
    WidgetsComponent: { path: '/components/widgets' }
};
