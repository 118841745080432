import React from "react";

import MainInformationEdit from "./EditComponents/MainInformationEdit";

const JobForm = ({
  professions,
  seniorities,
  minimum_experience,
  work_preferences,
  employment_types,
  minimum_salary,
  maximum_salary,
  locations,
  visa_sponsorship,
  timezones,
  languages,
  currency,
  setJobForm,
  title,
  errors,
}) => {
  return (
    <MainInformationEdit
      selectedProfessions={professions}
      seniorities={seniorities}
      minimum_experience={minimum_experience}
      work_preferences={work_preferences}
      employment_types={employment_types}
      minimum_salary={minimum_salary}
      maximum_salary={maximum_salary}
      locations={locations}
      visa_sponsorship={visa_sponsorship}
      timezones={timezones}
      selectedLanguages={languages}
      currency={currency}
      setJobForm={setJobForm}
      title={title}
      errors={errors}
    />
  );
};

export default JobForm;
