import React, { memo, useCallback, useState } from "react";
import ReactQuill from "react-quill";

const EditDescription = memo(({ description, setData }) => {
  const handleTextAreaChange = useCallback(
    (v) => {
      setData((prev) => ({
        ...prev,
        job: {
          ...prev.job,
          description: v,
        },
      }));
    },
    [setData]
  );
  const editDescriptionModules = {
    toolbar: [
      ["underline", "bold", "italic"],
      [{ list: "bullet" }],
      [{ align: [] }],
    ],
  };
  const editDescriptionFormats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
  ];

  return (
    <div>
      <p className="m-0 mb-[2px] text-[14px] font-[400] text-main-neutral-600 ">
        Description
      </p>
      <ReactQuill
        className="bg-white "
        placeholder="Add key words about the job and generate the rest with Calyptus AI or write full thing manually"
        theme="snow"
        value={description}
        onChange={handleTextAreaChange}
        modules={editDescriptionModules}
        formats={editDescriptionFormats}
      />
    </div>
  );
});

export default EditDescription;
