const requiements = [
  "title",

  "professions_ids",
  "seniorities",
  "minimum_experience",
  "minimum_salary",
  "maximum_salary",
  "work_preferences",
  "employment_types",
  "languages",

  "core_skills_ids",
  "secondary_skills_ids",
  "industries_ids",
  "cultures_ids",
];

const REQUIRED_FIELD_ERROR = "This field is required";
const SALARY_VALIDATION_ERROR =
  "Minimum salary should be less than maximum salary";
const ERROR_REQUIRED = "This field is required";

const values = {
  requiements: requiements,
};
const findEmptyProperty = (items, property) =>
  items.some((item) => item[property]?.length === 0);

export const validateCountries = (locations, formIsValid, errors) => {
  if (findEmptyProperty(locations, "country")) {
    formIsValid = false;
    errors.country = {
      [String(locations.find((item) => item.country === "")?.id)]:
        ERROR_REQUIRED,
    };
  } else {
    errors.country = {};
  }
  return formIsValid;
};

export const validateCountriesAndCities = ({
  errors,
  locations,
  formIsValid,
}) => {
  validateCountries(locations, formIsValid, errors);
  if (findEmptyProperty(locations, "cities")) {
    formIsValid = false;
    errors.cities = {
      [String(locations.find((item) => item.cities.length === 0)?.id)]:
        ERROR_REQUIRED,
    };
  }

  return { errors, formIsValid };
};

const validateSalary = (data, errors) => {
  if (+data.minimum_salary > +data.maximum_salary) {
    errors["minimum_salary"] = SALARY_VALIDATION_ERROR;
    errors["maximum_salary"] = SALARY_VALIDATION_ERROR;
    return false;
  }
  return true;
};
const validateField = (field, data, errors) => {
  if (
    !data[field] ||
    (typeof data[field] === "string" &&
      data[field].replace(/(<([^>]+)>)/gi, "").length === 0)
  ) {
    errors[field] = REQUIRED_FIELD_ERROR;
    return false;
  }
  return true;
};

export const handleValidation = (data, callback, type) => {
  let errors = {};
  let formIsValid = true;
  if (typeof data !== "object" || !Array.isArray(values[type])) {
    return false;
  }
  values[type].forEach((field) => {
    if (
      [
        "professions_ids",
        "seniorities",
        "employment_types",
        "work_preferences",
        "core_skills_ids",
        "secondary_skills_ids",
        "industries_ids",
        "languages",
        "cultures_ids",
      ].includes(field) &&
      !data[field].length
    ) {
      errors[field] = REQUIRED_FIELD_ERROR;
      formIsValid = false;
      return;
    }
    if (type === "requiements" && data.work_preferences) {
      if (
        data.work_preferences.includes("Hybrid") ||
        data.work_preferences.includes("On-site")
      ) {
        const result = validateCountriesAndCities({
          errors,
          locations: data.locations,
          formIsValid,
        });
        errors = result.errors;
        formIsValid = result.formIsValid;
      }

      if (data.work_preferences.includes("Remote")) {
        if (!data.timezones[0] || !data.timezones[1]) {
          formIsValid = false;
          errors["timezones[0]"] = REQUIRED_FIELD_ERROR;
          errors["timezones[1]"] = REQUIRED_FIELD_ERROR;
        }
      }
    }
    formIsValid = validateSalary(data, errors) && formIsValid;
    formIsValid = validateField(field, data, errors) && formIsValid;
  });

  callback(errors);
  return formIsValid;
};
