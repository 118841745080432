import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useHistory } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { signInMFA } from "../../api/auth/authAPI";

const MFA = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [userData, setUserData] = useState({
    code: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const history = useHistory();
  const mfa_url = localStorage.getItem("mfa_url");

  const handleMFA = async (event) => {
    event.preventDefault();

    try {
      const response = await signInMFA(
        userData.code,
        localStorage.getItem("user_id")
      );

      if (response) {
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("role", response.role);
        localStorage.setItem("data", JSON.stringify(response));
        localStorage.removeItem("mfa_url");
        // history.push('/dashboard/overview');
        history.push("/users/candidates");
        window.location.reload();
      }

      // You might want to redirect the user or update the UI based on the response.
    } catch (error) {
      // Handle errors
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleMFA}>
                  <Form.Group id="code" className="mb-4">
                    <Form.Label>Your Verification Code</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        name="code"
                        autoFocus
                        required
                        type="number"
                        value={userData.code}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
                {mfa_url && (
                  <div className="mt-4 text-center text-md-center">
                    {/* Generate QR code for url */}
                    <QRCodeSVG value={mfa_url} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default MFA;
