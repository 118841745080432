import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";

export const signIn = async (email, password) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/auth/sign-in`, {
        email,
        password,
    });

    return data;
};

export const signInMFA = async (code, user_id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/auth/sign-in-mfa`, {
        code,
        user_id,
    });

    return data;
};

export const signUpPassword = async ({password, token}) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(
        `${API_ROOT_URL}/porrige/auth/set-password`,
        {
            password,
            token: token
        }
    );
    return data;
};

export const checkToken = async (token) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/auth/check-email/${token}`);
    return data;
};

export const signUpApplicant = async () => {
    const axios = getAxiosInstance();
    const { data: response } = await axios.post(
        `${API_ROOT_URL}/porrige/auth/sign-up-applicant`
    );
    return response;
};

export const userLogOut = async () => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/auth/logout`);
    return data;
};
