import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Button,
  Breadcrumb,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
  Col,
  Form,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes";
import { USER_ROLES, USER_STATUSES } from "../../../utils/constants";
import { getCandidates } from "../../../api/users/candidatesApi";
import { formatDate, shortId } from "../../../utils/helpers";
import InputSearch from "../../components/InputSearch";
import moment from "moment-timezone";

const TableRow = (props) => {
  const { id, first_name, last_name, email, created_at, status } = props;

  return (
    <tr>
      <td>
        <Card.Link
          as={Link}
          to={Routes.CandidateDetail.path.replace(":id", id)}
          className="fw-normal"
        >
          {shortId(id)}
        </Card.Link>
      </td>
      <td>
        <span className="fw-normal">{first_name}</span>
      </td>
      <td>
        <span className="fw-normal">{last_name}</span>
      </td>
      <td>
        <span className="fw-normal">{email}</span>
      </td>
      <td>
        <span className="fw-normal">{formatDate(created_at)}</span>
      </td>
      <td>
        <span className={`fw-normal text-${status}`}>{status}</span>
      </td>
    </tr>
  );
};

export const CandidatesTable = () => {
  const [query, setQuery] = useState({
    page: 1,
    per_page: 20,
    search: "",
    status: "",
    is_profile_checked: "",
    date: {
      from: "",
      to: "",
    },
  });
  const userRole = localStorage.getItem("role");

  const [users, setUsers] = useState({ count: 0, data: [] });
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState("");

  const PROFILE_STATUS = {
    all: "",
    checked: true,
    not_checked: false,
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCandidates(query);
        setUsers(res);
        setTableData(
          res.data.map((t) => <TableRow key={`candidate-${t.id}`} {...t} />)
        );
        setPaginationData(
          generatePagination(query.page, res.count, query.per_page)
        );
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [query]);

  const totalUsers = users.count;
  const currentUsers = users.data.length + (query.page - 1) * query.per_page;

  let is_paginate = false;

  const handlePaginationPage = async (value, maxPage) => {
    if (is_paginate) {
      return false;
    }
    let new_page;
    if (value === "prev") {
      if (query.page === 1) {
        return false;
      }
      new_page = query.page - 1;
    } else if (value === "next") {
      if (query.page === maxPage) {
        return false;
      }
      new_page = query.page + 1;
    } else {
      new_page = Number(value);
    }
    query.page = new_page;
    is_paginate = true;
    const res = await getCandidates(query);
    setUsers(res);
    setTableData(
      res.data.map((t) => <TableRow key={`candidate-${t.id}`} {...t} />)
    );
    setPaginationData(
      generatePagination(query.page, res.count, query.per_page)
    );
    is_paginate = false;
  };
  const generatePagination = (page, count, per_page) => {
    const totalPages = Math.ceil(count / per_page);

    if (count > per_page) {
      const paginationItems = [];

      for (let i = 1; i <= totalPages; i++) {
        // Only show the first page, the last page, the current page, and two pages on either side of the current page
        if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
          paginationItems.push(
            <Pagination.Item
              key={i}
              value={i}
              active={i === page}
              onClick={() => handlePaginationPage(i, totalPages)}
            >
              {i}
            </Pagination.Item>
          );
        } else if (i === 2 || i === totalPages - 1) {
          paginationItems.push(<Pagination.Ellipsis key={i} disabled />);
        }
      }

      return (
        <Nav>
          <Pagination className="mb-2 mb-lg-0">
            <Pagination.Prev
              value="prev"
              onClick={() => handlePaginationPage("prev", totalPages)}
            >
              Previous
            </Pagination.Prev>
            {paginationItems}
            <Pagination.Next
              value="next"
              onClick={() => handlePaginationPage("next", totalPages)}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Nav>
      );
    }
  };

  const statusHandler = (status) => {
    setQuery((prevQuery) => {
      if (prevQuery.status === status) {
        return { ...prevQuery, status: "" };
      } else {
        return { ...prevQuery, status: status };
      }
    });
  };

  const setQueryDateFrom = (date) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      date: {
        ...prevQuery.date,
        from: date,
      },
    }));
  };

  const setQueryDateTo = (date) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      date: {
        ...prevQuery.date,
        to: date,
      },
    }));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calyptus</Breadcrumb.Item>
            <Breadcrumb.Item active>Candidates</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Candidates</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
          {userRole !== USER_ROLES.SUPPORT && (
            <Link to={Routes.CreateCandidate.path}>
              <Dropdown.Toggle
                as={Button}
                variant="secondary"
                className="text-dark me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>Create New</span>
              </Dropdown.Toggle>
            </Link>
          )}
        </div>
      </div>

      <div className="mb-4">
        <Row className="d-flex justify-content-start align-items-center">
          <div
            style={{
              marginRight: "10px",
              width: "200px",
            }}
          >
            <InputSearch setQuery={setQuery} />
          </div>
          <div
            className="d-flex"
            style={{
              width: "max-content",
              alignItems: "center",
            }}
          >
            {Object.values(USER_STATUSES).map((status, index) => {
              return (
                <span
                  className="badge rounded-pill  me-2 px-3 py-2"
                  key={index}
                  style={{
                    backgroundColor:
                      query.status === status ? "#61DAFB" : "#F2F2F3",
                    color: "black",

                    cursor: "pointer",
                  }}
                  onClick={() => statusHandler(status)}
                >
                  {status}
                </span>
              );
            })}
            {Object.entries(PROFILE_STATUS).map((status, index) => {
              return (
                <span
                  className="badge rounded-pill  me-2 px-3 py-2"
                  key={index}
                  style={{
                    backgroundColor:
                      query.is_profile_checked === status[1]
                        ? "#61DAFB"
                        : "#F2F2F3",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setQuery((prevQuery) => ({
                      ...prevQuery,
                      is_profile_checked: status[1],
                    }))
                  }
                >
                  {status[0].replace("_", " ")}
                </span>
              );
            })}
          </div>
          <Col md={4} className="mb-3">
            <Form.Group id="from">
              <Form.Label>Start date</Form.Label>
              <Form.Control
                required
                name="from"
                type="date"
                value={moment(query.date.from).format("YYYY-MM-DD")}
                onChange={(v) => {
                  setQueryDateFrom(v.target?.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="to">
              <Form.Label>End date</Form.Label>
              <Form.Control
                name="to"
                type="date"
                value={moment(query.date.to).format("YYYY-MM-DD")}
                onChange={(v) => {
                  setQueryDateTo(v.target?.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>

      {/* <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
        </Row>
      </div> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">First Name</th>
                <th className="border-bottom">Last Name</th>
                <th className="border-bottom">Email</th>
                <th className="border-bottom">Created Date</th>
                <th className="border-bottom">Status</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {paginationData}
            <small className="fw-bold">
              Showing <b>{currentUsers}</b> out of <b>{totalUsers}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default CandidatesTable;
