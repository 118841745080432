import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import {USER_ROLES, USER_STATUSES} from '../../../utils/constants';
import {useHistory} from 'react-router-dom';
import {deleteRecruiter, updateCompanyUser} from '../../../api/users/companies/CompaniesApi';
import {getUser, resetForgotPasswordLimit, saveUserStatus} from '../../../api/users/usersApi';
import {ChoosePhotoWidget} from "../../../components/Widgets";

export default () => {
    const history = useHistory();
    const userRole = localStorage.getItem('role');
    const pathname = window.location.href.split('/');
    const [errorMsg, setErrorMsg] = useState('');
    const [errorMsgBtn, setErrorMsgBtn] = useState('');
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [forgotPasswordLimit, setForgotPasswordLimit] = useState(false);
    const [userStatus, setUserStatus] = useState({verified: false, blocked: false});

    const [userData, setUserData] = useState({
        id: '',
        phone_number: '',
        first_name: '',
        last_name: '',
        created_at: '',
        email: '',
        status: '',
        profile_picture_id: '',
        position: '',
        profile_picture: {url: ''}
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    useEffect( () => {
        async function fetchData() {
            try {
                const res = await getUser(pathname[pathname.length-1]);
                setUserData(res);

                if (userRole === USER_ROLES.SUPPORT) {
                    setIsDisabledForm(true);
                }

                if (res.forgot_password_count > 4) {
                    setForgotPasswordLimit(true);
                } else {
                    setForgotPasswordLimit(false);
                }
                if (res.status === USER_STATUSES.VERIFIED) {
                    setUserStatus({verified: true});
                } else if (res.status === USER_STATUSES.BLOCKED) {
                    setUserStatus({blocked: true});
                }

            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, []);

    const handleSaveGeneral = async (event) => {
        event.preventDefault();
        try {
            await updateCompanyUser(userData.id, { position: userData.position, first_name: userData.first_name, last_name: userData.last_name, email: userData.email, phone_number: userData.phone_number });
            history.push('/users/recruiters');
        } catch (error) {
            setErrorMsg(error.response.data.message);
            console.error('Error:', error.message);
        }
    }

    const handleDelete = async () => {
        try {
            await deleteRecruiter(pathname[pathname.length-1]);
            history.push('/users/recruiters');
        } catch (error) {
            setErrorMsgBtn(error.response.data.message);
        }
    }

    const handleStatus = async (status) => {
        try {
            await saveUserStatus(pathname[pathname.length-1], status);
            history.push('/users/recruiters');
        } catch (error) {
            setErrorMsgBtn(error.response.data.message);
        }
    };

    const handleForgotPasswordLimit = async () => {
        setForgotPasswordLimit(false);
        await resetForgotPasswordLimit(pathname[pathname.length-1]);
    };

    return (
        <>
            <Row>
                <Col xs={12} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <h5 className="mb-4">General information</h5>
                            <Form onSubmit={handleSaveGeneral}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control disabled={isDisabledForm} required name="first_name" type="text" placeholder="Enter first name" value={userData.first_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="lastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control disabled={isDisabledForm} required name="last_name" type="text" placeholder="Also last name" value={userData.last_name} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control disabled={isDisabledForm} required name="email" type="email" placeholder="name@mail.com" value={userData.email} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control disabled={isDisabledForm} required name="phone_number" type="phone" placeholder="+12-345 678 910" value={userData.phone_number} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="position">
                                            <Form.Label>Position</Form.Label>
                                            <Form.Control disabled={isDisabledForm} required name="position" type="text" placeholder="Enter position" value={userData.position} onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                                <div className="mt-3">
                                    <Button variant="primary" type="submit">Save</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <div>
                                <p>Creation Date: {new Date(userData.created_at).toLocaleDateString()}</p>
                                <p>Status: {userData.status}</p>
                                {forgotPasswordLimit &&
                                    <p>
                                        <Form.Check disabled={isDisabledForm} type="switch" id="forgot_password_limit-switch" name="forgot_password_limit" label="Forgot password limit" checked={forgotPasswordLimit} onChange={handleForgotPasswordLimit}/>
                                    </p>
                                }
                            </div>
                        </Col>
                        {!isDisabledForm &&
                            <Col xs={12}>
                                <div className="d-flex align-items-center">
                                    <Button variant="danger" onClick={() => handleDelete()} className="me-2">
                                        Delete
                                    </Button>
                                    {userStatus.blocked &&
                                        <Button variant="secondary" onClick={() => handleStatus(USER_STATUSES.VERIFIED)}>
                                            Unblock
                                        </Button>
                                    }
                                    {userStatus.verified &&
                                        <Button variant="secondary" onClick={() => handleStatus(USER_STATUSES.BLOCKED)}>
                                            Block
                                        </Button>
                                    }
                                </div>
                            </Col>
                        }
                        <Col xs={12}>
                            <ChoosePhotoWidget title="Select profile photo" photo={userData?.profile_picture?.url}/>
                        </Col>
                    </Row>
                    {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
                </Col>
            </Row>
        </>
    );
};
