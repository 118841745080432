import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, InputGroup } from "@themesberg/react-bootstrap";

const InputSearch = ({ setQuery }) => {
  const searchTimeoutRef = useRef();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchValue(value);
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = setTimeout(() => {
        setQuery((prev) => ({ ...prev, search: value }));
      }, 500);
    },
    [setQuery]
  );

  useEffect(() => {
    return () => {
      clearTimeout(searchTimeoutRef.current);
    };
  }, []);

  return (
    <div
      style={{
        width: "300px",
      }}
    >
      <Col>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search"
            onChange={handleSearch}
            value={searchValue}
          />
        </InputGroup>
      </Col>
    </div>
  );
};

InputSearch.propTypes = {
  setQuery: PropTypes.func.isRequired,
};

export default InputSearch;
