import moment from "moment-timezone";
import React from "react";
import { Card, Table } from "@themesberg/react-bootstrap";

const Assessments = ({ assessments }) => {
  const AssessmentsRow = (props) => {
    const { status, score, created_at, assessment } = props;

    return (
      <tr>
        <td className="border-0">{assessment.title}</td>
        <td className="border-0">{assessment.type}</td>
        <td className="border-0">{status}</td>
        <td className="border-0">{score}</td>
        <td className="border-0">{moment(created_at).format("DD MMM YYYY")}</td>
      </tr>
    );
  };

  return (
    <>
      {assessments?.length > 0 && (
        <Card border="light" className="shadow-sm">
          <Card.Body className="pb-0">
            <h5 className="mb-4">Assessments</h5>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Title</th>
                  <th className="border-0">Type</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Score</th>
                  <th className="border-0">Created At</th>
                </tr>
              </thead>
              <tbody>
                {assessments.map((r) => (
                  <AssessmentsRow key={`assessment-${r.id}`} {...r} />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Assessments;
