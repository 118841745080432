import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useHistory } from "react-router-dom";
import { signUpPassword } from "../../api/auth/authAPI";
import { checkToken } from "../../api/auth/authAPI";

const SetPassword = () => {
  const pathname = window.location.href.split("/");
  const [errorMsg, setErrorMsg] = useState("");
  const [userData, setUserData] = useState({
    password: "",
    token: pathname[pathname.length - 1],
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await checkToken(pathname[pathname.length - 1]);
        if (!res?.success) {
          history.push("/404");
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const handlePass = async (event) => {
    event.preventDefault();

    try {
      const response = await signUpPassword(userData);

      if (response) {
        window.location.href = "/index.html#/auth/sign-in";
      }

      // You might want to redirect the user or update the UI based on the response.
    } catch (error) {
      // Handle errors
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign up on our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handlePass}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Set Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        name="password"
                        autoFocus
                        required
                        type="password"
                        value={userData.password}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                  <Button variant="primary" type="submit" className="w-100">
                    Set password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default SetPassword;
