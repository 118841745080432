import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import { Currencies, USER_ROLES } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  editJob,
  getJob,
  saveReferralExternalLink,
} from "../../../api/jobs/jobsAPI";
import MainInformation from "./MainInformation";
import { v4 as uuidv4 } from "uuid";
import "../../../scss/main/jobs.scss";
import Description from "./Description";
import SkillsAndIndustry from "./SkillsAndIndustry";
import MainInformationEdit from "./EditComponents/MainInformationEdit";
import { handleValidation } from "./validation";
import Spinner from "../../components/Spinner";
import Actions from "./Actions";
import { getStatusClass } from "../../../utils/helpers";

const initialState = {
  job: {
    seniorities: [],
    minimum_salary: "",
    maximum_salary: "",
    minimum_experience: "",
    currency: Currencies.EUR,
    core_skills_ids: [],
    secondary_skills_ids: [],
    employment_types: [],
    work_preferences: [],
    languages: [],
    timezones: [],
    visa_sponsorship: false,
    members: [],
    description: "",
    title: "",
    company_id: "",
    selectedSkills: {
      core: [],
      secondary: [],
    },
    professions: [],
    locations: [
      {
        country: "",
        continent: "",
        cities: [],
        isoCode: "",
        id: uuidv4(),
      },
    ],
  },
  members: [],
};

const JobDetail = () => {
  const history = useHistory();
  const userRole = localStorage.getItem("role");
  const pathname = window.location.href.split("/");
  const [errorMsgBtn, setErrorMsgBtn] = useState("");
  const [isDisabledForm, setIsDisabledForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isEdit, setEdit] = useState(false);

  // needs to save prev state
  const [jobData, setJobData] = useState(initialState);
  const [jobForm, setJobForm] = useState(initialState);

  const [errors, setErrors] = useState({});

  const {
    title,
    professions,
    seniorities,
    maximum_experience,
    minimum_experience,
    work_preferences,
    employment_types,
    minimum_salary,
    maximum_salary,
    locations,
    visa_sponsorship,
    timezones,
    languages,
    currency,
    description,
    cultures,
    industries,
  } = jobForm.job;

  const handleJobInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setJobData((prevData) => ({
        ...prevData,
        job: {
          ...prevData.job,
          [name]: value,
        },
      }));
    },
    [setJobData]
  );
  const saveExternalLink = async () => {
    try {
      await saveReferralExternalLink(
        pathname[pathname.length - 1],
        jobData.job.referral_external_link || null
      );
      history.push("/jobs");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
    }
  };
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getJob(pathname[pathname.length - 1]);
      setJobData(res);
      setJobForm(() => ({
        ...res,
        job: {
          ...res.job,
          locations:
            res.job.locations.length > 0
              ? res.job.locations.map((item) => ({
                  id: item.id,
                  cities: [item.city],
                  country: item.country,
                  continent: item.continent || "",
                  isoCode: item.isoCode || "",
                }))
              : [
                  {
                    id: uuidv4(),
                    cities: [],
                    continent: "",

                    country: "",
                    isoCode: "",
                  },
                ],
          selectedSkills: {
            core: res.job.skills.filter(
              (skill) => skill.JobsSkillsModel.is_required
            ),
            secondary: res.job.skills.filter(
              (skill) => !skill.JobsSkillsModel.is_required
            ),
          },
        },
      }));
      if (userRole === USER_ROLES.SUPPORT) {
        setIsDisabledForm(true);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [pathname, setJobData, setJobForm, userRole]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setJobForm((prev) => ({
      ...prev,
      ...jobData.job,
      locations:
        jobData.job.locations.length > 0
          ? jobData.job.locations
          : [
              {
                country: "",
                cities: [],
                isoCode: "",
                id: uuidv4(),
                continent: "",
              },
            ],
      selectedSkills: {
        core: jobData.job.skills.filter(
          (skill) => skill.JobsSkillsModel.is_required
        ),
        secondary: jobData.job.skills.filter(
          (skill) => !skill.JobsSkillsModel.is_required
        ),
      },
    }));

    setEdit(false);
  };
  const onSave = async () => {
    try {
      const {
        cultures,
        created_at,
        industries,
        professions,
        referral_external_link,
        selectedSkills,
        timezones,
        locations,
        ...rest
      } = jobForm.job;

      const data = {
        ...rest,
        core_skills_ids: jobForm.job.selectedSkills.core.map(
          (skill) => skill.id
        ),
        secondary_skills_ids: jobForm.job.selectedSkills.secondary.map(
          (skill) => skill.id
        ),
        industries_ids: jobForm.job.industries.map((industry) => industry.id),
        cultures_ids: jobForm.job.cultures.map((culture) => culture.id),
        company_id: jobForm.job.company_id,
        professions_ids: jobForm.job.professions.map(
          (profession) => profession.id
        ),
        members: jobForm.members.map((member) => member.id),
      };

      if (
        work_preferences.includes("Hybrid") ||
        work_preferences.includes("On-site")
      ) {
        data.locations = locations.map((location) => ({
          country: location.country,
          cities: location.cities,
          continent: location.continent,
          isoCode: location.isoCode,
        }));
      }
      if (work_preferences.includes("Remote")) {
        data.timezones = jobForm.job.timezones;
      }
      if (!handleValidation(data, setErrors, "requiements")) return;
      setLoading(true);
      await editJob(data, pathname[pathname.length - 1]);
      history.push("/jobs");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <Spinner />}

      <Row>
        <div className="d-flex  justify-content-end">
          <Col xs={12} xl={4}>
            <div className="d-flex align-items-center ">
              <Form.Control
                disabled={isDisabledForm}
                required
                name="referral_external_link"
                type="text"
                className="me-2"
                placeholder="Enter referral external link"
                value={jobData.job.referral_external_link || ""}
                onChange={handleJobInputChange}
              />
              <Button variant="secondary" onClick={saveExternalLink}>
                Save
              </Button>
            </div>
          </Col>
          {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
        </div>
      </Row>
      <Row className="border border-2 border-main p-2 m-1">
        <div className="d-flex">
          <div
            className="d-grid align-items-start p-3 justify-content-between "
            style={{
              gridTemplateColumns: "2fr 1fr",
              gap: "1rem",
            }}
          >
            <Row>
              {isEdit ? (
                <MainInformationEdit
                  selectedProfessions={professions}
                  seniorities={seniorities}
                  maximum_experience={maximum_experience}
                  minimum_experience={minimum_experience}
                  work_preferences={work_preferences}
                  employment_types={employment_types}
                  minimum_salary={minimum_salary}
                  maximum_salary={maximum_salary}
                  locations={locations}
                  visa_sponsorship={visa_sponsorship}
                  timezones={timezones}
                  selectedLanguages={languages}
                  currency={currency}
                  setJobForm={setJobForm}
                  title={title}
                  errors={errors}
                />
              ) : (
                <MainInformation
                  title={title}
                  professions={professions}
                  seniorities={seniorities}
                  maximum_experience={maximum_experience}
                  minimum_experience={minimum_experience}
                  work_preferences={work_preferences}
                  employment_types={employment_types}
                  minimum_salary={minimum_salary}
                  maximum_salary={maximum_salary}
                  locations={locations}
                  visa_sponsorship={visa_sponsorship}
                  timezones={timezones}
                  languages={languages}
                  currency={currency}
                />
              )}

              {description && (
                <Description
                  setJobData={setJobForm}
                  jobForm={jobForm.job}
                  description={description}
                  isEdit={isEdit}
                  errors={errors}
                />
              )}
            </Row>
            <div className="d-flex flex-column">
              <SkillsAndIndustry
                selectedSkills={jobForm.job.selectedSkills}
                industries={industries}
                isEdit={isEdit}
                setJobForm={setJobForm}
                cultures={cultures}
                errors={errors}
                members={jobForm.members}
                companyId={jobForm.job.company_id}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-between "
            style={{
              width: "140px",
              height: "max-content",
            }}
          >
            <span
              className={`fw-normal text-${jobData.job.status}`}
              style={{
                backgroundColor: getStatusClass(jobData.job.status),
                padding: "2px 4px",
                borderRadius: "5px",
              }}
            >
              {jobData.job.status}
            </span>
            <Actions
              status={jobData.job.status}
              id={pathname[pathname.length - 1]}
              fetchData={fetchData}
            />
          </div>
        </div>
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            {isEdit ? (
              <>
                <Button variant="primary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="secondary" className="ms-2" onClick={onSave}>
                  Save
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={() => setEdit(true)}>
                Edit
              </Button>
            )}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default JobDetail;
