import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import { CompanySizes, IndustriesTypes } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { createCompany } from "../../../api/users/companies/CompaniesApi";
import { getLocations } from "../../../api/locations/locationsApi";
import { getIndustries } from "../../../api/industries/industriesApi";
import { getCultures } from "../../../api/cultures/culturesApi";
import Select from "react-select";
import { City } from "country-state-city";
import { v4 as uuidv4 } from "uuid";
import useContinents from "../../../utils/Hooks/useContinents";
import Locations from "../../../components/Locations";

const CreateCompany = () => {
  const history = useHistory();
  const { continents } = useContinents();
  const [errorMsg, setErrorMsg] = useState("");
  const [locationsList, setLocationsList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [culturesList, setCulturesList] = useState([]);
  const [companyData, setCompanyData] = useState({
    name: "",
    website: "",
    description: "",
    location_id: "",
    year_founded: "",
    size: CompanySizes["1-10"],
    first_name: "",
    last_name: "",
    position: "",
    email: "",
    phone_number: "",
    cultures: [],
    industries: [],
    locations: [],
    contacts: { socials: [{}], linkedin: "", twitter: "" },
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleSocialInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      contacts: { ...companyData.contacts, [name]: value },
    });
  };

  const handleMultiSelectChange = (values, name) => {
    setCompanyData({ ...companyData, [name]: values });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const locations = await getLocations(null);
        setLocationsList(locations);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const handleSaveGeneral = async (event) => {
    event.preventDefault();
    try {
      const data = {
        locations: companyData.locations.map((i) => ({
          isoCode: i.isoCode,
          country: i.country,
          cities: i.cities.map((city) => city.value),
          continent: i.continent,
        })),
        contacts: companyData.contacts,
        name: companyData.name,
        website: companyData.website,
        description: companyData.description,
        year_founded: new Date(companyData.year_founded).toISOString(),
        size: companyData.size,
        first_name: companyData.first_name,
        last_name: companyData.last_name,
        position: companyData.position,
        email: companyData.email,
        phone_number: companyData.phone_number,
        location_id: companyData.location_id,
        cultures: companyData.cultures.map((i) => i.value),
        industries: companyData.industries.map((i) => i.value),
      };
      await createCompany(data);
      history.push("/users/companies");
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleIndustriesList = (search) => {
    try {
      getIndustries({ search: search, type: IndustriesTypes.INDUSTRY }).then(
        (arr) =>
          setIndustriesList(
            arr.map((industry) => ({
              value: industry.id,
              label: industry.name,
            }))
          )
      );
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleCulturesList = (search) => {
    try {
      getCultures({ search: search }).then((arr) =>
        setCulturesList(
          arr.map((culture) => ({ value: culture.id, label: culture.name }))
        )
      );
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleLocationsChange = (value, index, name) => {
    if (name === "isoCode") {
      setCompanyData({
        ...companyData,
        locations: companyData.locations.map((i, n) => {
          if (n === index) {
            i[name] = value.target.value;
            i.cities = [];
          }
          return i;
        }),
      });
    } else {
      setCompanyData({
        ...companyData,
        locations: companyData.locations.map((i, n) => {
          if (n === index) {
            i[name] = value;
          }
          return i;
        }),
      });
    }
  };
  const handleAddLocation = () => {
    if (
      !companyData.locations.length ||
      companyData.locations[companyData.locations.length - 1].cities?.length
    ) {
      setCompanyData({
        ...companyData,
        // locations: [...companyData.locations, {}],
        locations: [
          ...companyData.locations,
          { cities: [], country: "", isoCode: "", continent: "", id: uuidv4() },
        ],
      });
    }
  };

  //   const handleDeleteLocation = (index) => {
  //     const updatedCompanyData = { ...companyData };
  //     updatedCompanyData.locations.splice(index, 1);
  //     setCompanyData(updatedCompanyData);
  //   };
  const handleDeleteLocation = (id) => {
    const updatedCompanyData = { ...companyData };
    updatedCompanyData.locations = updatedCompanyData.locations.filter(
      (location) => location.id !== id
    );
    setCompanyData(updatedCompanyData);
  };

  const handleSocialsChange = (e, index) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      contacts: {
        ...companyData.contacts,
        socials: companyData.contacts.socials.map((i, n) => {
          if (n === index) i[name] = value;
          return i;
        }),
      },
    });
  };

  const handleAddSocial = () => {
    if (
      !companyData.contacts.socials.length ||
      companyData.contacts.socials[companyData.contacts.socials.length - 1]
        ?.name
    ) {
      setCompanyData({
        ...companyData,
        contacts: {
          ...companyData.contacts,
          socials: [...companyData.contacts.socials, {}],
        },
      });
    }
  };

  const handleDeleteSocial = (index) => {
    const updatedCompanyData = { ...companyData };
    updatedCompanyData.contacts.socials.splice(index, 1);
    setCompanyData(updatedCompanyData);
  };

  const generateCountryList = (location) => {
    const allCountries = Object.values(continents).filter(
      ({ continent }) => continent === location.continent
    );

    if (!allCountries.length) {
      return [];
    }
    return allCountries[0];
  };
  return (
    <>
      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form onSubmit={handleSaveGeneral}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="name">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        required
                        name="name"
                        type="text"
                        placeholder="Enter company name"
                        value={companyData.name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="year_founded">
                      <Form.Label>Founded</Form.Label>
                      <Form.Control
                        required
                        name="year_founded"
                        type="date"
                        min="1900"
                        max={new Date().toISOString().split("T")[0]}
                        value={companyData.year_founded}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="size">
                      <Form.Label>Size</Form.Label>
                      <Form.Select
                        name="size"
                        onChange={handleInputChange}
                        defaultValue={CompanySizes["1-10"]}
                        value={companyData.role}
                      >
                        {Object.entries(CompanySizes).map(([k, v]) => (
                          <option key={k} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="location_id">
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        name="location_id"
                        onChange={handleInputChange}
                        defaultValue={companyData.location_id || ""}
                        value={companyData.location_id || ""}
                      >
                        <option>Select</option>
                        {locationsList.map((i, index) => (
                          <option value={i.id} key={index}>
                            {i.city} ({i.country})
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="website">
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        required
                        name="website"
                        type="text"
                        placeholder="Enter website"
                        value={companyData.website}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="cultures">
                      <Form.Label>Cultures</Form.Label>
                      <Select
                        required
                        value={companyData.cultures}
                        options={culturesList}
                        isMulti
                        name="cultures"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onFocus={() => handleCulturesList("")}
                        onChange={(v) => handleMultiSelectChange(v, "cultures")}
                        onInputChange={handleCulturesList}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="industries">
                      <Form.Label>Industries</Form.Label>
                      <Select
                        required
                        value={companyData.industries}
                        options={industriesList}
                        isMulti
                        name="industries"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onFocus={() => handleIndustriesList("")}
                        onChange={(v) =>
                          handleMultiSelectChange(v, "industries")
                        }
                        onInputChange={handleIndustriesList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="linkedin">
                      <Form.Label>Linkedin</Form.Label>
                      <Form.Control
                        name="linkedin"
                        type="text"
                        placeholder="https://linkedin.com/"
                        value={companyData.contacts.linkedin}
                        onChange={handleSocialInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="twitter">
                      <Form.Label>Twitter</Form.Label>
                      <Form.Control
                        name="twitter"
                        type="text"
                        placeholder="https://twitter.com/"
                        value={companyData.contacts.twitter}
                        onChange={handleSocialInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Label>Socials</Form.Label>
                {companyData.contacts.socials.map((social, index) => (
                  <React.Fragment key={index}>
                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group id="name">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            required
                            name="name"
                            type="text"
                            placeholder="Enter name"
                            value={social.name}
                            onChange={(v) => handleSocialsChange(v, index)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="link">
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            required
                            name="link"
                            type="text"
                            placeholder="Enter link"
                            value={social.link}
                            onChange={(v) => handleSocialsChange(v, index)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        {companyData.contacts.socials.length && (
                          <button onClick={() => handleDeleteSocial(index)}>
                            <i className="fas fa-times"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
                <div className="mb-3">
                  <Button variant="primary" onClick={handleAddSocial}>
                    Add Social
                  </Button>
                </div>
                <Form.Label>Locations</Form.Label>
                {companyData.locations.map((location, index) => (
                  <React.Fragment key={location.id}>
                    <Locations
                      location={location}
                      continents={continents}
                      setState={setCompanyData}
                      generateCountryList={generateCountryList}
                      City={City}
                      handleDeleteLocation={handleDeleteLocation}
                      data={companyData}
                      index={index}
                    />
                  </React.Fragment>
                ))}
                <div className="mt-3">
                  <Button variant="primary" onClick={handleAddLocation}>
                    Add Location
                  </Button>
                </div>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        rows="3"
                        maxLength="500"
                        name="description"
                        as="textarea"
                        type="text"
                        placeholder="Enter description"
                        value={companyData.description}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h5 className="mb-4">Owner info</h5>
                <Row>
                  <Col className="mb-3">
                    <Form.Group id="position">
                      <Form.Label>Position</Form.Label>
                      <Form.Control
                        required
                        name="position"
                        type="text"
                        placeholder="Enter Position"
                        value={companyData.position}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        name="first_name"
                        type="text"
                        placeholder="Enter first name"
                        value={companyData.first_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        name="last_name"
                        type="text"
                        placeholder="Also last name"
                        value={companyData.last_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        name="email"
                        type="email"
                        placeholder="name@mail.com"
                        value={companyData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        // required
                        name="phone_number"
                        type="phone"
                        placeholder="+12-345 678 910"
                        value={companyData.phone_number}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateCompany;
