import React, { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import { getLanguages } from "../../../../api/utills/utillsAPI";
import Select from "react-select";
import { languageLevels } from "../../../../utils/constants";
import { saveLanguages } from "../../../../api/users/experiencesAPI";

const Languages = ({ languages, setSelectedLanguages, isDisabledForm }) => {
  const pathname = window.location.href.split("/");
  const id = pathname[pathname.length - 1];
  const [languagesList, setLanguages] = useState([]);

  const fetchLanguages = async () => {
    try {
      const res = await getLanguages();
      setLanguages(res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLanguageChange = useCallback((selectedOption, id) => {
    const newLanguage = selectedOption.label;
    const isExist = languages.find((l) => l.label === newLanguage);
    if (isExist) {
      return;
    }
    setSelectedLanguages((prev) => {
      return prev.map((l) => {
        if (l.id === id) {
          return {
            ...l,
            value: selectedOption.value,
            label: selectedOption.label,
          };
        }
        return l;
      });
    });
  }, []);

  const handleLevelChange = useCallback((selectedOption, id) => {
    const newLevel = selectedOption.label;
    setSelectedLanguages((prev) => {
      return prev.map((l) => {
        if (l.id === id) {
          return { ...l, level: newLevel };
        }
        return l;
      });
    });
  }, []);

  const handleAdd = useCallback(() => {
    setSelectedLanguages([
      ...languages,
      { value: "", label: "", level: "", id: uuidv4() },
    ]);
  }, [languages]);

  const handleDelete = useCallback(
    (id) => {
      setSelectedLanguages(languages.filter((l) => l.id !== id));
    },
    [languages]
  );

  const onLanguagesSave = useCallback(async () => {
    try {
      const data = languages
        .filter((l) => l.value !== "" && l.level !== "")
        .map((l) => ({
          lang: l.value,
          level: l.level,
        }));
      await saveLanguages(id, data);
    } catch (error) {
      console.error(error);
    }
  }, [languages]);

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onLanguagesSave();
      }}
    >
      <h5 className="my-4">Language</h5>
      {languages.map((l, index) => (
        <div key={index} className="my-4">
          <Row className="d-flex mt-2 align-items-end">
            <Col xs={5}>
              <Form.Group id="languages">
                <Form.Label>Languages</Form.Label>
                <Select
                  //   required
                  hideSelectedOptions={false}
                  closeMenuOnSelect={true}
                  options={languagesList?.map((item) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  value={{
                    value: l.value,
                    label: l.label,
                  }}
                  onFocus={() => fetchLanguages("")}
                  placeholder="Select languages"
                  name="languages"
                  className={`basic-multi-select `}
                  classNamePrefix="select"
                  onChange={(v) => handleLanguageChange(v, l.id)}
                />
              </Form.Group>
            </Col>
            <Col xs={5}>
              <Form.Group id="level">
                <Form.Label>Level</Form.Label>
                <Select
                  //   required
                  hideSelectedOptions={false}
                  closeMenuOnSelect={true}
                  options={languageLevels}
                  value={{
                    value: l.level,
                    label: l.level,
                  }}
                  name="level"
                  className={`basic-multi-select `}
                  classNamePrefix="select"
                  onChange={(v) => handleLevelChange(v, l.id)}
                />
              </Form.Group>
            </Col>
            <Col xs={2} className="mt-2 ">
              <Button variant="danger" onClick={() => handleDelete(l.id)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ))}
      {!isDisabledForm && (
        <>
          <div className="mt-3">
            <Button variant="primary" onClick={handleAdd}>
              Add Another Language
            </Button>
          </div>

          <div className="mt-3 d-flex align-items-center">
            <Button className="me-2 btn" variant="primary" type="submit">
              Save
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Languages;
