import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getApplications = async (query) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/chats?${qs.stringify(query)}`);

    return data;
};

export const getApplication = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/chats/${id}`);

    return data;
};

export const deleteApplication = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.delete(`${API_ROOT_URL}/porrige/chats/${id}`);

    return data;
};
