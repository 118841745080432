import React, { useCallback, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditDescription from "./EditComponents/EditDescription";

const Description = ({ description, isEdit, setJobData }) => {
  return (
    <div>
      <p className="m-0 mb-0 text-uppercase fw-bold text-muted">Description</p>

      {isEdit ? (
        <div
        // className={clsx("relative ", {
        //   "px-[16px]": edit,
        // })}
        >
          {/* {descriptionLoading && (
          <div className="pointer-events-none">
            <Spinner />
          </div>
        )} */}

          <EditDescription setData={setJobData} description={description} />
        </div>
      ) : (
        <ReactQuill
          value={description}
          readOnly={true}
          theme="snow"
          modules={{ toolbar: false }}
          style={{ border: "none" }}
        />
      )}
    </div>
  );
};

export default Description;
