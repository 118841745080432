import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import { createCandidate } from "../../../api/users/candidatesApi";
import {
  Currencies,
  EmploymentTypes,
  USER_ROLES,
  UserSeniority,
  WorkPreferences,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getSkills } from "../../../api/skills/skillsApi";
import { getProfessions } from "../../../api/professions/professionsApi";

const CreateCandidate = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [skillsList, setSkillsList] = useState([]);
  const [professionsList, setProfessionList] = useState([]);

  const [userData, setUserData] = useState({
    email: "",
    last_name: "",
    first_name: "",
    phone_number: "",
    password: "",
    seniority: UserSeniority.TRAINEE_JUNIOR,
    work_preference: [],
    employment_type: [],
    salary: "",
    currency: Currencies.EUR,
    experience_years: "",
    professions: [],
    core_skills: [],
    secondary_skills: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (values, name) => {
    setUserData({
      ...userData,
      [name]: values,
    });
  };

  const handleSaveGeneral = async (event) => {
    event.preventDefault();
    setErrorMsg("");
    const core_skills = userData.core_skills.map((i) => i.value);
    const secondary_skills = userData.secondary_skills.map((i) => i.value);
    if (core_skills.find((val) => secondary_skills.includes(val))) {
      setErrorMsg("Core and secondary skills must be unique");
      return false;
    }

    try {
      await createCandidate({
        ...userData,
        experience_years: Number(userData.experience_years),
        salary: Number(userData.salary),
        work_preference: userData.work_preference.map((i) => i.value),
        employment_type: userData.employment_type.map((i) => i.value),
        professions: userData.professions.map((i) => i.value),
        core_skills: core_skills,
        secondary_skills: secondary_skills,
      });
      history.push("/users/candidates");
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const handleSkillsList = (search) => {
    try {
      if (search.length) {
        getSkills({ search: search }).then((arr) =>
          setSkillsList(
            arr.map((skill) => ({ value: skill.id, label: skill.name }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const handleProfessionsList = (search) => {
    try {
      if (search?.length) {
        getProfessions({ search: search }).then((arr) =>
          setProfessionList(
            arr.map((skill) => ({ value: skill.id, label: skill.name }))
          )
        );
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form onSubmit={handleSaveGeneral}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        name="first_name"
                        type="text"
                        placeholder="Enter first name"
                        value={userData.first_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        name="last_name"
                        type="text"
                        placeholder="Also last name"
                        value={userData.last_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        name="email"
                        type="email"
                        placeholder="name@mail.com"
                        value={userData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        required
                        name="phone_number"
                        type="phone"
                        placeholder="+12-345 678 910"
                        value={userData.phone_number}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="seniority">
                      <Form.Label>Seniority</Form.Label>
                      <Form.Select
                        name="seniority"
                        onChange={handleInputChange}
                        defaultValue={UserSeniority.TRAINEE_JUNIOR}
                        value={userData.seniority}
                      >
                        {Object.entries(UserSeniority).map(([k, v]) => (
                          <option key={k} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="experience">
                      <Form.Label>Experience Years</Form.Label>
                      <Form.Control
                        required
                        name="experience_years"
                        type="number"
                        placeholder="5"
                        value={userData.experience_years}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="currency">
                      <Form.Label>Currency</Form.Label>
                      <Form.Select
                        name="currency"
                        onChange={handleInputChange}
                        defaultValue={Currencies.EUR}
                        value={userData.currency}
                      >
                        {Object.entries(Currencies).map(([k, v]) => (
                          <option key={k} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="salary">
                      <Form.Label>Salary</Form.Label>
                      <Form.Control
                        required
                        name="salary"
                        type="number"
                        placeholder="50000"
                        value={userData.salary}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="work_preference">
                      <Form.Label>Work Preference</Form.Label>
                      <Select
                        required
                        value={userData.work_preference}
                        options={Object.entries(WorkPreferences).map(
                          ([k, v]) => ({ value: v, label: v })
                        )}
                        isMulti
                        name="work_preference"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v) => {
                          handleMultiSelectChange(v, "work_preference");
                        }}
                      ></Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="employment_type">
                      <Form.Label>Employment Type</Form.Label>
                      <Select
                        required
                        value={userData.employment_type}
                        options={Object.entries(EmploymentTypes).map(
                          ([k, v]) => ({ value: v, label: v })
                        )}
                        isMulti
                        name="employment_type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v) => {
                          handleMultiSelectChange(v, "employment_type");
                        }}
                      ></Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="professions">
                      <Form.Label>Profession</Form.Label>
                      <Select
                        required
                        value={userData.professions}
                        options={professionsList}
                        isMulti
                        name="professions"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v) => {
                          handleMultiSelectChange(v, "professions");
                        }}
                        onInputChange={handleProfessionsList}
                      ></Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="core_skills">
                      <Form.Label>Core Skills</Form.Label>
                      <Select
                        required
                        value={userData.core_skills}
                        options={skillsList}
                        isMulti
                        name="core_skills"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v) => {
                          handleMultiSelectChange(v, "core_skills");
                        }}
                        onInputChange={handleSkillsList}
                      ></Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="secondary_skills">
                      <Form.Label>Secondary Skills</Form.Label>
                      <Select
                        required
                        value={userData.secondary_skills}
                        options={skillsList}
                        isMulti
                        name="secondary_skills"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v) => {
                          handleMultiSelectChange(v, "secondary_skills");
                        }}
                        onInputChange={handleSkillsList}
                      ></Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        name="password"
                        type="password"
                        value={userData.password}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateCandidate;
