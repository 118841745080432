import {getAxiosInstance} from '../../axios';
import {API_ROOT_URL} from '../../../utils/constants';
import qs from 'qs';

export const getCompanies = async (query) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/company?${qs.stringify(query)}`);

    return data;
};

export const getCompany = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/company/${id}`);

    return data;
};

export const getCompaniesList = async (filter) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/company/list?${qs.stringify(filter)}`);

    return data;
};

export const updateCompany = async (id, company) => {
    const axios = getAxiosInstance();
    const { data } = await axios.put(`${API_ROOT_URL}/porrige/company/${id}`, company);

    return data;
};

export const createCompany = async (company) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/company`, company);

    return data;
};

export const createRecruiter = async (company) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/company/recruiter`, company);

    return data;
};

export const deleteCompany = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.delete(`${API_ROOT_URL}/porrige/company/${id}`);

    return data;
};

export const deleteRecruiter = async (id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.delete(`${API_ROOT_URL}/porrige/company/recruiter/${id}`);

    return data;
};

export const updateCompanyUser = async (id, owner) => {
    const axios = getAxiosInstance();
    const { data } = await axios.put(`${API_ROOT_URL}/porrige/company/user/${id}`, owner);

    return data;
};

export const sendToLever = async (id, status) => {
    const axios = getAxiosInstance();
    const { data } = await axios.patch(`${API_ROOT_URL}/porrige/company/send-to-lever/${id}`, {is_send_to_lever: status});

    return data;
};
