import { getAxiosInstance } from "../axios";
import { API_ROOT_URL } from "../../utils/constants";
import qs from "qs";

export const getLocations = async (query) => {
  const axios = getAxiosInstance();
  if (query) {
    const { data } = await axios.get(
      `${API_ROOT_URL}/porrige/location?${qs.stringify(query)}`
    );
    return data;
  } else {
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/location`);
    return data;
  }
};
