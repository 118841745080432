import {getAxiosInstance} from '../axios';
import {API_ROOT_URL} from '../../utils/constants';
import qs from 'qs';

export const getCultures = async (query) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/cultures?${qs.stringify(query)}`);

    return data;
};
