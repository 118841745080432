import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Breadcrumb, Card, Table, Nav, Pagination } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import { ChatsApplicationStatuses } from "../../utils/constants";
import { formatDate, getStatusClass, shortId } from "../../utils/helpers";
import Actions from "../jobs/JobDetails/Actions";
import InputSearch from "../components/InputSearch";
import Select from "react-select";
import { getCompaniesList } from "../../api/users/companies/CompaniesApi";
import { getApplications } from "../../api/applications/applicationsApi";

const ApplicationsTable = () => {
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [query, setQuery] = useState({
        page: 1,
        per_page: 20,
        search: "",
        status: "",
        company_id: "",
    });

    const userRole = localStorage.getItem("role");

    const [applications, setApplications] = useState({ count: 0, data: [] });
    const [tableData, setTableData] = useState([]);
    const [paginationData, setPaginationData] = useState("");

    const [companiesList, setCompaniesList] = useState([]);

    const handleCompaniesList = (search) => {
        try {
            if (search.length) {
                getCompaniesList({ search: search }).then((arr) =>
                    setCompaniesList(arr)
                );
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    const handlePaginationPage = useCallback(
        async (value, maxPage) => {
            if (is_paginate) {
                return false;
            }
            let new_page;
            if (value === "prev") {
                if (query.page === 1) {
                    return false;
                }
                new_page = query.page - 1;
            } else if (value === "next") {
                if (query.page === maxPage) {
                    return false;
                }
                new_page = query.page + 1;
            } else {
                new_page = Number(value);
            }
            query.page = new_page;
            is_paginate = true;
            const res = await getApplications(query);
            setApplications(res);
            setTableData(
                res.data.map((t) => <TableRow key={`application-${t.id}`} {...t} />)
            );
            setPaginationData(
                generatePagination(query.page, res.count, query.per_page)
            );
            is_paginate = false;
        },
        [query]
    );

    const generatePagination = useCallback(
        (page, count, per_page) => {
            const totalPages = Math.ceil(count / per_page);

            if (count > per_page) {
                const paginationItems = [];

                for (let i = 1; i <= totalPages; i++) {
                    // Only show the first page, the last page, the current page, and two pages on either side of the current page
                    if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
                        paginationItems.push(
                            <Pagination.Item
                                key={i}
                                value={i}
                                active={i === page}
                                onClick={() => handlePaginationPage(i, totalPages)}
                            >
                                {i}
                            </Pagination.Item>
                        );
                    } else if (i === 2 || i === totalPages - 1) {
                        // Add ellipsis for hidden pages
                        paginationItems.push(<Pagination.Ellipsis key={i} />);
                    }
                }

                return (
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                            <Pagination.Prev
                                value="prev"
                                onClick={() => handlePaginationPage("prev", totalPages)}
                            >
                                Previous
                            </Pagination.Prev>
                            {paginationItems}
                            <Pagination.Next
                                value="next"
                                onClick={() => handlePaginationPage("next", totalPages)}
                            >
                                Next
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                );
            }
        },
        [handlePaginationPage]
    );
    const fetchData = async () => {
        try {
            const res = await getApplications(query);
            setApplications(res);
            setTableData(
                res.data.map((t) => <TableRow key={`application-${t.id}`} {...t} />)
            );
            setPaginationData(
                generatePagination(query.page, res.count, query.per_page)
            );
        } catch (err) {
            console.log(err);
        }
    };

    const TableRow = (props) => {
        const {
            created_at,
            chats_applications_status,
            id,
            title,
            name,
            first_name,
            last_name,
            matching_score,
        } = props;

        return (
            <tr>
                <td>
                    <Card.Link
                        as={Link}
                        to={Routes.ApplicationDetail.path.replace(":id", id)}
                        className="fw-normal"
                    >
                        {shortId(id)}
                    </Card.Link>
                </td>
                <td>
                    <span className="fw-normal">{title}</span>
                </td>
                <td>
                    <span className="fw-normal">{name}</span>
                </td>
                <td>
                    <span className="fw-normal">{first_name} {last_name}</span>
                </td>
                <td>
                    {matching_score && (
                        <span className="fw-normal">{matching_score.toFixed(2) + '% match'}</span>
                    )}
                </td>
                <td>
                    <span className="fw-normal">{formatDate(created_at)}</span>
                </td>
                <td>
          <span
              className={`fw-normal text-${chats_applications_status}`}
              style={{
                  backgroundColor: getStatusClass(chats_applications_status),
                  padding: "2px 4px",
                  borderRadius: "5px",
              }}
          >
            {chats_applications_status}
          </span>
                </td>
                <td>
                    <Actions status={chats_applications_status} id={id} fetchData={fetchData} />
                </td>
            </tr>
        );
    };

    useEffect(() => {
        fetchData();
    }, [query]);

    const handleSelectChange = (value, name) => {
        setSelectedCompany(value);
        setQuery((prev) => ({
            ...prev,
            [name]: value.value,
        }));
    };

    const totalApplications = applications.count;
    const currentApplications = applications.data.length + (query.page - 1) * query.per_page;

    let is_paginate = false;

    const statusHandler = (status) => {
        setQuery((prevQuery) => {
            if (prevQuery.status === status) {
                return { ...prevQuery, status: "" };
            } else {
                return { ...prevQuery, status: status };
            }
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Calyptus</Breadcrumb.Item>
                        <Breadcrumb.Item active>Applications</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Applications</h4>
                    <p className="mb-0">Applications list.</p>
                </div>
            </div>

            <div className="mb-4">
                <Row className="d-flex justify-content-start align-items-center mb-4">
                    <div
                        style={{
                            marginRight: "10px",
                            width: "300px",
                        }}
                    >
                        <InputSearch setQuery={setQuery} />
                    </div>
                    <Col md={4}>
                        <Form.Group id="company_id" className="w-100">
                            <Select
                                placeholder="Start typing to search for a company..."
                                required
                                value={selectedCompany}
                                options={companiesList.map((i) => ({
                                    value: i.id,
                                    label: i.name,
                                }))}
                                name="company_id"
                                classNamePrefix="select"
                                onChange={(v) => handleSelectChange(v, "company_id")}
                                onInputChange={handleCompaniesList}
                            />
                        </Form.Group>
                    </Col>
                    {/*<div className="d-flex" style={{width: "max-content"}}>*/}
                    {/*    {Object.values(ChatsApplicationStatuses).map((status, index) => {*/}
                    {/*        return (*/}
                    {/*            <span*/}
                    {/*                className="badge rounded-pill  me-2 px-3 py-2"*/}
                    {/*                key={index}*/}
                    {/*                style={{*/}
                    {/*                    backgroundColor: query.status === status ? "#61DAFB" : "#F2F2F3",*/}
                    {/*                    color: "black",*/}
                    {/*                    cursor: "pointer",*/}
                    {/*                }}*/}
                    {/*                onClick={() => statusHandler(status)}*/}
                    {/*            >{status}</span>*/}
                    {/*        );*/}
                    {/*    })}*/}
                    {/*</div>*/}
                </Row>
            </div>

            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <Table hover className="user-table align-items-center">
                        <thead>
                        <tr>
                            <th className="border-bottom">#</th>
                            <th className="border-bottom">Job Title</th>
                            <th className="border-bottom">Company</th>
                            <th className="border-bottom">Candidate</th>
                            <th className="border-bottom">Matching score</th>
                            <th className="border-bottom">Application Date</th>
                            <th className="border-bottom">Status</th>
                            <th className="border-bottom"></th>
                        </tr>
                        </thead>
                        <tbody>{tableData}</tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                        {paginationData}
                        <small className="fw-bold">
                            Showing <b>{currentApplications}</b> out of <b>{totalApplications}</b> entries
                        </small>
                    </Card.Footer>
                </Card.Body>
            </Card>
        </>
    );
};

export default ApplicationsTable;
