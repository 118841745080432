import React from "react";
import { TableForm } from "./TableForm";

const MainInformation = ({
  professions = [],
  seniorities = [],
  maximum_experience,
  minimum_experience,
  work_preferences = [],
  employment_types = [],
  locations = [],
  visa_sponsorship,
  timezones = [],
  languages = [],
  currency,
  minimum_salary,
  maximum_salary,
  title,
}) => {
  const jobRoles = professions.map((item) => item.name).join(", ");
  const experienceLevel = seniorities
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(", ");
  const baseSalary =
    minimum_salary && maximum_salary
      ? `${minimum_salary} - ${maximum_salary} ${currency}`
      : "";
  const visaSponsorshipStatus = visa_sponsorship
    ? "Can be provided"
    : "Not Available";

  return (
    <div className="d-flex flex-column align-items-start p-[16px]">
      <p className="h2">{title}</p>

      <p className="m-0 mb-0 text-uppercase fw-bold text-muted">Experience</p>
      <table className="">
        <tbody>
          {professions.length > 0 && (
            <TableForm name="Job Roles" value={jobRoles} />
          )}
          {seniorities.length > 0 && (
            <TableForm name="Experience Level" value={experienceLevel} />
          )}
          {(minimum_experience || maximum_experience) && (
            <TableForm
              name="Years of Experience"
              value={
                [minimum_experience, maximum_experience]
                  .map((i) => i)
                  .join("- ") + "years"
              }
            />
          )}

          <tr className="">
            <th className="m-0 mb-0 text-uppercase fw-bold text-muted">
              Basic Information
            </th>
          </tr>
          {work_preferences.length > 0 && (
            <TableForm
              name="Work Preference"
              value={work_preferences.join(", ")}
            />
          )}
          {employment_types.length > 0 && (
            <TableForm
              name="Employment Type"
              value={employment_types.join(", ")}
            />
          )}
          <TableForm name="Base Salary" value={baseSalary} />

          {(locations[0].country || locations[0].cities) && (
            <TableForm
              name="Remote Locations"
              value={locations
                .map((location) => {
                  const country = location.country ?? "";
                  return country
                    ? `${location.cities}, ${country}`
                    : location.cities;
                })
                .join(" ; ")}
            />
          )}

          <TableForm name="Visa Sponsorship" value={visaSponsorshipStatus} />
          {timezones && timezones.some((item) => item !== "") && (
            <TableForm name="Timezones" value={timezones.join(", ")} />
          )}
          {languages.length > 0 && (
            <TableForm name="Language" value={languages.join(", ")} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MainInformation;
