import React, { useCallback, useEffect, useState } from "react";
import {
  addTransaction,
  getUserPoints,
} from "../../../../api/users/candidatesApi";
import { Col, Form, Card, Button } from "@themesberg/react-bootstrap";

const Points = () => {
  const pathname = window.location.href.split("/");

  const [points, setPoints] = useState({});

  const [transactionNote, setTransactionNote] = useState("");
  const [transactionAmount, setTransactionAmount] = useState(0);

  const fetchPoints = useCallback(async () => {
    try {
      const points = await getUserPoints(pathname[pathname.length - 1]);
      setPoints(points);
    } catch (error) {
      console.log(error);
    }
  }, [setPoints]);

  useEffect(() => {
    fetchPoints();
  }, [fetchPoints]);

  const saveNote = async (e) => {
    e.preventDefault();
    try {
      const transaction = {
        points: +transactionAmount,
        description: transactionNote,
      };
      await addTransaction(pathname[pathname.length - 1], transaction);
      setTransactionAmount("");
      fetchPoints();
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (e) => {
    setTransactionAmount(e.target.value);
  };

  if (!Object.keys(points).length) {
    return <div>Loading...</div>;
  }

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <p className="mb-3 mt-3">
          <strong>Total Amount: {points.amount}</strong>
        </p>

        <table className="table table-centered table-nowrap mb-0 rounded">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Points</th>
              <th className="border-0">Name</th>
              <th className="border-0">Date</th>
            </tr>
          </thead>
          <tbody>
            {points.point_transactions?.map((point) => (
              <tr>
                <td>{point.points}</td>
                <td>{point.description}</td>
                <td> {new Date(point.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Col xs={6}>
          <Form.Group id="title">
            <Form.Label className="form-label mt-3">Amount</Form.Label>
            <Form.Control
              required
              name="amount"
              type="number"
              className="me-2"
              placeholder="Enter number of years of experience"
              value={transactionAmount}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Form onSubmit={saveNote}>
          <Col md={12} className="mb-3">
            <Form.Group id="description">
              <Form.Label>Note</Form.Label>
              <Form.Control
                rows="5"
                name="note"
                as="textarea"
                type="text"
                placeholder="Enter transaction note"
                value={transactionNote}
                onChange={(v) => setTransactionNote(v.target.value)}
              />
            </Form.Group>
          </Col>
          <div className="mt-3">
            <Button variant="primary" type="submit">
              Save Transaction
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Points;
