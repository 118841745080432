import { useState, useEffect } from "react";
import { getLocations } from "../../api/locations/locationsApi";
const useContinents = () => {
  const [continents, setContinents] = useState([]);

  useEffect(() => {
    const fetchContinents = async () => {
      try {
        const res = await getLocations();
        const continentsObj = res.reduce(
          (acc, { country, continent, isoCode }) => {
            if (!acc[continent]) {
              acc[continent] = { continent, country: [] };
            }
            if (
              !acc[continent].country.find(
                (c) => c.value === country && c.isoCode === isoCode
              )
            ) {
              acc[continent].country.push({
                value: country,
                isoCode: isoCode,
                label: country,
                name: country,
              });
            }
            return acc;
          },
          {}
        );
        setContinents(continentsObj);
      } catch (error) {
        console.error(error);
      }
    };
    fetchContinents();
  }, []);
  return { continents };
};

export default useContinents;
