import {getAxiosInstance} from '../axios';
import {API_ROOT_URL} from '../../utils/constants';

export const getApproves = async (user_id) => {
    const axios = getAxiosInstance();
    const { data } = await axios.get(`${API_ROOT_URL}/porrige/candidates/approves/${user_id}`);

    return data;
};

export const createApprove = async (approve) => {
    const axios = getAxiosInstance();
    const { data } = await axios.post(`${API_ROOT_URL}/porrige/candidates/approves`, approve);

    return data;
}
