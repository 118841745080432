import React from "react";
import { Button, Dropdown } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { saveJobStatus } from "../../../api/jobs/jobsAPI";

const Actions = ({ status, id, fetchData }) => {
  const _saveJobStatus = async (status) => {
    try {
      await saveJobStatus(id, status);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const generateMenu = (status) => {
    const baseItems = [
      //   {
      //     name: "Edit",
      //     func: () => {
      //       dispatch(setJobEdit(true));
      //       openHandler();
      //     },
      //   },
      //   {
      //     name: "Delete",
      //     func: () => {},
      //   },
    ];

    let specificItems = [];
    switch (status) {
      case "on hold":
        specificItems = [
          {
            name: "Reopen",
            func: () => _saveJobStatus("published"),
          },
          {
            name: "Close Job Position",
            func: () => _saveJobStatus("closed"),
          },
        ];
        break;
      case "published":
        specificItems = [
          {
            name: "Close Job Position",
            func: () => _saveJobStatus("closed"),
          },

          {
            name: "Put Position On Hold",
            func: () => _saveJobStatus("on hold"),
          },
        ];

        break;
      case null:
        specificItems = [
          {
            name: "Publish",
            func: () => _saveJobStatus("published"),
          },
        ];
        break;
      case "draft":
        specificItems = [
          {
            name: "Open",
            func: () => _saveJobStatus("published"),
          },
        ];
        break;
      case "closed":
        specificItems = [
          {
            name: "Reopen",
            func: () => _saveJobStatus("published"),
          },
        ];
        break;
      default:
        break;
    }

    return [...specificItems, ...baseItems];
  };

  return (
    <div>
      <Dropdown className="btn-toolbar" drop="left">
        <Dropdown.Toggle as={Button} variant="light" size="sm" className="me-2">
          <FontAwesomeIcon icon={faEllipsisV} />{" "}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
          {generateMenu(status).map((item, index) => (
            <Dropdown.Item onClick={item.func} key={index}>
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Actions;
