import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Breadcrumb,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes";
import { USER_ROLES } from "../../../utils/constants";
import { getCompanies } from "../../../api/users/companies/CompaniesApi";
import { formatDate, shortId } from "../../../utils/helpers";
import InputSearch from "../../components/InputSearch";

const TableRow = (props) => {
  let { id, name, size, year_founded, created_at } = props;

  return (
    <tr>
      <td>
        <Card.Link
          as={Link}
          to={Routes.CompanyDetail.path.replace(":id", id)}
          className="fw-normal"
        >
          {shortId(id)}
        </Card.Link>
      </td>
      <td>
        <span className="fw-normal">{name}</span>
      </td>
      <td>
        <span className="fw-normal">{size}</span>
      </td>
      <td>
        <span className="fw-normal">
          {year_founded}
          {/*{year_founded && new Date(year_founded).getFullYear()}*/}
        </span>
      </td>
      <td>
        <span className="fw-normal">{formatDate(created_at)}</span>
      </td>
    </tr>
  );
};

export const CompaniesTable = () => {
  const [query, setQuery] = useState({
    page: 1,
    per_page: 20,
    search: "",
  });

  const userRole = localStorage.getItem("role");

  const [users, setUsers] = useState({ count: 0, data: [] });
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCompanies(query);
        setUsers(res);
        setTableData(
          res.data.map((t) => <TableRow key={`company-${t.id}`} {...t} />)
        );
        setPaginationData(
          generatePagination(query.page, res.count, query.per_page)
        );
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [query]);

  const totalUsers = users.count;
  const currentUsers = users.data.length + (query.page - 1) * query.per_page;

  let is_paginate = false;

  const handlePaginationPage = async (value, maxPage) => {
    if (is_paginate) {
      return false;
    }
    let new_page;
    if (value === "prev") {
      if (query.page === 1) {
        return false;
      }
      new_page = query.page - 1;
    } else if (value === "next") {
      if (query.page === maxPage) {
        return false;
      }
      new_page = query.page + 1;
    } else {
      new_page = Number(value);
    }
    query.page = new_page;
    is_paginate = true;
    const res = await getCompanies(query);
    setUsers(res);
    setTableData(
      res.data.map((t) => <TableRow key={`company-${t.id}`} {...t} />)
    );
    setPaginationData(
      generatePagination(query.page, res.count, query.per_page)
    );
    is_paginate = false;
  };

  const generatePagination = (page, count, per_page) => {
    if (count > per_page) {
      const paginationItems = [];
      for (let i = 1; i <= Math.ceil(count / per_page); i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            value={i}
            active={i === page}
            onClick={() => handlePaginationPage(i, Math.ceil(count / per_page))}
          >
            {i}
          </Pagination.Item>
        );
      }
      return (
        <Nav>
          <Pagination className="mb-2 mb-lg-0">
            <Pagination.Prev
              value="prev"
              onClick={() =>
                handlePaginationPage("prev", Math.ceil(count / per_page))
              }
            >
              Previous
            </Pagination.Prev>
            {paginationItems}
            <Pagination.Next
              value="next"
              onClick={() =>
                handlePaginationPage("next", Math.ceil(count / per_page))
              }
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Nav>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calyptus</Breadcrumb.Item>
            <Breadcrumb.Item active>Companies</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Companies</h4>
          <p className="mb-0">Companies list.</p>
          {userRole !== USER_ROLES.SUPPORT && (
            <Link to={Routes.CreateCompany.path}>
              <Dropdown.Toggle
                as={Button}
                variant="secondary"
                className="text-dark me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>Create New</span>
              </Dropdown.Toggle>
            </Link>
          )}
        </div>
      </div>

      <div className="mb-4">
        <InputSearch setQuery={setQuery} />
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">Title</th>
                <th className="border-bottom">Size</th>
                <th className="border-bottom">Year founded</th>
                <th className="border-bottom">Created Date</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {paginationData}
            <small className="fw-bold">
              Showing <b>{currentUsers}</b> out of <b>{totalUsers}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default CompaniesTable;
