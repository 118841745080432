import React, { useCallback, useState } from "react";
import { Col, Form, Row } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { getSkills } from "../../../api/skills/skillsApi";
import { getIndustries } from "../../../api/industries/industriesApi";
import { getCultures } from "../../../api/cultures/culturesApi";
import { getManagers } from "../../../api/company/managersAPI";
import { getCompaniesList } from "../../../api/users/companies/CompaniesApi";

export const SkillsConfig = ({
  selectedSkills,
  industries,
  cultures,
  company,
  isEdit,
  isCreate,
  members,
  options,
  fetchManagers = () => {},
  fetchSkills = () => {},
  fetchIndustries = () => {},
  fetchCultures = () => {},
  handleSkillsChange = () => {},
  handleIndustriesChange = () => {},
  handleCulturesChange = () => {},
  handleMembers = () => {},
  fetchCompanies = () => {},
  handleCompany = () => {},
}) => {
  const config = [
    {
      condition: selectedSkills?.core.length > 0 || isEdit,
      element: {
        title: "Core Skills",
        type: "core",
        id: "core_skills_ids",
        data: selectedSkills?.core,
        maxCount: 10,
        options: options?.skills,
        getOptions: fetchSkills,
        setSelected: handleSkillsChange,
      },
    },
    {
      condition: selectedSkills?.secondary.length > 0 || isEdit,
      element: {
        title: "Secondary Skills",
        type: "secondary",
        id: "secondary_skills_ids",
        data: selectedSkills?.secondary,
        maxCount: 20,
        options: options?.skills,
        getOptions: fetchSkills,
        setSelected: handleSkillsChange,
      },
    },
    {
      condition: industries.length > 0 || isEdit,
      element: {
        title: "Industry & Domain Knowledge",
        type: "industries",
        id: "industries_ids",
        data: industries,
        maxCount: 3,
        options: options?.industries,
        getOptions: fetchIndustries,
        setSelected: handleIndustriesChange,
      },
    },
    {
      condition: cultures.length > 0 || isEdit,
      element: {
        title: "Cultures",
        type: "cultures",
        id: "cultures_ids",
        data: cultures,
        maxCount: 3,
        options: options?.cultures,
        getOptions: fetchCultures,
        setSelected: handleCulturesChange,
      },
    },
    {
      condition: isCreate,
      element: {
        title: "Company",
        type: "company",
        id: "company",
        data: company,
        maxCount: 100,
        options: options?.company,
        getOptions: fetchCompanies,
        setSelected: handleCompany,
      },
    },
    {
      condition: members.length > 0 || isEdit,
      element: {
        title: "Members",
        type: "members",
        id: "members",
        data: members.map((m) => ({
          label: m.first_name,
          value: m.last_name,
          id: m.id,
        })),
        maxCount: 100,
        options: options?.members,
        getOptions: fetchManagers,
        setSelected: handleMembers,
      },
    },
  ];
  const elements = config.reduce((acc, { condition, element }) => {
    if (condition) {
      acc.push(element);
    }
    return acc;
  }, []);
  return elements;
};

const SkillElements = ({
  selectedSkills,
  industries,
  cultures,
  setJobForm,
  company,
  isEdit,
  isCreate = false,
  companyId,
  members,
  errors,
}) => {
  const [options, setOptions] = useState({
    skills: [],
    industries: [],
    cultures: [],
    company: [],
    members: [],
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const fetchData = useCallback(
    async (fetchFunction, optionKey, id) => {
      try {
        const res = await fetchFunction(id ? id : "");
        setOptions((prev) => ({
          ...prev,
          [optionKey]: res.map((r) => ({ value: r.id, label: r.name })),
        }));
      } catch (error) {
        console.log(error);
      }
    },
    [setOptions]
  );

  const fetchCompanies = (search) => {
    if (!search) {
      return;
    }
    try {
      if (search.length) {
        getCompaniesList({ search: search }).then((arr) => {
          setOptions((prev) => ({
            ...prev,
            company: arr.map((r) => ({ value: r.id, label: r.name })),
          }));
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = useCallback(
    (value, field) => {
      let data = value.map((v) => ({ name: v.label, id: v.value }));
      setJobForm((prev) => ({
        ...prev,
        job: {
          ...prev.job,
          [field]: data,
        },
      }));
    },
    [setJobForm]
  );
  const handleMembersChange = useCallback(
    (value, field) => {
      let data = value.map((v) => ({
        first_name: v.label,
        last_name: v.value,
        id: v.id,
      }));
      setJobForm((prev) => ({
        ...prev,
        [field]: data,
      }));
    },
    [setJobForm]
  );

  const fetchSkills = useCallback(
    () => fetchData(getSkills, "skills"),
    [fetchData]
  );
  const fetchIndustries = useCallback(
    () => fetchData(getIndustries, "industries"),
    [fetchData]
  );

  const fetchCultures = useCallback(
    () => fetchData(getCultures, "cultures"),
    [fetchData]
  );

  const fetchManagers = async () => {
    try {
      const res = await getManagers(companyId ? companyId : selectedCompanyId);
      setOptions((prev) => ({
        ...prev,
        members: res.map((r) => ({
          label: r.first_name,
          value: r.last_name,
          id: r.id,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleMembers = useCallback(
    (value) => handleMembersChange(value, "members"),
    [handleMembersChange]
  );

  const handleCulturesChange = useCallback(
    (value) => handleChange(value, "cultures"),
    [handleChange]
  );
  const handleIndustriesChange = useCallback(
    (value) => handleChange(value, "industries"),
    [handleChange]
  );

  const handleCompany = (value) => {
    setJobForm((prev) => ({
      ...prev,
      job: {
        ...prev.job,
        company: value,
      },
    }));
    setSelectedCompanyId(value.value);
  };

  const handleSkillsChange = useCallback(
    (value, name) => {
      let data = value.map((v) => ({ name: v.label, id: v.value }));
      setJobForm((prev) => ({
        ...prev,
        job: {
          ...prev.job,
          selectedSkills: {
            ...prev.job.selectedSkills,
            [name]: data,
          },
        },
      }));
    },
    [setJobForm]
  );

  return (
    <>
      {SkillsConfig({
        selectedSkills,
        industries,
        cultures,
        company,
        isEdit,
        isCreate,
        options,
        members,
        fetchSkills,
        fetchIndustries,
        fetchCultures,
        fetchManagers,
        handleSkillsChange,
        handleIndustriesChange,
        handleCulturesChange,
        handleMembers,
        fetchCompanies,
        handleCompany,
      }).map((element, index) => {
        return (
          <div key={index}>
            <p className="m-0 mb-0 text-uppercase fw-bold text-muted">
              {element.title}
            </p>
            <div className="mt-3 d-flex flex-wrap">
              <Row>
                <Col className="mb-3 " style={{ width: "300px" }}>
                  <Form.Group>
                    <Select
                      required
                      value={
                        element.type === "members"
                          ? element.data.map((d) => ({
                              value: d.value,
                              label: d.label,
                              id: d.id,
                            }))
                          : element.type === "company"
                          ? {
                              value: element.data.value,
                              label: element.data.label,
                            }
                          : element.data.map((d) => ({
                              value: d.id,
                              label: d.name,
                            }))
                      }
                      closeMenuOnSelect={
                        element.type === "company" ? true : false
                      }
                      options={element.options}
                      isClearable={false}
                      hideSelectedOptions={false}
                      isMulti={element.type !== "company" ? true : false}
                      placeholder="Type  to select from dropdown"
                      name={element.type}
                      className={`basic-multi-select ${
                        errors?.[element?.id] ? "error" : ""
                      }`}
                      isDisabled={
                        isCreate &&
                        element.type === "members" &&
                        !selectedCompanyId
                          ? true
                          : false
                      }
                      classNamePrefix="select"
                      onFocus={
                        element.type === "members"
                          ? () => fetchManagers()
                          : () => element.getOptions()
                      }
                      onChange={(v) => element.setSelected(v, element.type)}
                      onInputChange={
                        element.type === "company" ? fetchCompanies : () => {}
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SkillElements;
