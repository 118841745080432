import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getUser,
  deleteUser,
  saveUserStatus,
  saveUserPassword,
  saveUser,
} from "../../../api/users/usersApi";
import { USER_ROLES, USER_STATUSES } from "../../../utils/constants";
import { ChoosePhotoWidget } from "../../../components/Widgets";

const OatmealDetail = () => {
  const history = useHistory();
  const pathname = window.location.href.split("/");
  const userId = localStorage.getItem("user_id");
  if (userId === pathname[pathname.length - 1]) {
    history.push("/settings");
  }

  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsgPass, setErrorMsgPass] = useState("");
  const [errorMsgBtn, setErrorMsgBtn] = useState("");
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const [isDisabledSelect, setIsDisabledSelect] = useState(false);
  const [userStatus, setUserStatus] = useState({
    verified: false,
    blocked: false,
  });

  const userRole = localStorage.getItem("role");

  const [userData, setUserData] = useState({
    role: "",
    email: "",
    status: "",
    last_name: "",
    first_name: "",
    created_at: "",
    phone_number: "",
    profile_picture_id: "",
    profile_picture: { url: "" },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getUser(pathname[pathname.length - 1]);
        setUserData(res);

        if (
          userRole === USER_ROLES.SUPPORT ||
          res.role === USER_ROLES.SUPER_ADMIN ||
          res.role === userRole
        ) {
          setIsDisabledForm(true);
        }
        if (userRole !== USER_ROLES.SUPER_ADMIN) {
          setIsDisabledSelect(true);
        }
        if (res.status === USER_STATUSES.VERIFIED) {
          setUserStatus({ verified: true });
        } else if (res.status === USER_STATUSES.BLOCKED) {
          setUserStatus({ blocked: true });
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const handleSaveGeneral = async (event) => {
    event.preventDefault();

    try {
      await saveUser(pathname[pathname.length - 1], {
        role: userData.role,
        email: userData.email,
        last_name: userData.last_name,
        first_name: userData.first_name,
        phone_number: userData.phone_number,
      });
      history.push("/users/oatmeal");
    } catch (error) {
      setErrorMsg(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleSavePassword = async (event) => {
    event.preventDefault();

    try {
      await saveUserPassword(pathname[pathname.length - 1], password);
      history.push("/users/oatmeal");
    } catch (error) {
      setErrorMsgPass(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUser(pathname[pathname.length - 1]);
      history.push("/users/oatmeal");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  const handleStatus = async (status) => {
    try {
      await saveUserStatus(pathname[pathname.length - 1], status);
      history.push("/users/oatmeal");
    } catch (error) {
      setErrorMsgBtn(error.response.data.message);
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form onSubmit={handleSaveGeneral}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        disabled={isDisabledForm}
                        required
                        name="first_name"
                        type="text"
                        placeholder="Enter first name"
                        value={userData.first_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        disabled={isDisabledForm}
                        required
                        name="last_name"
                        type="text"
                        placeholder="Also last name"
                        value={userData.last_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        disabled={isDisabledForm}
                        required
                        name="email"
                        type="email"
                        placeholder="name@company.com"
                        value={userData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        disabled={isDisabledForm}
                        required
                        name="phone_number"
                        type="phone"
                        placeholder="+12-345 678 910"
                        value={userData.phone_number}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="role">
                      <Form.Label>Role</Form.Label>
                      <Form.Select
                        name="role"
                        disabled={isDisabledSelect}
                        defaultValue={userData.role}
                        value={userData.role}
                        onChange={handleInputChange}
                      >
                        <option disabled value={USER_ROLES.SUPER_ADMIN}>
                          Super Admin
                        </option>
                        <option value={USER_ROLES.ADMIN}>Admin</option>
                        <option value={USER_ROLES.SUPPORT}>Support</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                {!isDisabledForm && (
                  <div className="mt-3">
                    <Button variant="primary" type="submit">
                      Save General
                    </Button>
                  </div>
                )}
              </Form>

              {!isDisabledForm && (
                <Form onSubmit={handleSavePassword}>
                  <h5 className="my-4">Set Password</h5>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          required
                          name="password"
                          type="password"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {errorMsgPass && (
                    <Alert variant="danger">{errorMsgPass}</Alert>
                  )}
                  <div className="mt-3">
                    <Button variant="primary" type="submit">
                      Save Password
                    </Button>
                  </div>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <div>
                <p>
                  Creation Date:{" "}
                  {new Date(userData.created_at).toLocaleDateString()}
                </p>
                <p>Status: {userData.status}</p>
              </div>
            </Col>
            {!isDisabledForm && (
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <Button
                    variant="danger"
                    onClick={() => handleDelete()}
                    className="me-2"
                  >
                    Delete
                  </Button>
                  {userStatus.blocked && (
                    <Button
                      variant="secondary"
                      onClick={() => handleStatus(USER_STATUSES.VERIFIED)}
                    >
                      Unblock
                    </Button>
                  )}
                  {userStatus.verified && (
                    <Button
                      variant="secondary"
                      onClick={() => handleStatus(USER_STATUSES.BLOCKED)}
                    >
                      Block
                    </Button>
                  )}
                </div>
              </Col>
            )}
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={userData?.profile_picture?.url}
              />
            </Col>
          </Row>
          {errorMsgBtn && <Alert variant="danger">{errorMsgBtn}</Alert>}
        </Col>
      </Row>
    </>
  );
};

export default OatmealDetail;
