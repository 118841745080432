
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// core styles
import './scss/volt.scss';

// vendor styles
import 'react-datetime/css/react-datetime.css';

import ScrollToTop from './components/ScrollToTop';
import LoginRoute from './LoginRoute';

ReactDOM.render(
    <HashRouter>
        <ScrollToTop />
        <LoginRoute />
    </HashRouter>,
  document.getElementById('root')
);
