export const formatTimezonesWithMoment = (moment) => {
  const gmtOffsets = new Set();

  return moment.tz
    .names()
    .sort((a, b) => moment.tz(a).utcOffset() - moment.tz(b).utcOffset())
    .filter((tz) => {
      const offset = moment.tz(tz).utcOffset();
      if (gmtOffsets.has(offset)) {
        return false;
      } else {
        gmtOffsets.add(offset);
        return true;
      }
    })
    .map((tz) => {
      const now = moment.tz(tz);
      const offset = now.utcOffset() / 60;
      const sign = offset >= 0 ? "+" : "-";
      const absOffset = Math.abs(offset);

      return `GMT ${sign}${absOffset}`;
    });
};

export function formatDate(isoDateString) {
  let date = new Date(isoDateString);
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = String(date.getFullYear()).slice(-2);

  return `${month}/${day}/${year}`;
}

export function shortId(id) {
  return id.split("-").pop();
}

export function getStatusClass(status) {
  if (status === "open" || status === "published") {
    return "#9AE0B3";
  } else if (status === "draft" || status === "closed") {
    return "#DBDCDB";
  } else if (status === "on hold") {
    return "#FFE895";
  } else {
    return "";
  }
}

export const shortSocialUrl = (url) => {
  if (!url || !url.includes("http")) return url;
  const socialSites = [
    "github",
    "instagram",
    "twitter",
    "x",
    "linkedin",
    "facebook",
    "youtube",
  ];
  let newUrl;
  try {
    newUrl = new URL(url);
  } catch (_) {
    return "Invalid URL";
  }
  const parts = newUrl.pathname.split("/");
  const lastPart = parts[parts.length - 1] || parts[parts.length - 2];
  for (const site of socialSites) {
    if (url.includes(site)) {
      return `${site}.com/${lastPart}`;
    }
  }
};
export const websiteUrl = (url) => {
  if (!url) return "";
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }
  return url;
};
