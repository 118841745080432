import React from "react";
import Chip from "../../../components/Chip";
import SkillElements, { SkillsConfig } from "./SkillElements";

const SkillsAndIndustry = ({
  industries = [],
  selectedSkills = [],
  cultures = [],
  isEdit,
  setJobForm,
  members,
  companyId,
  errors,
}) => {
  return (
    <div>
      {isEdit ? (
        <SkillElements
          industries={industries}
          selectedSkills={selectedSkills}
          cultures={cultures}
          isEdit={isEdit}
          setJobForm={setJobForm}
          companyId={companyId}
          members={members}
          errors={errors}
        />
      ) : (
        <>
          {SkillsConfig({
            industries,
            selectedSkills,
            cultures,
            members,
            isEdit,
            setJobForm,
            companyId,
          }).map((element, i) => {
            return (
              <div className="p-3" key={i}>
                <p className="m-0 mb-0 text-uppercase fw-bold text-muted">
                  {element.title}
                </p>
                <div className="mt-3 d-flex flex-wrap">
                  {element.data.map((i) => (
                    <React.Fragment key={i.id}>
                      <Chip name={i.name ? i.name : i.label + " " + i.label} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SkillsAndIndustry;
